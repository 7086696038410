import cn from 'classnames';
import { useUI } from 'contexts/UIContext';
import Icon from 'components/shared/icon';
import { useEffect, useState } from 'react';
import SalonsList from 'components/salonsList/salonsList';
import { useCart, useSettings, useCustomer, useMultipass, useDiscountFreeItem } from 'hooks';
import LineItem from 'components/account/cart/lineItem';
import useViewCartTracking from 'hooks/tracking/useViewCartTracking';
import {
  formatPriceByStoreLocale,
  getStorageCustomerSalonNo,
  getSelectedSalonNo,
  setSessionCheckoutUrl,
  getCheckoutIdByLocale,
} from 'lib/shopify';
import LockIcon from 'components/shared/lockIcon/lockIcon';
import PaymentIcons from 'components/shared/paymentIcons/paymentIcons';
import addCheckoutAttributes from 'lib/shopify/addCheckoutAttributes';
import ProgressBar from 'components/shared/progressBar';
import { addLocaleToHref } from 'lib/utils/helpers';
import SiteLink from 'components/shared/sitelink';
import { useRouter } from 'next/router';
import OutOfStockPopup from 'components/account/cart/outOfStockPopup/outOfStockPopup';
import styles from './cartPopup.module.scss';
import FreeItemPopup from '../freeItemPopup';

const CartPopup = ({ globalModules, locale }) => {
  const { displayCartPopup, closeCartPopup } = useUI();
  const {
    btncolors,
    cart: cartModules,
    settings,
    shipping,
    checkout,
    freeitem,
    secondpartsettings,
  } = globalModules || {};

  const { cart, isEmpty } = useCart();
  const [contentfulData, setContentfulData] = useState([]);
  const { trackedItemsSelectList } = useSettings();
  const [outOfStocLineItems, setOutOfStocLineItems] = useState(null);
  const { customer } = useCustomer();

  const subTotalPrice = cart?.cost?.subtotalAmount?.amount;
  const totalPrice = cart?.cost?.totalAmount?.amount;
  const totalPriceIsValid = totalPrice && totalPrice !== subTotalPrice;

  const freeShippingPrice = parseFloat(shipping.freeShippingPrice?.text || 0);
  const router = useRouter();
  const { freeProducts } = useDiscountFreeItem(freeitem?.discountIDs.texts || [], locale);

  const hasShippingBar = shipping.localeHasShippingBar.onOrOff !== false;

  const t = key => cartModules?.[key]?.text;

  useEffect(() => {
    const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
    if (displayCartPopup) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollWidth}px`;
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.paddingRight = `0px`;
    }
  }, [displayCartPopup]);

  useEffect(() => {
    const fetchProducts = async () => {
      const productSlugs = cart.lines?.edges.map(({ node }) => node.merchandise?.product?.handle);
      const products = await fetch('/api/contentful/get-products', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          productSlugs,
          locale,
        }),
      });

      try {
        if (products.ok) {
          const productsWithContentfulData = await products.json();
          setContentfulData(productsWithContentfulData);
        }
        return null;
      } catch (e) {
        return products.status(500).json('There seems to have been an issue:', e);
      }
    };

    if (cart.lines?.edges?.length) {
      fetchProducts();
    }
  }, [cart.lines?.edges?.length]);

  const startCheckout = async () => {
    const checkoutId = getCheckoutIdByLocale(locale);
    const affiliatedSalonNo = getStorageCustomerSalonNo(locale);
    const selectedSalonNo = getSelectedSalonNo(locale);
    const attributes =
      affiliatedSalonNo || selectedSalonNo
        ? [
            {
              key: 'salonNo',
              value:
                (Boolean(selectedSalonNo) && selectedSalonNo !== 'null' ? selectedSalonNo : null) ||
                affiliatedSalonNo,
            },
            {
              key: 'affiliatedSalonNo',
              value: affiliatedSalonNo || '',
            },
          ]
        : null;

    if (attributes) await addCheckoutAttributes(checkoutId, attributes, locale);

    if (customer?.isLoggedIn) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const url = await useMultipass(customer.email, cart.webUrl, locale);
      document.location.href = url;
    } else {
      // We save the checkout url so that we can redirect to it after login
      setSessionCheckoutUrl(locale, cart.webUrl);
      document.location.href = cart.webUrl;
    }
  };

  const handleCheckout = () => {
    const outOfStockItems = cart.lines?.edges?.filter(({ node }) =>
      node?.merchandise?.product.tags.includes('out-of-stock')
    );

    if (outOfStockItems.length) {
      setOutOfStocLineItems(outOfStockItems);
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useViewCartTracking({
        currencyCode: cart.currencyCode,
        lineItems: cart.lines?.edges,
        contentfulData,
        trackedItemsSelectList,
      });
      startCheckout();
    }
  };

  const shippingMessage = () => {
    if (subTotalPrice > freeShippingPrice) {
      return shipping?.freeShippingMessage?.text;
    }
    const difference = formatPriceByStoreLocale({
      locale,
      price: Number(freeShippingPrice - subTotalPrice).toFixed(2),
    });
    return shipping?.freeShipingDifferenceMessage?.text.replace('{difference}', difference);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <div
        className={cn(styles.overlay, { [styles.visible]: displayCartPopup })}
        onClick={closeCartPopup}
      />
      <div className={cn(styles.popup, { [styles.opened]: displayCartPopup })}>
        <button type="button" className={styles.closeBtn} onClick={closeCartPopup}>
          <Icon type="close" />
        </button>
        <div className={styles.cartHeader}>
          {hasShippingBar && (
            <>
              <span className={styles.shippingLabel}>{shippingMessage()}</span>
              <ProgressBar
                current={subTotalPrice}
                max={freeShippingPrice}
                color={shipping?.progressBarColor?.text}
              />
            </>
          )}
        </div>
        <div className={cn(styles.popupContent, { [styles.spaceBottom]: freeProducts?.length })}>
          {isEmpty && <div className={styles.emptyContainer}>{t('emptyCart')}</div>}
          {!isEmpty && secondpartsettings?.localesWithSalonSelector?.onOrOff && (
            <SalonsList
              locale={locale}
              globalModules={globalModules}
              customClasses={{
                container: styles.salonsListContainer,
                text: styles.salonsListText,
                title: styles.salonsListTitle,
              }}
              showNameInTitle
            />
          )}
          <div className={styles.lineItemsSection}>
            {cart?.lines?.edges?.map(({ node }, i) => (
              <LineItem
                locale={locale}
                lineItem={node}
                key={node.id}
                index={i}
                cart={cart}
                globalModules={globalModules}
                isCartPopup
                contentfulData={contentfulData?.find(
                  c => c?.slug === node?.merchandise?.product?.handle
                )}
              />
            ))}
          </div>
          <FreeItemPopup
            items={freeProducts}
            locale={locale}
            className={styles.freeItemPopup}
            globalModules={globalModules}
          />
        </div>
        <div className={styles.subContainer}>
          <div className={cn(styles.subtotal)}>
            <span className={styles.totalLabel}>{cartModules.subtotal.text}</span>
            <div className={styles.totalContainer}>
              <span
                className={cn({
                  [styles.lineThroughText]: !isEmpty && totalPriceIsValid,
                })}
              >
                {formatPriceByStoreLocale({
                  locale,
                  price: Number(subTotalPrice).toFixed(2),
                })}
              </span>
              {!isEmpty && totalPriceIsValid && (
                <span className={styles.salesPrice}>
                  {formatPriceByStoreLocale({
                    locale,
                    price: Number(totalPrice).toFixed(2),
                  })}
                </span>
              )}
            </div>
          </div>
          <span className={styles.shippingInfo}>
            {hasShippingBar && subTotalPrice > parseInt(shipping.freeShippingPrice.text, 10)
              ? checkout.freeShipping.text
              : checkout.shippingCostsMessage.text}
          </span>
          {isEmpty ? (
            <SiteLink
              locale={locale}
              href={addLocaleToHref(settings?.slugEmptyCartButton?.link?.slug, locale, router)}
              onClick={closeCartPopup}
            >
              <button className={styles.startShoppingButton} type="button">
                {t('startShopping')}
              </button>
            </SiteLink>
          ) : (
            <button
              className={styles.checkout}
              style={{
                backgroundColor: btncolors?.checkoutButtonColor.text || '',
                color: btncolors?.checkoutButtonTextColor.text || '',
              }}
              type="button"
              onClick={() => handleCheckout()}
            >
              <LockIcon
                color={btncolors?.checkoutButtonTextColor.text}
                width="2rem"
                height="2rem"
              />
              <span className={styles.checkoutContent}>{settings.checkout.text}</span>
            </button>
          )}
          <div className={styles.subtotalBottom}>
            <PaymentIcons
              className={styles.paymentMethods}
              locale={locale}
              iconHeight="2.5rem"
              iconWidth="3.5rem"
            />
            <SiteLink
              locale={locale}
              className={styles.cartLink}
              href={addLocaleToHref('/cart', locale, router)}
              onClick={closeCartPopup}
            >
              {t('goToCartLink')}
            </SiteLink>
          </div>
        </div>
      </div>
      {outOfStocLineItems?.length && (
        <OutOfStockPopup
          globalModules={globalModules}
          lineItems={outOfStocLineItems}
          closeModal={() => {
            setOutOfStocLineItems(null);
          }}
          startCheckout={startCheckout}
          locale={locale}
          cart={cart}
        />
      )}
    </>
  );
};

export default CartPopup;
