import { useRef } from 'react';
import dynamic from 'next/dynamic';
import { useInViewTracking } from 'hooks';

// dynamically import components, otherwise unused sections will be loaded
const SideImageDescription = dynamic(() => import('components/sections/sideImageDescription'));
const VideoPlayer = dynamic(() => import('components/sections/videoPlayer'));
const ProgramDescription = dynamic(() => import('components/sections/programDescription'));
const Recommendation = dynamic(() => import('components/sections/recommendation'));
const Tagline = dynamic(() => import('components/sections/tagline'));
const HeroCarousel = dynamic(() => import('components/sections/hero'));
const Highlights = dynamic(() => import('components/sections/highlights'));
const Text = dynamic(() => import('components/sections/text'));
const Cta = dynamic(() => import('components/sections/cta'));
const InstagramFeed = dynamic(() => import('components/sections/instagramFeed'));
const SideImageCta = dynamic(() => import('components/sections/sideImageCta'));
const IconCollection = dynamic(() => import('components/sections/iconCollection'));
const RelatedProducts = dynamic(() => import('components/sections/relatedProducts'));
const Blog = dynamic(() => import('components/sections/blog'));
const TextAndSubtitle = dynamic(() => import('components/sections/textAndSubtitle'));
const BlogArticle = dynamic(() => import('components/sections/blogArticle'));
const ReadMoreArticles = dynamic(() => import('components/sections/readMoreArticles'));
const TitleAndText = dynamic(() => import('components/sections/titleAndText'));
const Promises = dynamic(() => import('components/sections/promises'));
const DoubleCta = dynamic(() => import('components/sections/doubleCta'));
const ProfessionalsHeader = dynamic(() => import('components/sections/professionalsHeader'));
const CtaLadder = dynamic(() => import('components/sections/ctaLadder'));
const Breadcrumbs = dynamic(() => import('components/layout/breadcrumbs'));
const Contact = dynamic(() => import('components/sections/contact'));
const ContactForm = dynamic(() => import('components/sections/contactForm'));
const Bookeo = dynamic(() => import('components/sections/bookeo'));
const ProductGrid = dynamic(() => import('components/sections/productGrid'));
const Klaviyo = dynamic(() => import('components/sections/klaviyo'));
const DownloadPdf = dynamic(() => import('components/sections/downloadPdf'));
const LocalisedContact = dynamic(() => import('components/sections/localisedContact'));
const Advice = dynamic(() => import('components/sections/advice'));
const ArticleSlider = dynamic(() => import('components/sections/articleSlider'));
const Iframe = dynamic(() => import('components/sections/iframe'));
const Videos = dynamic(() => import('components/sections/videos'));
const ComponentContainer = dynamic(() => import('components/sections/componentContainer'));
const GallerySlider = dynamic(() => import('components/sections/gallerySlider'));
const Image = dynamic(() => import('components/sections/mediaImage'));
const ImagesBeforeafter = dynamic(() => import('components/sections/imagesBeforeAfter'));
const Banners = dynamic(() => import('components/sections/banners'));
const Quiz = dynamic(() => import('components/sections/quiz'));
const Collaborator = dynamic(() => import('components/sections/collaborator'));
const TypeForm = dynamic(() => import('components/sections/typeForm'));

const components = {
  SideImageDescription,
  VideoPlayer,
  ProgramDescription,
  Recommendation,
  Tagline,
  HeroCarousel,
  Highlights,
  Text,
  Cta,
  InstagramFeed,
  SideImageCta,
  IconCollection,
  RelatedProducts,
  Blog,
  BlogArticle,
  TextAndSubtitle,
  ReadMoreArticles,
  TitleAndText,
  Promises,
  DoubleCta,
  ProfessionalsHeader,
  CtaLadder,
  Breadcrumbs,
  Contact,
  ContactForm,
  Bookeo,
  ProductGrid,
  Klaviyo,
  DownloadPdf,
  LocalisedContact,
  Advice,
  ArticleSlider,
  Iframe,
  Videos,
  ComponentContainer,
  GallerySlider,
  Image,
  ImagesBeforeafter,
  Banners,
  Quiz,
  Collaborator,
  TypeForm,
};

const Sections = ({ locale, type, className, data, i }) => {
  const typeSection = type.replace('Section', '');
  const ref = useRef();
  useInViewTracking({ ref, num: i + 1, data });

  if (typeof components[typeSection] !== 'undefined') {
    const Component = components[typeSection];
    // These sections ignore the initial spacing rule, so that's why they get an extra class.
    const ignoreSpacingSections = ['ProfessionalsHeader', 'Breadcrumbs'];

    return (
      <section
        className={
          ignoreSpacingSections.find(section => section === typeSection) ? className : undefined
        }
        ref={ref}
      >
        <Component data={data} locale={locale} />
      </section>
    );
  }
  return <div>{`Can't find component: ${typeSection}`}</div>;
};

const renderSections = (name, locale, sections, className, resources = {}) => {
  if (sections) {
    return sections?.map((data, i) => {
      const type = data?.type;
      // eslint-disable-next-line react/no-array-index-key
      return (
        <Sections
          locale={locale}
          type={type}
          className={className}
          data={{ name, resources, ...data }}
          i={i}
          // eslint-disable-next-line react/no-array-index-key
          key={type + i}
        />
      );
    });
  }
  return null;
};

export default renderSections;
