import cn from 'classnames';
import { useRouter } from 'next/router';
import RichText from 'components/shared/richtext';
import { addLocaleToHref } from 'lib/utils/helpers';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import useIsMobile from 'hooks/utils/useIsMobile';
import styles from './announcementBar.module.scss';
import { getSelectedSalonNo } from '../../../lib/shopify';

const AnnouncementBar = ({ salon, locale, announcementBar, is404, localesWithWebshop }) => {
  const router = useRouter();
  const isMobile = useIsMobile();
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
      initial: 0,
      slidesPerView: 1,
      autoplay: true,
      disabled: !isMobile,
    },
    [
      slider => {
        // todo try catch
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 5000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ]
  );

  const handleSalonRedirect = () => {
    const salonNo = getSelectedSalonNo(locale);
    router.push({ pathname: addLocaleToHref('/salon-finder/', locale), query: { salonNo } });
  };

  const getAdditionalUSP = (additionalClass = '') => {
    const USP2 = announcementBar?.['announcementBar-Message2'];
    const USP3 = announcementBar?.['announcementBar-Message3'];
    return (
      <>
        {localesWithWebshop && (
          <div className={cn(styles.slide, additionalClass)} key={`USP1${additionalClass}`}>
            {salon && announcementBar['announcementBar-SalonPrefix']?.text}
            {/* eslint-disable-next-line */}
            <p className={styles.salonName} onClick={handleSalonRedirect}>
              {salon}
            </p>
            {!salon && announcementBar['announcementBar-AllowedLocales']?.onOrOff && (
              <RichText
                className={styles.announcementText}
                text={
                  announcementBar['announcementBar-Message']?.text &&
                  announcementBar['announcementBar-Message']?.text.json
                }
              />
            )}
          </div>
        )}
        {!is404 && USP2 && USP2.text && (
          <div className={cn(styles.slide, additionalClass)} key={`USP2${additionalClass}`}>
            <RichText className={styles.additionalUSP} text={USP2?.text?.json} />
          </div>
        )}
        {!is404 && USP3 && USP3.text && (
          <div className={cn(styles.slide, additionalClass)} key={`USP3${additionalClass}`}>
            <RichText className={styles.additionalUSP} text={USP3?.text?.json} />
          </div>
        )}
      </>
    );
  };
  if (isMobile) {
    return (
      <div className={cn(styles.banner, 'keen-slider')} ref={sliderRef}>
        {getAdditionalUSP('keen-slider__slide')}
      </div>
    );
  }
  return <div className={styles.banner}>{getAdditionalUSP()}</div>;
};

export default AnnouncementBar;

// Only for NL if there's a salon selected then the priority is showing the salon
// otherwise show the announcement from contentful
