import React, { useState } from 'react';
import SiteLink from 'components/shared/sitelink';
import Icon from 'components/shared/icon';
import cx from 'classnames';
import { addLocaleToHref } from 'lib/utils/helpers';
import { useRouter } from 'next/router';
import HeartIcon from 'components/shared/heart-icon';
import { useLocalWishlist } from 'hooks';
import styles from './menuMobile.module.scss';
import BelgiumLangSelector from '../../shared/belgium-language-selector';

const MenuMobile = ({
  menu,
  locale,
  mobileMenuSecondary,
  visible = true,
  closeMenu,
  localesWithWebshop,
  pageData,
}) => {
  const router = useRouter();
  const { localWishlist } = useLocalWishlist();
  const [openedSubMenu, setOpenedSubMenu] = useState(false);
  if (!menu) return null;

  return (
    <>
      <nav className={cx(styles.container, { [styles.visible]: visible })}>
        <div className={styles.menuWrapper}>
          <div className={styles.inner}>
            <div className={cx(styles.actions, styles.left, { [styles.visible]: openedSubMenu })}>
              <button type="button" onClick={() => setOpenedSubMenu(false)}>
                <Icon
                  type="back"
                  className={cx(styles.button, styles.back, {
                    [styles.visible]: openedSubMenu,
                  })}
                />
              </button>
            </div>
            <div className={cx(styles.actions, styles.right)}>
              <BelgiumLangSelector locale={locale} pageData={pageData} />
              <SiteLink
                className={styles.searchAnchor}
                locale={locale}
                href={addLocaleToHref('/search/', locale, router)}
                onClick={closeMenu}
                prefetch={false}
              >
                <Icon type="search" className={styles.button} />
              </SiteLink>
              {localesWithWebshop?.onOrOff && (
                <SiteLink
                  locale={locale}
                  href={addLocaleToHref('/wishlist/', locale, router)}
                  onClick={closeMenu}
                  prefetch={false}
                  className={cx(styles.wishlistIcon, styles.desktopOnly)}
                >
                  <HeartIcon
                    className={styles.iconHeight}
                    type={localWishlist && localWishlist.length > 0 ? 'black' : ''}
                  />
                  <span className={styles.quantity}>{localWishlist && localWishlist.length}</span>
                </SiteLink>
              )}
              <button type="button" onClick={closeMenu}>
                <Icon type="close" className={styles.button} />
              </button>
            </div>
            <ul
              className={cx(styles.menu, styles.levelOne, {
                [styles.menuIsOpen]: visible,
                [styles.subMenuIsOpen]: openedSubMenu,
              })}
            >
              {menu.map(({ title, link, id, menu: subMenu }) => (
                <li key={title} className={cx({ [styles.withSubmenu]: subMenu })}>
                  {/* eslint-disable-next-line */}
                  {subMenu ? (
                    <button
                      type="button"
                      onClick={() => {
                        setOpenedSubMenu(id);
                      }}
                    >
                      <h2>{title}</h2>
                    </button>
                  ) : link?.slug ? (
                    <SiteLink
                      locale={locale}
                      href={addLocaleToHref(link.slug, locale, router)}
                      onClick={closeMenu}
                    >
                      <h2>{title}</h2>
                    </SiteLink>
                  ) : (
                    <SiteLink external={link.externalLink} onClick={closeMenu}>
                      <h2>{title}</h2>
                    </SiteLink>
                  )}
                </li>
              ))}
            </ul>

            {/* Submenus */}
            {menu.map(({ title: subMenuTitle, id, menu: subMenu }) => (
              <div key={id || subMenuTitle}>
                {subMenu && (
                  <ul
                    className={cx(styles.menu, styles.levelTwo, {
                      [styles.visible]: openedSubMenu === id,
                    })}
                  >
                    <li className={styles.title}>{subMenuTitle}</li>
                    {subMenu.map(menuItem => (
                      <li key={menuItem?.id || menuItem?.title}>
                        <SiteLink
                          locale={locale}
                          href={addLocaleToHref(menuItem.slug, locale, router)}
                          external={menuItem.external}
                          onClick={closeMenu}
                        >
                          <h4>{menuItem.title}</h4>
                        </SiteLink>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}

            {mobileMenuSecondary.map(({ title: subMenuTitle, id, menu: subMenu }) => (
              <div key={id || subMenuTitle}>
                {subMenu?.[0]?.menu && (
                  <ul
                    className={cx(styles.menu, styles.levelTwo, {
                      [styles.visible]: openedSubMenu === id,
                    })}
                  >
                    <li className={styles.title}>{subMenuTitle}</li>
                    {subMenu?.[0]?.menu?.filter(Boolean).map(menuItem => (
                      <li key={menuItem?.id || menuItem?.title}>
                        <SiteLink
                          locale={locale}
                          href={addLocaleToHref(menuItem.slug, locale, router)}
                          external={menuItem.external}
                          onClick={closeMenu}
                        >
                          <h4>{menuItem.title}</h4>
                        </SiteLink>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
          {/* Secondary menu */}
          <ul
            className={cx(styles.menu, styles.secondary, {
              [styles.menuIsOpen]: visible,
              [styles.subMenuIsOpen]: openedSubMenu,
            })}
          >
            {mobileMenuSecondary.map(({ title, link, id, menu: subMenu }) => (
              <li key={title}>
                {/* eslint-disable-next-line */}
                  {subMenu ? (
                  <button
                    type="button"
                    onClick={() => {
                      setOpenedSubMenu(id);
                    }}
                  >
                    <h2>{title}</h2>
                  </button>
                ) : link?.slug ? (
                  <SiteLink
                    locale={locale}
                    href={addLocaleToHref(link.slug, locale, router)}
                    onClick={closeMenu}
                  >
                    <h2>{title}</h2>
                  </SiteLink>
                ) : (
                  <SiteLink
                    external={link?.externalLink}
                    onClick={closeMenu}
                    reloaded={link.pageReload}
                  >
                    <h2>{title}</h2>
                  </SiteLink>
                )}
              </li>
            ))}
            {/* {mobileMenuSecondary.map(({ title, externalLink, slug }) => (
              <li key={title}>
                <SiteLink
                  locale={locale}
                  external={externalLink}
                  href={addLocaleToHref(slug, locale, router)}
                  onClick={() => closeMenu()}
                >
                  <h4>{title}</h4>
                </SiteLink>
              </li>
            ))} */}
          </ul>
        </div>
      </nav>
      {/* Click away */}
      <button
        className={cx(styles.clickaway, { [styles.visible]: visible })}
        onClick={() => closeMenu()}
        aria-label="Close menu"
        type="button"
      >
        Close menu
      </button>
    </>
  );
};

export default MenuMobile;
