import SiteLink from 'components/shared/sitelink';
import { addLocaleToHref } from 'lib/utils/helpers';
import { useRouter } from 'next/router';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  PinterestIcon,
  TwitterIcon,
  YouTubeIcon,
  TikTokIcon,
} from '.';
import styles from './social.module.scss';

const Social = ({ locale, networks }) => {
  const router = useRouter();
  const getIcon = title => {
    switch (title) {
      case 'Facebook':
        return <FacebookIcon />;
      case 'Instagram':
        return <InstagramIcon />;
      case 'LinkedIn':
        return <LinkedInIcon />;
      case 'Pinterest':
        return <PinterestIcon />;
      case 'Twitter':
        return <TwitterIcon />;
      case 'YouTube':
        return <YouTubeIcon />;
      case 'TikTok':
        return <TikTokIcon />;
      default:
        return <></>;
    }
  };
  return (
    <ul className={styles.networks}>
      {networks.map(({ externalLink, slug, title: linkTitle }) => (
        <li key={linkTitle}>
          <SiteLink
            locale={locale}
            external={externalLink}
            href={addLocaleToHref(slug, locale, router)}
          >
            {getIcon(linkTitle)}
          </SiteLink>
        </li>
      ))}
    </ul>
  );
};

export default Social;
