import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getCurrencyCode } from 'lib/shopify';

// Hook
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

function getDifference(array1, array2) {
  return array1.filter(object1 => !array2.some(object2 => object1.id === object2.id));
}

const useCollectionViewItemListTracking = ({ products, itemListName }) => {
  const router = useRouter();
  const [viewedProducts, setViewedProducts] = useState([]);
  const [pushedToDataLayer, setPushedToDataLayer] = useState([]);
  const debouncedSearchTerm = useDebounce(viewedProducts, 500);

  useEffect(() => {
    // clear on router change
    setViewedProducts([]);
    setPushedToDataLayer([]);
  }, [router.asPath]);

  useEffect(() => {
    setPushedToDataLayer(prevState => [...prevState, ...viewedProducts]);

    const productsToSendToDataLayer = [
      ...getDifference(viewedProducts, pushedToDataLayer),
      ...getDifference(pushedToDataLayer, viewedProducts),
    ];

    try {
      const ecommerce = {
        currency: getCurrencyCode(router.locale),
        items: productsToSendToDataLayer.map(({ title, productSpecs, shopifyData }) => {
          const productType = productSpecs?.find(
            ({ type }) =>
              type === 'Producttype' || type === 'Type de produit' || type === 'Product type'
          );
          const productCategory = productSpecs?.find(
            ({ type }) =>
              type === 'Productcategorie' ||
              type === 'Catégorie de produit' ||
              type === 'Product Category'
          );
          const productSubCategory = productSpecs?.find(
            ({ type }) =>
              type === 'Subcategorie' || type === 'Sous-catégorie' || type === 'Sub Category'
          );

          return {
            item_name: title,
            item_id: shopifyData?.variants[0]?.product_id,
            price: shopifyData?.variants[0]?.price,
            item_brand: 'Keune',
            item_category: productType?.value,
            item_category2: productCategory?.value,
            ...(productSubCategory?.value && { item_category3: productSubCategory?.value }),
            item_list_name: itemListName,
            index: products?.indexOf(products?.find(p => p.title === title)),
            quantity: 1,
          };
        }),
      };

      if (typeof window !== 'undefined' && window.dataLayer && ecommerce?.items?.length) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

        window.dataLayer.push({
          event: 'view_item_list',
          ecommerce,
        });
      } else {
        setTimeout(() => {
          if (typeof window !== 'undefined' && window.dataLayer && ecommerce?.items?.length) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

            window.dataLayer.push({
              event: 'view_item_list',
              ecommerce,
            });
          }
        }, [process.env.GTM_DELAY]);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('There was an error sending data to Google Tag Manager: ', error);
    }
  }, [debouncedSearchTerm]);

  const pushToDataLayerCb = indexOfViewedProduct => {
    if (products[indexOfViewedProduct]) {
      setViewedProducts(prevState => [...prevState, products[indexOfViewedProduct]]);
    }
  };

  return {
    pushToDataLayerCb,
  };
};

export default useCollectionViewItemListTracking;
