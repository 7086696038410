import { useEffect, useState } from 'react';
import { useCustomer, useLocalWishlist } from 'hooks';

export function useWishlistProducts(locale) {
  const [products, setProducts] = useState([]);
  const { customer } = useCustomer();
  const { localWishlist } = useLocalWishlist();

  useEffect(() => {
    const fetchProducts = async () => {
      if (!localWishlist) return;

      const contentfulData = await fetch('/api/contentful/get-products-by-ids', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          locale,
          productIds: localWishlist,
        }),
      });
      const contentfulProducts = await contentfulData.json();

      setProducts(contentfulProducts || []);
    };

    fetchProducts();
  }, [customer, localWishlist]);

  return { wishlistProducts: products };
}

export default useWishlistProducts;
