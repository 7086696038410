const FooterLogo = ({ width, height }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 136.94 200.11"
    xmlSpace="preserve"
    fill="#3c3c3b"
  >
    <g id="Logo">
      <g>
        <path
          d="M48.27,66.92h19.98c4.86,0,11.27,0,14.58,2.01c3.63,2.16,6.48,6.09,6.48,11.5c0,5.86-3.08,10.26-8.18,12.34v0.16
			c6.79,1.39,10.49,6.48,10.49,13.27c0,8.1-5.79,15.82-16.74,15.82H48.27V66.92z M55.6,90.6h14.67c8.33,0,11.73-3.01,11.73-8.8
			c0-7.64-5.4-8.72-11.73-8.72H55.6V90.6z M55.6,115.83h18.37c6.32,0,10.34-3.86,10.34-9.87c0-7.18-5.79-9.18-11.96-9.18H55.6
			V115.83z"
        />
        <path
          d="M110.37,94.48c0,23.14-18.76,41.9-41.91,41.9c-23.14,0-41.9-18.76-41.9-41.9c0-23.14,18.76-41.9,41.9-41.9
			C91.61,52.59,110.37,71.35,110.37,94.48z M68.47,46.2c-26.65,0-48.26,21.61-48.26,48.26c0,26.65,21.61,48.26,48.26,48.26
			c26.66,0,48.27-21.61,48.27-48.26C116.74,67.81,95.13,46.2,68.47,46.2z"
        />
      </g>
      <g>
        <rect x="20.3" y="152.91" width="96.33" height="6.33" />
        <path
          d="M113.62,145.94c0.12,0,0.23-0.01,0.34-0.01c0.1-0.01,0.2-0.03,0.28-0.06c0.09-0.04,0.15-0.1,0.2-0.17
			c0.05-0.08,0.07-0.18,0.07-0.31c0-0.11-0.02-0.21-0.06-0.27c-0.04-0.07-0.1-0.12-0.17-0.15c-0.07-0.04-0.14-0.06-0.24-0.08
			c-0.09-0.01-0.17-0.01-0.27-0.01h-0.69v1.07H113.62z M113.85,144.44c0.4,0,0.69,0.08,0.89,0.24c0.19,0.16,0.29,0.4,0.29,0.73
			c0,0.31-0.09,0.52-0.25,0.67c-0.17,0.14-0.38,0.22-0.64,0.25l0.96,1.48h-0.56l-0.92-1.45h-0.55v1.45h-0.53v-3.36H113.85z
			 M111.49,147.09c0.11,0.3,0.28,0.57,0.5,0.79c0.21,0.22,0.46,0.39,0.75,0.52c0.28,0.12,0.6,0.18,0.93,0.18
			c0.34,0,0.65-0.06,0.93-0.18c0.29-0.13,0.54-0.3,0.75-0.52c0.21-0.22,0.38-0.48,0.49-0.79c0.12-0.3,0.19-0.62,0.19-0.97
			c0-0.35-0.06-0.67-0.19-0.97c-0.12-0.3-0.29-0.56-0.49-0.78c-0.22-0.22-0.47-0.39-0.75-0.51c-0.28-0.12-0.6-0.19-0.93-0.19
			c-0.33,0-0.65,0.07-0.93,0.19c-0.29,0.13-0.55,0.3-0.75,0.51c-0.22,0.22-0.38,0.48-0.5,0.78c-0.12,0.3-0.18,0.61-0.18,0.97
			C111.3,146.46,111.36,146.79,111.49,147.09 M111.01,144.98c0.15-0.35,0.36-0.64,0.62-0.9c0.26-0.26,0.57-0.45,0.92-0.6
			c0.35-0.15,0.73-0.22,1.11-0.22c0.4,0,0.77,0.07,1.13,0.22c0.34,0.15,0.65,0.34,0.9,0.6c0.26,0.26,0.48,0.56,0.62,0.9
			c0.16,0.35,0.23,0.73,0.23,1.14c0,0.41-0.07,0.79-0.23,1.14c-0.15,0.36-0.36,0.66-0.62,0.92c-0.26,0.27-0.56,0.46-0.9,0.61
			c-0.36,0.14-0.73,0.21-1.13,0.21c-0.39,0-0.76-0.07-1.11-0.21c-0.35-0.15-0.66-0.34-0.92-0.61c-0.26-0.26-0.47-0.56-0.62-0.92
			c-0.16-0.35-0.22-0.73-0.22-1.14C110.78,145.71,110.85,145.33,111.01,144.98"
        />
      </g>
      <g>
        <path
          d="M31.98,24.98c-0.23-0.37-0.51-0.69-0.85-0.96c-0.34-0.28-0.73-0.49-1.16-0.65c-0.43-0.15-0.88-0.23-1.35-0.23
			c-0.86,0-1.59,0.17-2.2,0.5c-0.6,0.33-1.09,0.78-1.46,1.34c-0.37,0.56-0.65,1.2-0.82,1.92c-0.17,0.72-0.26,1.46-0.26,2.22
			c0,0.73,0.08,1.44,0.26,2.13c0.17,0.69,0.44,1.31,0.82,1.87c0.37,0.55,0.86,1,1.46,1.33c0.6,0.33,1.33,0.5,2.2,0.5
			c1.17,0,2.09-0.36,2.74-1.07c0.66-0.72,1.06-1.66,1.21-2.83h3.71c-0.1,1.09-0.35,2.07-0.76,2.95c-0.41,0.88-0.94,1.63-1.61,2.24
			c-0.67,0.62-1.45,1.09-2.34,1.42c-0.89,0.32-1.88,0.49-2.95,0.49c-1.33,0-2.53-0.23-3.6-0.7c-1.07-0.46-1.96-1.1-2.7-1.92
			c-0.73-0.81-1.29-1.77-1.68-2.87c-0.39-1.1-0.59-2.28-0.59-3.55c0-1.3,0.19-2.51,0.59-3.62c0.39-1.11,0.95-2.09,1.68-2.91
			c0.73-0.83,1.63-1.48,2.7-1.95c1.06-0.47,2.26-0.71,3.6-0.71c0.96,0,1.87,0.14,2.72,0.41c0.85,0.28,1.62,0.68,2.29,1.21
			c0.67,0.53,1.23,1.18,1.67,1.96c0.44,0.78,0.72,1.68,0.83,2.68h-3.71C32.35,25.74,32.21,25.34,31.98,24.98z"
        />
        <path
          d="M41.27,34.72c0.52,0.5,1.27,0.76,2.24,0.76c0.7,0,1.3-0.17,1.81-0.52c0.5-0.35,0.81-0.72,0.93-1.11h3.05
			c-0.49,1.51-1.24,2.6-2.25,3.25c-1.01,0.65-2.23,0.98-3.66,0.98c-0.99,0-1.89-0.16-2.68-0.48c-0.8-0.32-1.47-0.77-2.03-1.35
			c-0.55-0.58-0.98-1.28-1.28-2.1c-0.3-0.81-0.45-1.71-0.45-2.68c0-0.94,0.15-1.82,0.46-2.63c0.31-0.81,0.75-1.52,1.32-2.11
			c0.57-0.59,1.25-1.06,2.04-1.4c0.79-0.34,1.66-0.51,2.62-0.51c1.07,0,2.01,0.21,2.81,0.62c0.8,0.42,1.45,0.97,1.96,1.67
			c0.51,0.7,0.88,1.5,1.11,2.39c0.23,0.89,0.31,1.83,0.24,2.81h-9.1C40.46,33.4,40.74,34.22,41.27,34.72z M45.18,28.09
			c-0.42-0.45-1.05-0.68-1.89-0.68c-0.55,0-1.01,0.09-1.38,0.28c-0.37,0.19-0.66,0.42-0.88,0.69c-0.22,0.28-0.37,0.57-0.46,0.88
			c-0.09,0.31-0.14,0.58-0.16,0.83h5.64C45.89,29.21,45.6,28.54,45.18,28.09z"
        />
        <path
          d="M54.37,25.13v2.34h0.05c0.16-0.39,0.38-0.75,0.66-1.09c0.28-0.33,0.59-0.62,0.95-0.85c0.36-0.23,0.74-0.42,1.15-0.55
			c0.41-0.13,0.83-0.19,1.27-0.19c0.23,0,0.48,0.04,0.76,0.12v3.22c-0.16-0.03-0.36-0.06-0.59-0.08c-0.23-0.02-0.45-0.04-0.66-0.04
			c-0.63,0-1.17,0.11-1.61,0.32c-0.44,0.21-0.79,0.5-1.06,0.87c-0.27,0.37-0.46,0.79-0.57,1.28c-0.11,0.49-0.17,1.02-0.17,1.59v5.69
			h-3.46V25.13H54.37z"
        />
        <path
          d="M68.47,25.13v2.32h-2.54v6.25c0,0.59,0.1,0.98,0.29,1.17c0.19,0.19,0.59,0.29,1.17,0.29c0.2,0,0.38-0.01,0.56-0.02
			c0.18-0.02,0.35-0.04,0.51-0.07v2.68c-0.29,0.05-0.62,0.08-0.98,0.1c-0.36,0.02-0.71,0.03-1.05,0.03c-0.54,0-1.05-0.04-1.52-0.11
			c-0.48-0.07-0.9-0.21-1.27-0.43c-0.37-0.21-0.65-0.51-0.87-0.9c-0.21-0.39-0.32-0.9-0.32-1.54v-7.44h-2.1v-2.32h2.1v-3.78h3.46
			v3.78H68.47z"
        />
        <path d="M70.49,23.18v-2.86h3.46v2.86H70.49z M73.95,25.13v12.61h-3.46V25.13H73.95z" />
        <path
          d="M75.33,27.45v-2.32h2.07v-0.98c0-1.12,0.35-2.04,1.05-2.76c0.7-0.72,1.76-1.07,3.17-1.07c0.31,0,0.62,0.01,0.93,0.04
			c0.31,0.02,0.61,0.05,0.9,0.06v2.59c-0.41-0.05-0.83-0.07-1.27-0.07c-0.47,0-0.81,0.11-1.01,0.33c-0.2,0.22-0.3,0.59-0.3,1.11
			v0.76h2.39v2.32h-2.39v10.3H77.4v-10.3H75.33z"
        />
        <path d="M85.6,23.18v-2.86h3.46v2.86H85.6z M89.07,25.13v12.61H85.6V25.13H89.07z" />
        <path
          d="M94.53,34.72c0.52,0.5,1.27,0.76,2.24,0.76c0.7,0,1.3-0.17,1.81-0.52c0.5-0.35,0.81-0.72,0.93-1.11h3.05
			c-0.49,1.51-1.24,2.6-2.24,3.25c-1.01,0.65-2.23,0.98-3.66,0.98c-0.99,0-1.89-0.16-2.68-0.48c-0.8-0.32-1.47-0.77-2.03-1.35
			c-0.55-0.58-0.98-1.28-1.28-2.1c-0.3-0.81-0.45-1.71-0.45-2.68c0-0.94,0.16-1.82,0.46-2.63C90.99,28,91.43,27.3,92,26.71
			c0.57-0.59,1.25-1.06,2.04-1.4c0.79-0.34,1.66-0.51,2.62-0.51c1.07,0,2.01,0.21,2.81,0.62c0.8,0.42,1.45,0.97,1.96,1.67
			c0.51,0.7,0.88,1.5,1.11,2.39c0.23,0.89,0.31,1.83,0.25,2.81h-9.1C93.73,33.4,94.01,34.22,94.53,34.72z M98.45,28.09
			c-0.41-0.45-1.05-0.68-1.89-0.68c-0.55,0-1.01,0.09-1.38,0.28c-0.37,0.19-0.66,0.42-0.88,0.69c-0.22,0.28-0.37,0.57-0.46,0.88
			c-0.09,0.31-0.14,0.58-0.16,0.83h5.63C99.15,29.21,98.86,28.54,98.45,28.09z"
        />
        <path
          d="M113.3,36.14c-0.41,0.68-0.94,1.18-1.6,1.48c-0.66,0.3-1.4,0.45-2.23,0.45c-0.94,0-1.77-0.18-2.49-0.55
			c-0.72-0.37-1.31-0.86-1.77-1.49c-0.46-0.63-0.81-1.35-1.05-2.16c-0.24-0.81-0.35-1.66-0.35-2.54c0-0.85,0.12-1.66,0.35-2.45
			c0.23-0.79,0.59-1.48,1.05-2.09c0.46-0.6,1.05-1.09,1.74-1.45c0.7-0.37,1.51-0.55,2.44-0.55c0.75,0,1.46,0.16,2.13,0.48
			c0.68,0.32,1.21,0.78,1.6,1.4h0.05v-6.34h3.46v17.42h-3.29v-1.61H113.3z M113.15,29.9c-0.1-0.48-0.26-0.9-0.5-1.27
			c-0.24-0.37-0.54-0.66-0.92-0.89c-0.37-0.23-0.85-0.34-1.41-0.34s-1.05,0.11-1.44,0.34c-0.39,0.23-0.7,0.53-0.94,0.9
			c-0.23,0.37-0.4,0.8-0.51,1.28c-0.11,0.48-0.16,0.98-0.16,1.5c0,0.49,0.06,0.98,0.17,1.46c0.11,0.49,0.3,0.92,0.55,1.31
			c0.25,0.38,0.57,0.69,0.95,0.93c0.38,0.24,0.84,0.35,1.38,0.35c0.57,0,1.04-0.11,1.43-0.34c0.38-0.23,0.69-0.53,0.92-0.92
			c0.23-0.38,0.39-0.82,0.49-1.3c0.1-0.49,0.15-0.99,0.15-1.51C113.3,30.88,113.25,30.38,113.15,29.9z"
        />
      </g>
      <g>
        <path
          d="M28.75,171.67c-0.17-1.22-1.34-2.15-2.68-2.15c-2.42,0-3.33,2.06-3.33,4.21c0,2.04,0.91,4.1,3.33,4.1
			c1.65,0,2.58-1.13,2.78-2.75h2.62c-0.28,3.06-2.39,5.02-5.4,5.02c-3.8,0-6.03-2.84-6.03-6.37c0-3.64,2.23-6.48,6.03-6.48
			c2.7,0,4.96,1.58,5.29,4.42H28.75z"
        />
        <path
          d="M37.1,170.69c2.8,0,4.6,1.85,4.6,4.69c0,2.82-1.8,4.67-4.6,4.67c-2.78,0-4.59-1.86-4.59-4.67
			C32.51,172.55,34.32,170.69,37.1,170.69z M37.1,178.22c1.67,0,2.16-1.43,2.16-2.83c0-1.43-0.5-2.85-2.16-2.85
			c-1.65,0-2.15,1.43-2.15,2.85C34.95,176.79,35.45,178.22,37.1,178.22z"
        />
        <path
          d="M43.21,170.92h2.32v1.65h0.04c0.44-1.12,1.65-1.89,2.84-1.89c0.17,0,0.38,0.03,0.53,0.08v2.27
			c-0.23-0.05-0.59-0.08-0.88-0.08c-1.79,0-2.41,1.29-2.41,2.85v4h-2.44V170.92z"
        />
        <path
          d="M49.89,170.93h2.32v1.13h0.03c0.58-0.94,1.54-1.37,2.64-1.37c2.79,0,4.04,2.25,4.04,4.77c0,2.37-1.3,4.59-3.9,4.59
			c-1.06,0-2.08-0.46-2.66-1.34h-0.04v4.23h-2.44V170.93z M56.48,175.4c0-1.41-0.57-2.87-2.13-2.87c-1.6,0-2.11,1.43-2.11,2.87
			c0,1.44,0.55,2.82,2.13,2.82C55.97,178.22,56.48,176.84,56.48,175.4z"
        />
        <path
          d="M64.27,170.69c2.8,0,4.61,1.85,4.61,4.69c0,2.82-1.81,4.67-4.61,4.67c-2.78,0-4.58-1.86-4.58-4.67
			C59.69,172.55,61.49,170.69,64.27,170.69z M64.27,178.22c1.67,0,2.17-1.43,2.17-2.83c0-1.43-0.5-2.85-2.17-2.85
			c-1.65,0-2.15,1.43-2.15,2.85C62.13,176.79,62.63,178.22,64.27,178.22z"
        />
        <path
          d="M70.39,170.92h2.32v1.65h0.04c0.44-1.12,1.65-1.89,2.83-1.89c0.17,0,0.38,0.03,0.53,0.08v2.27
			c-0.22-0.05-0.58-0.08-0.88-0.08c-1.79,0-2.41,1.29-2.41,2.85v4h-2.44V170.92z"
        />
        <path
          d="M76.83,173.66c0.14-2.29,2.18-2.97,4.18-2.97c1.77,0,3.9,0.39,3.9,2.52v4.62c0,0.81,0.08,1.61,0.31,1.98h-2.48
			c-0.08-0.28-0.16-0.57-0.17-0.86c-0.77,0.81-1.9,1.1-2.99,1.1c-1.68,0-3.02-0.84-3.02-2.66c0-2.01,1.51-2.49,3.02-2.7
			c1.5-0.22,2.89-0.17,2.89-1.17c0-1.05-0.73-1.2-1.58-1.2c-0.93,0-1.53,0.38-1.62,1.34H76.83z M82.46,175.47
			c-0.42,0.36-1.27,0.38-2.03,0.52c-0.76,0.15-1.44,0.41-1.44,1.31c0,0.91,0.7,1.13,1.5,1.13c1.9,0,1.98-1.51,1.98-2.04V175.47z"
        />
        <path
          d="M89.83,170.93h1.79v1.63h-1.79v4.4c0,0.83,0.21,1.03,1.03,1.03c0.26,0,0.5-0.02,0.76-0.07v1.91
			c-0.41,0.07-0.95,0.09-1.43,0.09c-1.49,0-2.8-0.35-2.8-2.11v-5.24h-1.48v-1.63h1.48v-2.66h2.44V170.93z"
        />
        <path d="M95.9,169.56h-2.44v-2.01h2.44V169.56z M93.46,170.93h2.44v8.88h-2.44V170.93z" />
        <path
          d="M102.12,170.69c2.8,0,4.61,1.85,4.61,4.69c0,2.82-1.81,4.67-4.61,4.67c-2.78,0-4.58-1.86-4.58-4.67
			C97.54,172.55,99.34,170.69,102.12,170.69z M102.12,178.22c1.67,0,2.17-1.43,2.17-2.83c0-1.43-0.5-2.85-2.17-2.85
			c-1.65,0-2.15,1.43-2.15,2.85C99.97,176.79,100.47,178.22,102.12,178.22z"
        />
        <path
          d="M108.3,170.93h2.32v1.24h0.05c0.62-1,1.68-1.48,2.72-1.48c2.59,0,3.25,1.46,3.25,3.66v5.46h-2.44v-5.02
			c0-1.46-0.43-2.18-1.56-2.18c-1.33,0-1.89,0.74-1.89,2.55v4.66h-2.44V170.93z"
        />
      </g>
    </g>
    <g id="Guides">
      <g />
    </g>
  </svg>
);

export default FooterLogo;
