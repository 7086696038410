import fetchJson from 'lib/utils/fetchJson';

export const useCustomerWishlist = locale => {
  const getCustomerWishlist = async email => {
    try {
      const data = await fetchJson('/api/shopify/account/get-customer-wishlist/', {
        method: 'POST',
        body: JSON.stringify({ email, locale }),
      });
      const { wishlist } = data;
      return wishlist;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('getCustomer failed: ', error.message);
      return [];
    }
  };

  const setCustomerWishlist = async (email, productIds) => {
    try {
      const data = await fetchJson('/api/shopify/account/set-customer-wishlist/', {
        method: 'POST',
        body: JSON.stringify({
          email,
          productIds,
          locale,
        }),
      });

      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('setCustomerWishlist failed: ', error.message);
      return null;
    }
  };

  return { getCustomerWishlist, setCustomerWishlist };
};

export default useCustomerWishlist;
