import { fetchAllLocales } from 'lib/contentful/api';
import { WEBSHOP_STORES } from '../shopify';

export const camelize = str =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '');

export const createParamString = obj => new URLSearchParams(obj);

/**
 * convert country code to corresponding flag emoji
 * @param {string} cc - country code string
 * @returns {string} flag emoji
 */
export const countryCodeEmoji = cc => {
  // country code regex
  const COUNTRY_CODE_REGEX = /^[a-z]{2}$/i;

  // offset between uppercase ascii and regional indicator symbols
  const COUNTRY_CODE_OFFSET = 127397;

  if (!COUNTRY_CODE_REGEX.test(cc)) return null;

  // If country code is equal to 'en', the globe should be displayed since this would indicate the international locale
  if (cc?.toLowerCase() === 'en') return String.fromCodePoint('0x1F30E');

  const determineCCToUse = () => {
    // If country code is equal to 'na' (North America), the USA flag should be displayed.
    if (cc === 'na') return 'us';
    // If country code is equal to 'sr' (Serbia), the Serbian flag should be displayed.
    if (cc === 'sr') return 'rs';
    return cc;
  };

  const ccToUse = determineCCToUse();

  const codePoints = [...ccToUse.toUpperCase()].map(c => c.codePointAt() + COUNTRY_CODE_OFFSET);
  return String.fromCodePoint(...codePoints);
};

export const formatDate = enteredDate => {
  const formattedDate = new Date(enteredDate);
  const dd = formattedDate.getDate() < 10 ? `0${formattedDate.getDate()}` : formattedDate.getDate();
  const mm =
    formattedDate.getMonth() + 1 < 10
      ? `0${formattedDate.getMonth() + 1}`
      : formattedDate.getMonth() + 1;
  const yyyy = formattedDate.getFullYear();
  return `${dd}-${mm}-${yyyy}`;
};

export const addDaysToDate = (enteredDate, intDays) => {
  const objDate = new Date(enteredDate);
  const numberOfMlSeconds = objDate.getTime();
  const addMlSeconds = intDays * 24 * 60 * 60 * 1000;
  const newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
  return newDateObj;
};

export const checkForReturn = date => Date.parse(date) > Date.now();

export const logContentfulQueryErrors = (errorsArray, query) => {
  errorsArray.forEach(({ message }) =>
    /* eslint-disable no-console */
    console.log(
      '\x1b[31m',
      `
      - ERROR
      - MESSAGE:
        ${message}
      - QUERY:
        ${query}
      `
    )
  );
};

export const delay = ms =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

export const createCookie = (name, value) => {
  const date = new Date();
  date.setTime(Date.parse('2038-01-19 03:14:07'));
  const expirationDateToSet = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expirationDateToSet};path=/`;
};

export const searchCookies = cookieName => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

/** Function to initialize GTM */
export const initGTM = (additionalCallback = undefined) => {
  if (window.gtmDidInit) {
    return false;
  }
  window.gtmDidInit = true;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
      'gtm.uniqueEventId': 0,
    });

    window.dataLayer.push({
      original_location: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
    });
  }; // this part ensures PageViews is always tracked
  script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-59NG49R`;

  document.head.appendChild(script);

  if (additionalCallback) additionalCallback();

  return true;
};

// The amount of CONTENTFUL_LOCALES(see how they are fetched) should be equal to WEBSITE_LOCALES. See next.js config for website locales.
const CONTENTFUL_LOCALES = {
  en_US: 'en-US',
  nl_BE: 'nl-BE',
  nl_NL: 'nl-NL',
  de_DE: 'de-DE',
  fr_BE: 'fr-BE',
  fr_FR: 'fr-FR',
  FR: 'fr',
  SL: 'sl',
  BS: 'bs',
  en_GB: 'en-GB',
  es_ES: 'es-ES',
  de_AT: 'de-AT',
  en_IE: 'en-IE',
  sv_SE: 'sv-SE',
};

// You won't find here `hr`, `sr`, `ro`, 'no' - because they are equal to locales in Contentful
const WEBSITE_LOCALES = {
  NA: 'na',
  NL_BE: 'nl-be',
  NL: 'nl',
  DE: 'de',
  FR_BE: 'fr-be',
  FR: 'fr',
  FR_FR: 'fr-fr',
  SI: 'si',
  BA: 'ba',
  UK: 'uk',
  IE: 'ie',
  ES: 'es',
  AT: 'at',
  SE: 'se',
};

/**
 * Reformats the locale received into the correct format, depending on the locale.
 * @param {*} locale the locale to reformat
 * @returns The new locale in string
 */
export const reformatLocaleToContentfulLocale = locale => {
  switch (locale) {
    case WEBSITE_LOCALES.NA:
      return CONTENTFUL_LOCALES.en_US;

    case WEBSITE_LOCALES.NL_BE:
      return CONTENTFUL_LOCALES.nl_BE;

    case WEBSITE_LOCALES.NL:
      return CONTENTFUL_LOCALES.nl_NL;

    case WEBSITE_LOCALES.DE:
      return CONTENTFUL_LOCALES.de_DE;

    case WEBSITE_LOCALES.FR_BE:
      return CONTENTFUL_LOCALES.fr_BE;

    case WEBSITE_LOCALES.FR:
      return CONTENTFUL_LOCALES.fr_FR;

    case WEBSITE_LOCALES.FR_FR:
      return CONTENTFUL_LOCALES.FR;

    case WEBSITE_LOCALES.SI:
      return CONTENTFUL_LOCALES.SL;

    case WEBSITE_LOCALES.BA:
      return CONTENTFUL_LOCALES.BS;

    case WEBSITE_LOCALES.UK:
      return CONTENTFUL_LOCALES.en_GB;

    case WEBSITE_LOCALES.IE:
      return CONTENTFUL_LOCALES.en_IE;

    case WEBSITE_LOCALES.ES:
      return CONTENTFUL_LOCALES.es_ES;

    case WEBSITE_LOCALES.AT:
      return CONTENTFUL_LOCALES.de_AT;

    case WEBSITE_LOCALES.SE:
      return CONTENTFUL_LOCALES.sv_SE;

    default:
      return locale;
  }
};

export const reformatContentfulLocaleToLocale = contentfulLocale => {
  switch (contentfulLocale) {
    case CONTENTFUL_LOCALES.en_US:
      return WEBSITE_LOCALES.NA;

    case CONTENTFUL_LOCALES.nl_BE:
      return WEBSITE_LOCALES.NL_BE;

    case CONTENTFUL_LOCALES.nl_NL:
      return WEBSITE_LOCALES.NL;

    case CONTENTFUL_LOCALES.de_DE:
      return WEBSITE_LOCALES.DE;

    case CONTENTFUL_LOCALES.fr_BE:
      return WEBSITE_LOCALES.FR_BE;

    case CONTENTFUL_LOCALES.fr_FR:
      return WEBSITE_LOCALES.FR;

    case CONTENTFUL_LOCALES.FR:
      return WEBSITE_LOCALES.FR_FR;

    case CONTENTFUL_LOCALES.SL:
      return WEBSITE_LOCALES.SI;

    case CONTENTFUL_LOCALES.BS:
      return WEBSITE_LOCALES.BA;

    case CONTENTFUL_LOCALES.en_GB:
      return WEBSITE_LOCALES.UK;

    case CONTENTFUL_LOCALES.en_IE:
      return WEBSITE_LOCALES.IE;

    case CONTENTFUL_LOCALES.es_ES:
      return WEBSITE_LOCALES.ES;

    case CONTENTFUL_LOCALES.de_AT:
      return WEBSITE_LOCALES.AT;

    case CONTENTFUL_LOCALES.sv_SE:
      return WEBSITE_LOCALES.SE;

    default:
      return contentfulLocale;
  }
};

export const reformatCountryCodeToCountryName = countryCode => {
  switch (countryCode) {
    case 'us':
      return 'United States of America';

    case 'na':
      return 'United States';

    case 'be':
      return 'Belgium';

    case 'nl':
      return 'The Netherlands';

    case 'de':
      return 'Germany';

    case 'fr':
      return 'France';

    case 'hu':
      return 'Hungary';

    case 'no':
      return 'Norway';

    case 'se':
      return 'Sweden';

    case 'si':
      return 'Slovenia';

    case 'rs':
      return 'Serbia';

    case 'ba':
      return 'Bosnia';

    case 'cw':
      return 'Curaçao';

    case 'dk':
      return 'Denmark';

    case 'kw':
      return 'Kuwait';

    case 'at':
      return 'Austria';

    case 'jo':
      return 'Jordan';

    case 'lk':
      return 'Sri Lanka';

    case 'ro':
      return 'Romania';

    case 'mn':
      return 'Mongolia';

    case 'bo':
      return 'Bolivia';

    case 'cy':
      return 'Cyprus';

    case 'za':
      return 'South Africa';

    case 'uk':
      return 'United Kingdom';

    case 'ie':
      return 'Ireland';

    case 'tn':
      return 'Tunisia';

    case 'md':
      return 'Moldova';

    case 'br':
      return 'Brazil';

    default:
      return countryCode;
  }
};

export const reformatCountryCodeToContentfulLocale = countryCode => {
  switch (countryCode) {
    case 'NL':
      return CONTENTFUL_LOCALES.nl_NL;

    case 'BE':
      return CONTENTFUL_LOCALES.nl_BE;

    case 'NA':
    case 'US':
      return CONTENTFUL_LOCALES.en_US;

    case 'FR':
    case 'TN':
      return CONTENTFUL_LOCALES.fr_FR;

    case 'BA':
      return CONTENTFUL_LOCALES.BS;

    case 'HR':
      return 'hr';

    case 'DE':
      return CONTENTFUL_LOCALES.de_DE;

    case 'RS':
      return 'sr';

    case 'SI':
      return CONTENTFUL_LOCALES.SL;

    case 'GB':
      return CONTENTFUL_LOCALES.en_GB;

    case 'IE':
      return CONTENTFUL_LOCALES.en_IE;

    case 'ES':
      return CONTENTFUL_LOCALES.es_ES;

    case 'AT':
      return CONTENTFUL_LOCALES.de_AT;

    case 'SE':
      return CONTENTFUL_LOCALES.sv_SE;

    default:
      return 'en';
  }
};

/** This list needs to also contain the locales not included in Contentful, because the correct locale needs
 * to be displayed and used for the fetching of some locale specific pages, such as the contact pages
 */
export const reformatCountryCodeToLocale = countryCode => {
  switch (countryCode) {
    case 'NL':
      return WEBSITE_LOCALES.NL;

    case 'BE':
      return WEBSITE_LOCALES.NL_BE;

    case 'NA':
    case 'US':
      return WEBSITE_LOCALES.NA;

    case 'GB':
      return WEBSITE_LOCALES.UK;

    case 'IE':
      return WEBSITE_LOCALES.IE;

    case 'RS':
      return 'sr';

    case 'TN':
      return WEBSITE_LOCALES.FR_FR;

    case 'FR':
      return WEBSITE_LOCALES.FR;

    case 'BA':
      return WEBSITE_LOCALES.BA;

    case 'HR':
      return 'hr';

    case 'DE':
      return WEBSITE_LOCALES.DE;

    case 'SI':
      return WEBSITE_LOCALES.SI;

    case 'ES':
      return WEBSITE_LOCALES.ES;

    case 'AT':
      return WEBSITE_LOCALES.AT;

    case 'SE':
      return WEBSITE_LOCALES.SE;

    default:
      return 'en';
  }
};

export const checkIsWindowsOS = () => window.navigator.userAgent.indexOf('Win') !== -1;

export const getAllowedParams = () => {
  const allowedParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'mid',
    'gclid',
    'source',
  ];

  return allowedParams;
};

export const serializeURIParams = obj => {
  const str = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }

  return str.join('&');
};

export const addLocaleToHref = (slug, locale, router = undefined) => {
  let paramsStr = '';

  // for account pages no router parameter is passed
  if (router && Object.keys(router?.query || []).length) {
    const filteredParams = Object.keys(router.query)
      .filter(key => getAllowedParams().includes(key.toLowerCase()))
      .reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = router.query[key];
        return obj;
      }, {});

    const params = new URLSearchParams(serializeURIParams(filteredParams));
    paramsStr = params.toString() !== '' ? `?${params.toString()}` : '';
  }

  if (locale) {
    const parsedSlug = slug?.charAt(0) !== '/' ? `/${slug}` : slug;

    if (locale === 'en') return `${parsedSlug}${paramsStr}`;
    return `/${locale}${parsedSlug}${paramsStr}`;
  }

  return `${slug}${paramsStr}`;
};

export const createPathsForLocales = async () => {
  const contentfulLocales = await fetchAllLocales();

  return contentfulLocales?.map(locale => ({
    params: {
      locale: reformatContentfulLocaleToLocale(locale),
    },
  }));
};
export const KLAVIYO_ID_PREFIX = 'klaviyo-';
export const getProductSlugFromProductTitle = title => {
  if (title?.includes('1922') && !title?.includes('By J.M. Keune')) {
    const productTitleWithoutPlpName = title.split('1922').filter(Boolean)?.[0]?.trim();
    return getProductSlugFromProductTitle(`1922 By J.M. Keune ${productTitleWithoutPlpName}`);
  }

  return title?.toLowerCase()?.replace(/\./g, ' ')?.split(' ')?.filter(Boolean).join('-');
};

export const formatPrice = p => {
  if (!p) return '';
  return parseFloat(p).toFixed(2);
};

export const getKlaviyoCompanyIdByLocale = locale => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
      return `${process.env.NEXT_PUBLIC_KLAVIYO_FRENCH_COMPANY_ID}`;
    case WEBSHOP_STORES.NL:
      return `${process.env.NEXT_PUBLIC_KLAVIYO_DUTCH_COMPANY_ID}`;
    case WEBSHOP_STORES.DE:
      return `${process.env.NEXT_PUBLIC_KLAVIYO_DE_COMPANY_ID}`;
    case WEBSHOP_STORES.UK:
      return `${process.env.NEXT_PUBLIC_KLAVIYO_UK_COMPANY_ID}`;
    case WEBSHOP_STORES.NL_BE:
    case WEBSHOP_STORES.FR_BE:
      return `${process.env.NEXT_PUBLIC_KLAVIYO_BE_COMPANY_ID}`;
    case WEBSHOP_STORES.ES:
      return `${process.env.NEXT_PUBLIC_KLAVIYO_ES_COMPANY_ID}`;
    case WEBSHOP_STORES.NO:
      return `${process.env.NEXT_PUBLIC_KLAVIYO_NO_COMPANY_ID}`;
    case WEBSHOP_STORES.SE:
      return `${process.env.NEXT_PUBLIC_KLAVIYO_SE_COMPANY_ID}`;
    default:
      return '';
  }
};

export const initKlaviyo = locale => {
  if (window.klaviyoDidInit) {
    return false;
  }
  window.klaviyoDidInit = true;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;

  const companyId = getKlaviyoCompanyIdByLocale(locale);

  script.src = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${companyId}`;

  document.head.appendChild(script);

  return true;
};

export const minimizeQuerySize = str =>
  str
    ?.replace(/#.*\n/g, '')
    .replace(/[\s|,]*\n+[\s|,]*/g, ' ')
    .replace(/:\s/g, ':')
    .replace(/,\s/g, ',')
    .replace(/\)\s\{/g, '){')
    .replace(/\}\s/g, '}')
    .replace(/\{\s/g, '{')
    .replace(/\s\}/g, '}')
    .replace(/\s\{/g, '{')
    .replace(/\)\s/g, ')')
    .replace(/\(\s/g, '(')
    .replace(/\s\)/g, ')')
    .replace(/\s\(/g, '(')
    .replace(/=\s/g, '=')
    .replace(/\s=/g, '=')
    .replace(/@\s/g, '@')
    .replace(/\s@/g, '@')
    .replace(/\s\$/g, '$')
    .replace(/\s\./g, '.')
    .trim();
