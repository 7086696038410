const ChevronDown = ({ width = 16, height = 16 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 6.66675L8 10.6667L12 6.66675" stroke="#3C3C3B" strokeLinecap="square" />
  </svg>
);

export default ChevronDown;
