import { useEffect } from 'react';
import { useRouter } from 'next/router';
import getSalons from 'lib/firebase/getSalons';
import { useSettings } from '../../contexts';
import { get, keys } from '../../lib/utils/localStorage';
import useVisitorTypeTracking from '../tracking/useVisitorTypeTracking';

export const useSalons = async locale => {
  const router = useRouter();
  const { salons, setSalons } = useSettings();

  useEffect(async () => {
    const salonsRes = await getSalons(locale);

    if (salonsRes?.length) setSalons(salonsRes);
  }, [router]);

  useEffect(() => {
    if (!salons) return;
    const customerEmail = get(keys.LOGGED_IN_CUSTOMER);
    const salon = salons.find(
      s => s.salonEmail === customerEmail || s.parentEmail === customerEmail
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useVisitorTypeTracking({
      visitorType: salon?.salonNo || 'KEUNE Cosmetics',
      location: { action: router },
    });
  }, [router, salons, locale]);
};

export default useSalons;
