import { parseShopifyGid } from 'lib/shopify/shopifyGid';

const trackWishlist = (productData, shopifyData, event, list, index) => {
  try {
    const getSpec = (product, key) => {
      const specs =
        product.productSpecs ||
        product?.productSpecsCollection?.items ||
        product?.productSpecsCollection;
      const spec = specs?.find(({ type }) => type === key || type?.filterType === key);
      return spec?.value;
    };

    const getSingleItem = (product, actualShopifyData) => {
      const productType = getSpec(product, 'Producttype');
      const productCategory = getSpec(product, 'Productcategorie');
      const productSubCategory = getSpec(product, 'Subcategorie');

      const item = {
        item_name: actualShopifyData?.productTitle,
        item_id: parseShopifyGid(actualShopifyData.productId),
        price: actualShopifyData?.productPrice,
        item_brand: 'Keune',
        item_category: productType,
        item_category2: productCategory,
        ...(productSubCategory && { item_category3: productSubCategory }),
        item_list_name: list || '',
        index: index || index === 0 ? index : '',
        quantity: 1,
        is_item_in_stock: actualShopifyData?.outOfStock ? 0 : 1,
      };

      return item;
    };

    const getItems = () => {
      const items = [];
      if (productData?.products) {
        productData.products.forEach(product => {
          const actualShopifyData = shopifyData?.find(({ productId }) =>
            productId.toString().includes(product.shopifyData?.variants?.[0]?.product_id)
          );

          if (!actualShopifyData.isFreeItem) items.push(getSingleItem(product, actualShopifyData));
        });
      } else {
        items.push(getSingleItem(productData, shopifyData));
      }

      return items;
    };

    const ecommerce = {
      currency: 'EUR',
      items: getItems(),
    };

    const registerEvent = () => {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce
      window?.dataLayer?.push({
        event,
        ecommerce,
      });
    };

    if (typeof window !== 'undefined' && window.dataLayer) {
      registerEvent();
    } else {
      setTimeout(() => {
        registerEvent();
      }, [process.env.GTM_DELAY]);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('There was an error sending data to Google Tag Manager: ', error);
  }
};

export default trackWishlist;
