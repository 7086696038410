import classNames from 'classnames';
import NetherlandsFlag from 'components/shared/nl-flag';
import FranceFlag from 'components/shared/fr-flag';
import { useRouter } from 'next/router';
import styles from './select.module.scss';

const BelgiumLangSelector = ({ className, locale, pageData, isDesktop }) => {
  const router = useRouter();

  const LOCALES = {
    NL_BE: 'nl-be',
    FR_BE: 'fr-be',
  };

  const onChange = value => {
    router.push(
      {
        pathname:
          pageData?.href?.find(({ locale: hrefLocale }) => hrefLocale === value)?.slug ||
          pageData?.slug ||
          '/',
      },
      null,
      {
        locale: value,
      }
    );
  };

  const options = [
    { value: LOCALES.NL_BE, lang: 'NL' },
    { value: LOCALES.FR_BE, lang: 'FR' },
  ];

  const renderIcon = () => {
    switch (locale) {
      case LOCALES.NL_BE:
        return <NetherlandsFlag styles={styles} />;
      case LOCALES.FR_BE:
        return <FranceFlag styles={styles} />;
      default:
        return null;
    }
  };

  if (locale === LOCALES.NL_BE || locale === LOCALES.FR_BE) {
    return (
      <div
        className={classNames(styles.customSelect, className, { [styles.displayDesktop]: isDesktop })}
      >
        <select onChange={event => onChange(event.target.value)}>
          {options.map(({ value, lang }) => (
            <option key={value} value={value} selected={value === locale}>
              {lang}
            </option>
          ))}
        </select>
        <div className={classNames(styles.wrapperFlags)}>{renderIcon()}</div>
      </div>
    );
  }

  return null;
};

export default BelgiumLangSelector;
