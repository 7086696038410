import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { reformatContentfulLocaleToLocale } from './helpers';

const getPageUrl = (pageData, locale) => {
  const baseUrl = process.env.NEXT_PUBLIC_SITEURL;
  const slug = pageData?.slug || '';
  const finalLocale = locale === 'en' ? '' : `/${locale}/`;
  return `${baseUrl}${finalLocale}${slug === '/' ? '' : `${slug}/`}`;
};

const getBrandSchema = (pageData, pageUrl, locale) => {
  const { title } = pageData?.seo || {};

  const getLinksByLocale = () => {
    switch (locale) {
      case 'uk':
        return ['https://www.facebook.com/phgroupuk', 'https://www.instagram.com/keuneuk/?hl=en'];
      case 'na':
        return [
          'https://www.facebook.com/KeuneHaircosmeticsNorthAmerica',
          'https://www.instagram.com/keunenamerica/',
        ];
      case 'nl':
        return [
          'https://www.facebook.com/keunehaircosmetics.nl/',
          'https://www.instagram.com/keunehaircosmetics.nl/',
          'https://www.linkedin.com/company/keune-haircosmetics/',
          'https://nl.pinterest.com/keunehair/',
          'https://twitter.com/keuneHQ',
          'https://www.youtube.com/c/KeuneHaircosmetics',
          'https://www.tiktok.com/@keunehaircosmetics',
          'https://www.wikidata.org/wiki/Q122237838',
        ];
      case 'nl-be':
        return [
          'https://www.facebook.com/KeuneBelgium/',
          'https://www.instagram.com/keunehaircosmeticsbe/',
        ];
      case 'fr':
        return ['https://www.facebook.com/keunefrance/', 'https://www.instagram.com/keunefrance/'];
      case 'de':
        return [
          'https://www.facebook.com/keunegermany/',
          'https://www.instagram.com/keunegermany/',
        ];
      case 'es':
        return ['https://www.facebook.com/keuneib', 'https://www.instagram.com/keune.iberica/'];
      case 'si':
        return ['https://www.facebook.com/keune.si/', 'https://www.instagram.com/keuneslovenija/'];
      case 'ro':
        return [
          'https://www.facebook.com/KeuneRomania/',
          'https://www.instagram.com/keune_romania/',
        ];
      case 'hr':
        return [
          'https://m.facebook.com/keuneakademija/',
          'https://www.instagram.com/keunehaircosmetics_hr/',
        ];
      case 'sr':
        return ['https://www.facebook.com/keunesrbija/', 'https://www.instagram.com/keunesrbija/'];
      case 'ba':
        return [
          'https://www.facebook.com/KeuneBiH/',
          'https://www.instagram.com/keunehaircosmetics_bih/',
        ];
      default:
        return [
          'https://www.facebook.com/KeuneHaircosmetics/',
          'https://www.instagram.com/keunehaircosmetics',
          'https://www.linkedin.com/company/keune-haircosmetics/',
        ];
    }
  };

  return {
    '@context': 'https://schema.org',
    '@type': 'Brand',
    name: title,
    url: pageUrl,
    logo: 'https://images.ctfassets.net/9kjqrnn60hxu/3dtIY1pZMYNl940IJMpvbc/ec7a7a905e07bed1590eda9d1edc14c7/2022-Keune-Logo-Black-Online.png',
    sameAs: getLinksByLocale(),
  };
};

const getArticleSchema = (pageData, pageUrl) => {
  const { title, description, ogImage } = pageData?.seo || {};
  const { firstPublishedAt, publishedAt } = pageData?.sys || {};

  return {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': pageUrl,
    },
    headline: title,
    description,
    image: ogImage,
    // author: {
    //   '@type': 'Person',
    //   name: 'Naam',
    //   url: 'Auteurspagina',
    // },
    publisher: {
      '@type': 'Organization',
      name: 'Keune Haircosmetics',
      logo: {
        '@type': 'ImageObject',
        url: 'https://images.ctfassets.net/9kjqrnn60hxu/3dtIY1pZMYNl940IJMpvbc/ec7a7a905e07bed1590eda9d1edc14c7/2022-Keune-Logo-Black-Online.png',
      },
    },
    datePublished: firstPublishedAt ? firstPublishedAt.split('T')[0] : '',
    dateModified: publishedAt ? publishedAt.split('T')[0] : '',
  };
};

const getProductSchema = (pageData, pageUrl) => {
  const name = pageData?.title || '';
  const description = documentToPlainTextString(pageData?.description?.json || '');
  const image = pageData?.seo?.ogImage || '';
  const { price, sku } = pageData?.shopifyData?.variants?.[0] || {};

  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name,
    image,
    description,
    brand: {
      '@type': 'Brand',
      name: 'Keune Haircosmetics',
    },
    sku,
    offers: {
      '@type': 'Offer',
      url: pageUrl,
      priceCurrency: 'EUR',
      price,
      availability: 'https://schema.org/InStock',
      itemCondition: 'https://schema.org/NewCondition',
    },
    // aggregateRating: {
    //   '@type': 'AggregateRating',
    //   ratingValue: '4.2',
    //   bestRating: '5',
    //   worstRating: '0',
    //   ratingCount: '18',
    // },
  };
};

const getBundleSchema = (pageData, pageUrl) => {
  const name = pageData?.title || '';
  const description = documentToPlainTextString(pageData?.description?.json || '');
  const image = pageData?.seo?.ogImage || '';
  const isProductStieglitzInBundle = pageData?.products?.some(
    p => p?.slug === 'keune-stieglitz-giftbox'
  );

  const price = isProductStieglitzInBundle
    ? 59.95
    : pageData?.products
        ?.reduce((acc, curr) => {
          const { price: currPrice } = curr?.shopifyData?.variants?.[0] || {};
          return acc + parseFloat(currPrice);
        }, 0)
        .toFixed(2);

  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name,
    image,
    description,
    brand: {
      '@type': 'Brand',
      name: 'Keune Haircosmetics',
    },
    offers: {
      '@type': 'Offer',
      url: pageUrl,
      priceCurrency: 'EUR',
      price,
      availability: 'https://schema.org/InStock',
      itemCondition: 'https://schema.org/NewCondition',
    },
  };
};

const getContactSchema = (pageData, pageUrl) => {
  const name = pageData?.title || '';

  return {
    '@context': 'https://schema.org',
    '@type': 'HealthAndBeautyBusiness',
    name,
    image:
      'https://images.ctfassets.net/9kjqrnn60hxu/3dtIY1pZMYNl940IJMpvbc/ec7a7a905e07bed1590eda9d1edc14c7/2022-Keune-Logo-Black-Online.png',
    '@id': '',
    url: pageUrl,
    telephone: '0031 3530 362 36',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Koningsweg 15',
      addressLocality: 'Soest',
      postalCode: '3762 EA',
      addressCountry: 'NL',
    },
  };
};

const getSchema = pageData => {
  const locale = reformatContentfulLocaleToLocale(pageData?.locale || '');
  const pageUrl = getPageUrl(pageData, locale);
  const { schemaType } = pageData?.seo || {};

  let type = schemaType;
  if (pageData.eanNumber) {
    type = 'Product';
  }
  if (pageData.products) {
    type = 'Bundle';
  }

  switch (type) {
    case 'Brand':
      return getBrandSchema(pageData, pageUrl, locale);
    case 'Article':
      return getArticleSchema(pageData, pageUrl);
    case 'Product':
      return getProductSchema(pageData, pageUrl);
    case 'Bundle':
      return getBundleSchema(pageData, pageUrl);
    case 'Contact':
      return getContactSchema(pageData, pageUrl);
    default:
      return null;
  }
};

export default getSchema;
