const TikTok = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    zoomAndPan="magnify"
    viewBox="0 0 336.75 386.250002"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
  >
    <defs>
      <clipPath id="40f8e9eacf">
        <path
          d="M 0.203125 0 L 336.300781 0 L 336.300781 385.5 L 0.203125 385.5 Z M 0.203125 0 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#40f8e9eacf)">
      <path
        fill="#3c3c3b"
        d="M 286.121094 77.269531 C 265.359375 63.730469 250.375 42.058594 245.699219 16.816406 C 244.691406 11.363281 244.128906 5.746094 244.128906 0 L 177.859375 0 L 177.746094 265.605469 C 176.636719 295.351562 152.15625 319.222656 122.148438 319.222656 C 112.820312 319.222656 104.039062 316.894531 96.304688 312.820312 C 78.570312 303.492188 66.429688 284.902344 66.429688 263.519531 C 66.429688 232.796875 91.425781 207.800781 122.140625 207.800781 C 127.875 207.800781 133.375 208.75 138.574219 210.375 L 138.574219 142.71875 C 133.1875 141.976562 127.71875 141.527344 122.140625 141.527344 C 54.878906 141.527344 0.15625 196.242188 0.15625 263.519531 C 0.15625 304.785156 20.773438 341.308594 52.238281 363.390625 C 72.046875 377.3125 96.15625 385.5 122.148438 385.5 C 189.414062 385.5 244.128906 330.777344 244.128906 263.519531 L 244.128906 128.828125 C 270.121094 147.480469 301.972656 158.476562 336.34375 158.476562 L 336.34375 92.199219 C 317.828125 92.199219 300.589844 86.699219 286.121094 77.269531 Z M 286.121094 77.269531 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
export default TikTok;
