const useProductRemoveFromCartTracking = ({ product }) => {
  try {
    const productType = product.productSpecsCollection?.find(
      ({ type }) =>
        type.filterType === 'Producttype' ||
        type.filterType === 'Type de produit' ||
        type.filterType === 'Product type'
    );

    const productCategory = product.productSpecsCollection?.find(
      ({ type }) =>
        type.filterType === 'Productcategorie' ||
        type.filterType === 'Catégorie de produit' ||
        type.filterType === 'Product Category'
    );

    const productSubCategory = product.productSpecsCollection?.find(
      ({ type }) =>
        type.filterType === 'Subcategorie' ||
        type.filterType === 'Sous-catégorie' ||
        type.filterType === 'Sub Category'
    );
    const ecommerce = {
      currency: product?.currencyCode,
      items: [
        {
          item_name: product?.name,
          item_id: product?.id,
          price: product?.price,
          item_brand: 'Keune',
          item_category: productType?.value,
          item_category2: productCategory?.value,
          ...(productSubCategory?.value && { item_category3: productSubCategory?.value }),
          quantity: product.quantity,
          ...(product?.itemListName && { item_list_name: product?.itemListName }),
        },
      ],
    };

    // todo when completely removed delete from useSettings context
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce
      window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce,
      });
    } else {
      setTimeout(() => {
        if (typeof window !== 'undefined' && window.dataLayer) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce
          window.dataLayer.push({
            event: 'remove_from_cart',
            ecommerce,
          });
        }
      }, [process.env.GTM_DELAY]);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('There was an error sending data to Google Tag Manager: ', error);
  }
};

export default useProductRemoveFromCartTracking;
