import { useEffect } from 'react';

export const useSliderMomentum = ref => {
  // make slider element (NOT the container) draggable with momentum on desktop
  // takes the ref of the slider element as arg

  useEffect(() => {
    const slider = ref?.current;

    if (!slider) {
      return;
    }

    let isDown; // used to stop mousemove function if false
    let startX; // distance between mouseX and slider left edge (on mousedown)
    let scrollLeft; // how much of slider is scrolled (on mousedown)
    let removePointerEvent; // we need this variable to disable all links when slider is dragged

    const sliderChildrenAnchorEls = slider.querySelectorAll('a');

    slider.addEventListener('mousedown', e => {
      e.preventDefault();
      isDown = true;
      slider.style.cursor = 'grabbing';
      startX = e.pageX - slider.offsetLeft; // mouseX from pageLeft - edge of slider relative to pageLeft = distance between mouseX and slider left edge on mousedown
      scrollLeft = slider.scrollLeft; // how much of slider is scrolled on mousedown
    });

    slider.addEventListener('mousemove', e => {
      if (!isDown) return; // if mouse is up stops function
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft; // distance between mouseX and slider left edge as we are dragging
      const walk = (x - startX) * 1; // walk is how far in pixels you have dragged from moment of mousedown; number is scroll speed
      slider.scrollLeft = scrollLeft - walk; // how far the slider should move based on how far it has been dragged (walked)

      // make links not clickable if dragged (mousedown + mousemove)
      if (!removePointerEvent) {
        for (let i = 0; i < sliderChildrenAnchorEls.length; i++) {
          sliderChildrenAnchorEls[i].style.pointerEvents = 'none';
          if (i === sliderChildrenAnchorEls.length - 1) {
            removePointerEvent = true;
          }
        }
      }

      slider.style.cursor = 'grabbing';
    });

    slider.addEventListener('mouseup', () => {
      // make links clickable again after a brief delay
      for (let i = 0; i < sliderChildrenAnchorEls.length; i++) {
        setTimeout(() => {
          sliderChildrenAnchorEls[i].style.pointerEvents = 'auto';
        }, 250);
      }
      for (let i = 0; i < sliderChildrenAnchorEls.length; i++) {
        sliderChildrenAnchorEls[i].style.cursor = 'pointer';
      }
      removePointerEvent = false;
      isDown = false;
      slider.style.cursor = 'grab';
    });

    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.style.cursor = 'auto';
    });

    slider.addEventListener('mouseover', () => {
      slider.style.cursor = 'grab';
      for (let i = 0; i < sliderChildrenAnchorEls.length; i++) {
        sliderChildrenAnchorEls[i].style.cursor = 'pointer';
      }
    });
  }, []);
};

export default useSliderMomentum;
