import { useState } from 'react';
import PasswordValidator from 'password-validator';
import { useCustomer } from 'hooks';
import fetchJson from 'lib/utils/fetchJson';
import { getShopifyGid } from 'lib/shopify/shopifyGid';
import { addLocaleToHref } from 'lib/utils/helpers';

export const useResetPasswordCustomer = locale => {
  const [state, setState] = useState({
    response: null,
    loading: false,
    error: null,
  });

  const { mutateCustomer } = useCustomer({
    redirectTo: addLocaleToHref('/account/dashboard/', locale),
    redirectIfFound: true,
  });

  const resetPassword = async (passwordField1, passwordField2, id, resetToken) => {
    setState({ response: null, loading: true, error: null });

    const schema = new PasswordValidator();

    schema.is().min(8).is().max(100).has().lowercase().has().uppercase();

    if (!schema.validate(passwordField1)) {
      setState({
        response: null,
        loading: false,
        error:
          'Your password should be between 8 and 100 characters, and have at least one lowercase and one uppercase letter.',
      });
    } else if (passwordField1 !== passwordField2) {
      setState({
        response: null,
        loading: false,
        error: 'Passwords do not match.',
      });
    } else if (passwordField1 && resetToken && id) {
      try {
        await mutateCustomer(
          fetchJson('/api/shopify/account/reset-password/', {
            method: 'POST',
            body: JSON.stringify({
              id: getShopifyGid('Customer', id),
              input: {
                resetToken,
                password: passwordField1,
              },
              locale,
            }),
          })
        );
        setState({
          response: true,
          loading: false,
          error: null,
        });
      } catch (error) {
        setState({
          response: null,
          loading: false,
          error: error?.data?.error || error.message,
        });
      }
    } else {
      setState({
        response: null,
        loading: false,
        error: 'The password, reset token, or customer id is missing.',
      });
    }
  };

  return [resetPassword, state];
};

export default useResetPasswordCustomer;
