import classNames from 'classnames';
import styles from './itemCounter.module.scss';

const ItemCounter = ({ disabled, quantity, updateQuantity, onlyDecrease, className }) => (
  <div className={classNames(styles.productCounter, className)}>
    <button
      type="button"
      disabled={disabled || quantity === 1}
      className={classNames(styles.minusButton, { [styles.minusButtonInactive]: quantity === 1 })}
      onClick={() => quantity > 1 && updateQuantity(quantity - 1)}
    >
      <p className={styles.minus}>-</p>
    </button>
    <p className={styles.quantity}>{quantity}</p>
    <button
      type="button"
      disabled={disabled || onlyDecrease}
      className={styles.plusButton}
      onClick={() => updateQuantity(quantity + 1)}
    >
      <p className={styles.plus}>+</p>
    </button>
  </div>
);

export default ItemCounter;
