const useProductAddedTracking = ({ product, affiliatedSalonNo, selectedSalonNo, location }) => {
  try {
    if (location.action) {
      if (typeof window !== 'undefined' && window.dataLayer && product) {
        window.dataLayer.push({
          event: 'EECaddToCart',
          affiliatedSalonNo,
          salonNo: selectedSalonNo,
          ecommerce: {
            add: {
              products: [product],
            },
          },
        });
      }
    } else {
      setTimeout(() => {
        if (typeof window !== 'undefined' && window.dataLayer && product) {
          window.dataLayer.push({
            event: 'EECaddToCart',
            affiliatedSalonNo,
            salonNo: selectedSalonNo,
            ecommerce: {
              add: {
                products: [product],
              },
            },
          });
        }
      }, [process.env.GTM_DELAY]);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('There was an error sending data to Google Tag Manager: ', error);
  }
};

export default useProductAddedTracking;
