/*
 * This icon is a bit smart. It accepts a volume prop.
 * If the volume is 0, it shows a Muted icon.
 * If the volume is between 0 and 1, it will hide and show
 * the loudspeaker paths that signify volume
 */

const STEP_MIN = 0.3;
const STEP_MED = 0.6;
const STEP_MAX = 0.85;

const VolumeIcon = ({ volume, size = '60%', color = '#FFF' }) => {
  if (!volume || parseFloat(volume, 10) === 0) {
    return <MutedIcon size={size} color={color} />;
  }

  const pathStyle = {
    fill: 'none',
    stroke: '#fff',
    strokeLinecap: 'square',
  };
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
    >
      <path
        d="M11.3 5v14l-5-4h-5V9h5l5-4z"
        style={{
          fill: color,
          stroke: color,
          strokeLinecap: 'square',
        }}
      />
      <path
        d="M11.3 14c1.5-1 1.5-3 0-4v4z"
        style={{
          fill: color,
        }}
      />
      <path
        d="M11.3 14c1.5-1 1.5-3 0-4"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
        }}
      />
      {volume > STEP_MIN && <path style={pathStyle} d="M14.3 16c2.1-2.1 2.1-5.9 0-8" />}
      {volume > STEP_MED && <path style={pathStyle} d="M16.3 19c4-4 4-10 0-14" />}
      {volume > STEP_MAX && <path style={pathStyle} d="M19.7 21c4-4 4-14 0-18" />}
    </svg>
  );
};

export default VolumeIcon;

const MutedIcon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m16.667 10 4 4m-4 0 4-4" stroke={color} strokeLinecap="square" />
    <path d="M11.333 5v14l-5-4h-5V9h5l5-4Z" fill={color} stroke={color} strokeLinecap="square" />
    <path d="M11.333 14c1.5-1 1.5-3 0-4v4Z" fill={color} />
    <path d="M11.333 14c1.5-1 1.5-3 0-4" stroke={color} strokeLinecap="round" />
  </svg>
);
