import HeartIcon from 'components/shared/heart-icon';
import { useWishlist, useLocalWishlist } from 'hooks';
import { getShopifyGid } from 'lib/shopify/shopifyGid';
import classNames from 'classnames';
import trackWishlist from 'lib/utils/trackWishlist';
import styles from './wishlistButton.module.scss';

const WishlistButton = ({ productData, shopifyData, className, list, index }) => {
  const { addToWishlist, removeFromWishlist } = useWishlist();
  const { localWishlist } = useLocalWishlist();

  if (!localWishlist) {
    return null;
  }

  const getGIDs = () => {
    if (!shopifyData) return [];
    if (Array.isArray(shopifyData)) {
      return shopifyData.map(({ productId }) => getShopifyGid('Product', productId));
    }
    return [getShopifyGid('Product', shopifyData?.productId)].filter(Boolean);
  };

  const GIDs = getGIDs();

  const isInWishlist = GIDs.length && GIDs.every(id => localWishlist.includes(id));

  const handleClick = async event => {
    event.stopPropagation();
    event.preventDefault();

    if (isInWishlist) {
      await removeFromWishlist(GIDs);
    } else {
      await addToWishlist(GIDs);
    }

    trackWishlist(
      productData,
      shopifyData,
      isInWishlist ? 'remove_from_wishlist' : 'add_to_wishlist',
      list,
      index
    );
  };

  if (!GIDs?.length) {
    return null;
  }

  // every checks the bundles
  return (
    <button type="button" className={classNames(styles.container, className)} onClick={handleClick}>
      <HeartIcon type={isInWishlist ? 'red' : ''} />
    </button>
  );
};

export default WishlistButton;
