import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';

const InView = ({ children, index, pushToDataLayerCb, className, product }) => {
  const domRef = useRef();
  const router = useRouter();
  const [prevProduct, setPrevProduct] = useState({});

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry?.isIntersecting && prevProduct?.id !== product.id) {
            pushToDataLayerCb(index);
            if (domRef?.current) {
              observer.unobserve(domRef.current);
            }
            setPrevProduct(product);
          }
        });
      },
      {
        threshold: [0.5],
      }
    );

    if (domRef?.current) {
      observer.observe(domRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [router?.asPath, product, prevProduct]);

  return (
    <div data-label="in-view" ref={domRef} className={cn(className)}>
      {children}
    </div>
  );
};

export default InView;
