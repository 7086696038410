import renderSections from 'components/sections';
import styles from './general.module.scss';

// We need to add the ignoreSpacing class only if we have a section of type 'ProfessionalsHeader' or 'BreadCrumbs', since the sections after those ones
// will ignore the general spacing rule of applying margin-top.
const PageGeneral = ({ data, locale, resources }) => (
  <>{renderSections(data?.name, locale, data?.sections, styles.ignoreSpacing, resources)}</>
);

export default PageGeneral;
