import { getProductSlugFromProductTitle } from '../../lib/utils/helpers';
import { parseShopifyGid } from '../../lib/shopify/shopifyGid';

const useViewCartTracking = ({
  currencyCode,
  lineItems,
  contentfulData,
  trackedItemsSelectList,
}) => {
  const generateCategories = productHandle => {
    const productSpecs = contentfulData?.find(c => c?.slug === productHandle)
      ?.productSpecsCollection?.items;

    if (productSpecs) {
      const productType = productSpecs.find(
        ({ type }) =>
          type.filterType === 'Producttype' ||
          type.filterType === 'Type de produit' ||
          type.filterType === 'Product type'
      );

      const productCategory = productSpecs.find(
        ({ type }) =>
          type.filterType === 'Productcategorie' ||
          type.filterType === 'Catégorie de produit' ||
          type.filterType === 'Product Category'
      );

      const productSubCategory = productSpecs.find(
        ({ type }) =>
          type.filterType === 'Subcategorie' ||
          type.filterType === 'Sous-catégorie' ||
          type.filterType === 'Sub Category'
      );
      return {
        item_category: productType?.value,
        item_category2: productCategory?.value,
        ...(productSubCategory?.value && { item_category3: productSubCategory?.value }),
      };
    }

    return {}; // freeItem has no productSpecsCollection
  };
  try {
    const ecommerce = {
      currency: currencyCode,
      items: lineItems.map(
        ({
          node: {
            title,
            quantity,
            variant: { priceV2, product },
          },
        }) => {
          const itemListName = trackedItemsSelectList?.find(
            p => p?.productId === parseShopifyGid(product?.id)
          )?.itemListName;

          const productSlug = getProductSlugFromProductTitle(title);
          return {
            item_name: title,
            item_id: parseShopifyGid(product?.id),
            price: priceV2?.amount,
            item_brand: 'Keune',
            ...generateCategories(productSlug),
            quantity,
            item_list_name: itemListName,
          };
        }
      ),
    };
    if (typeof window !== 'undefined' && window.dataLayer && ecommerce) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce
      window.dataLayer.push({
        event: 'view_cart',
        ecommerce,
      });
    } else {
      setTimeout(() => {
        if (typeof window !== 'undefined' && window.dataLayer && ecommerce) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce
          window.dataLayer.push({
            event: 'view_cart',
            ecommerce,
          });
        }
      }, [process.env.GTM_DELAY]);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('There was an error sending data to Google Tag Manager: ', error);
  }
};

export default useViewCartTracking;
