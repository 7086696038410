import { useState } from 'react';
import RichText from 'components/shared/richtext';
import classNames from 'classnames';
import { parseShopifyGid } from 'lib/shopify/shopifyGid';
import styles from './backInStockNotifier.module.scss';

const BackInStockNotifier = ({
  text,
  submitText = 'Submit',
  errorText = 'Something went wrong. Please try again',
  successText = 'Successfully subscribed!',
  productIds,
  className,
  locale,
}) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const subscribe = async productId => {
    const response = await fetch('/api/subscribe-back-in-stock', {
      method: 'POST',
      body: JSON.stringify({
        email,
        locale,
        productId,
      }),
      headers: { 'Content-Type': 'application/json' },
    });
    const data = await response.json();
    return data;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setError(false);
    setSuccess(false);

    const subscriptionCalls = productIds.map(productId => subscribe(parseShopifyGid(productId)));
    const responses = await Promise.all(subscriptionCalls);
    const hasError = responses.some(({ error }) => error);

    if (hasError) {
      setError(true);
      return;
    }

    setSuccess(true);
    setEmail('');
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      {text && <Title content={text} classes={styles.title} />}
      <form className={styles.form} onSubmit={handleSubmit}>
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
          placeholder="E-mail"
        />
        <button type="submit" className={styles.submitButton}>
          {submitText}
        </button>
      </form>
      {error && <span className={styles.error}>{errorText}</span>}
      {success && <SuccessText classes={styles.success} content={successText} />}
    </div>
  );
};

const Title = ({ content, classes }) =>
  content.map(text => {
    if (text.nodeType !== 'paragraph') {
      return <RichText className={classes} text={text} />;
    }

    return <RichText text={text} className={styles.subtitle} />;
  });
const SuccessText = ({ content, classes }) => <p className={classes}>{content}</p>;
export default BackInStockNotifier;
