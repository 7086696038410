const Pause = ({ width = 36, height = 36 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.5 6H7.5V30H13.5V6Z" fill="white" />
    <path d="M28.5 6H22.5V30H28.5V6Z" fill="white" />
  </svg>
);

export default Pause;
