import useSWR from 'swr';
import { get } from 'lib/utils/localStorage';
import { useRouter } from 'next/router';
import { getWishlistKey } from 'lib/shopify';

export const useLocalWishlist = () => {
  const { locale } = useRouter();
  // we use useSWR here, so we can use the mutate function to update the return value for wishlist buttons to sync across the site

  const {
    data: localWishlist,
    error,
    mutate: mutateLocalWishlist,
  } = useSWR(
    'local-wishlist', // this is the key to use when calling mutate in another component
    () => JSON.parse(get(getWishlistKey(locale)))?.filter(Boolean) || []
  );
  return { localWishlist, error, mutateLocalWishlist };
};

export default useLocalWishlist;
