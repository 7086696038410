const FacebookIcon = () => (
  <svg viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.84439 23.2001H7.94639V12.9801H11.3764L11.8944 9.00405H7.94639V6.45605C7.94639 5.30805 8.26839 4.52405 9.9204 4.52405H12.0344V0.954049C11.6704 0.912049 10.4244 0.800049 8.96839 0.800049C5.91639 0.800049 3.84439 2.66205 3.84439 6.06405V9.00405H0.400391V12.9801H3.84439V23.2001Z"
      fill="#3C3C3B"
    />
  </svg>
);

export default FacebookIcon;
