const AccountOn = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    viewBox="0 0 174.63 203.01"
    className={className}
  >
    <defs>
      <style>{'.st2{fill:#333}'}</style>
    </defs>
    <path
      d="M58.56 96.11c3.65 3.46 7.84 6.2 12.47 8.16 5.16 2.18 10.65 3.29 16.29 3.29s11.13-1.11 16.29-3.29c4.63-1.96 8.82-4.7 12.47-8.16 15.53 8.03 27.28 22.37 31.85 39.62 10.63-13.28 16.99-30.12 16.99-48.41 0-42.79-34.81-77.6-77.6-77.6S9.71 44.53 9.71 87.32c0 18.29 6.36 35.13 16.99 48.41 4.57-17.25 16.32-31.59 31.85-39.62Zm28.76-62.53c17.75 0 32.13 14.39 32.13 32.13 0 10.83-5.36 20.4-13.56 26.22-5.24 3.72-11.65 5.91-18.57 5.91s-13.33-2.19-18.57-5.91c-8.21-5.82-13.56-15.4-13.56-26.22 0-17.74 14.39-32.13 32.13-32.13Z"
      style={{
        fill: 'none',
      }}
    />
    <path
      d="M167.77 53.33c-4.4-10.4-10.69-19.74-18.71-27.75S131.7 11.27 121.31 6.87C110.54 2.31 99.1 0 87.32 0S64.1 2.31 53.33 6.86c-10.4 4.4-19.74 10.69-27.75 18.71S11.27 42.92 6.87 53.32C2.31 64.09 0 75.53 0 87.32s2.31 23.22 6.86 33.99a86.892 86.892 0 0 0 18.71 27.75c6.44 6.44 13.73 11.77 21.74 15.9.3-1.71.7-3.39 1.21-5.05.4-1.29.86-2.55 1.39-3.81.09-.22.19-.43.28-.65a78.298 78.298 0 0 1-23.5-19.73C16.06 122.44 9.7 105.6 9.7 87.31c.01-42.78 34.83-77.6 77.62-77.6s77.6 34.81 77.6 77.6c0 18.29-6.36 35.13-16.99 48.41a78.092 78.092 0 0 1-23.25 19.59c.12.26.23.52.34.79.53 1.25.99 2.52 1.39 3.81.5 1.61.89 3.24 1.19 4.9 7.9-4.11 15.09-9.39 21.46-15.75 8.02-8.02 14.31-17.35 18.71-27.75 4.56-10.77 6.86-22.2 6.86-33.99s-2.31-23.22-6.86-33.99Z"
      className="st2"
    />
    <path
      d="M147.92 135.73c-4.57-17.25-16.32-31.59-31.85-39.62-3.65 3.46-7.84 6.2-12.47 8.16-5.16 2.18-10.65 3.29-16.29 3.29s-11.13-1.11-16.29-3.29c-4.63-1.96-8.82-4.7-12.47-8.16-15.53 8.03-27.28 22.37-31.85 39.62a78.176 78.176 0 0 0 23.5 19.73c2.04-4.59 4.88-8.73 8.45-12.3 3.74-3.74 8.1-6.68 12.95-8.73 5.03-2.13 10.37-3.21 15.87-3.21s10.84 1.08 15.87 3.21c4.85 2.05 9.21 4.99 12.95 8.73 3.54 3.54 6.35 7.63 8.39 12.16a78.092 78.092 0 0 0 23.25-19.59Z"
      className="st2"
    />
    <path
      d="M68.75 91.93c5.24 3.72 11.65 5.91 18.57 5.91s13.33-2.19 18.57-5.91c8.21-5.82 13.56-15.4 13.56-26.22 0-17.74-14.38-32.13-32.13-32.13S55.19 47.97 55.19 65.71c0 10.83 5.36 20.4 13.56 26.22Z"
      className="st2"
    />
    <path
      d="M117.5 164.07c-.42-1.59-.96-3.13-1.61-4.61-4.81-10.91-15.72-18.53-28.42-18.53s-23.68 7.67-28.46 18.64c-.63 1.45-1.15 2.95-1.56 4.5a31.55 31.55 0 0 0-.88 4.99c-.09.96-.13 1.93-.13 2.91 0 17.14 13.9 31.04 31.04 31.04s31.04-13.9 31.04-31.04c0-1.02-.05-2.03-.15-3.02-.16-1.67-.45-3.3-.87-4.88Zm-33.48 23.78-17.91-17.91 6.95-6.95 10.97 10.97 17.86-17.86 6.95 6.95-24.81 24.81Z"
      style={{
        fill: 'currentColor',
      }}
    />
  </svg>
);
export default AccountOn;
