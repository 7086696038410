import styles from './preview.module.scss';

const Preview = ({ message }) => (
  <div className={styles.preview}>
    <span>Preview mode enabled</span>
    <span className={styles.message}>{message}</span>
  </div>
);

export default Preview;
