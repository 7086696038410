import fetchJson from 'lib/utils/fetchJson';

export const useCustomerSalon = locale => {
  const getCustomerSalon = async email => {
    try {
      const data = await fetchJson('/api/shopify/account/get-customer-salon/', {
        method: 'POST',
        body: JSON.stringify({ email, locale }),
      });
      const { salon } = data;
      return salon;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('getCustomer failed: ', error.message);
      return [];
    }
  };

  const setCustomerSalon = async (email, salon) => {
    try {
      const data = await fetchJson('/api/shopify/account/set-customer-salon/', {
        method: 'POST',
        body: JSON.stringify({
          email,
          salon,
          locale,
        }),
      });

      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('setCustomerWishlist failed: ', error.message);
      return null;
    }
  };

  return { getCustomerSalon, setCustomerSalon };
};

export default useCustomerSalon;
