import { useState } from 'react';
import Router from 'next/router';
import { useCustomer } from 'hooks';
import useLoggedInTracking from 'hooks/tracking/useLoggedInTracking';
import fetchJson from 'lib/utils/fetchJson';
import { keys, set } from 'lib/utils/localStorage';
import { addLocaleToHref } from 'lib/utils/helpers';
// eslint-disable-next-line import/no-named-as-default
import { setSessionCheckoutUrl } from 'lib/shopify';
import { useMultipass } from './useMultipass';

export const useLoginCustomer = locale => {
  const [state, setState] = useState({
    response: null,
    loading: false,
    error: null,
  });

  // if customer is already logged in, redirect to account
  const { mutateCustomer } = useCustomer({
    redirectTo: addLocaleToHref('/account/dashboard/', locale),
    redirectIfFound: false,
  });

  const loginCustomer = async (
    email,
    password,
    affiliatedSalonNo,
    selectedSalonNo,
    checkoutUrl
  ) => {
    if (email && password) {
      setState({ response: null, loading: true, error: null });
      try {
        await mutateCustomer(
          fetchJson('/api/shopify/account/login/', {
            method: 'POST',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'Content-Type',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email,
              password,
              locale,
            }),
          })
        );
        set(keys.LOGGED_IN_CUSTOMER, email);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useLoggedInTracking({ affiliatedSalonNo, selectedSalonNo, location: { Router } });
        setState({
          response: true,
          loading: false,
          error: null,
        });

        // Redirect to an existing checkout session
        // if it exists, and then clear it
        if (checkoutUrl) {
          setSessionCheckoutUrl(locale, '');
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const url = await useMultipass(email, checkoutUrl, locale);
          document.location.href = url;
        } else {
          Router.push(addLocaleToHref('/account/dashboard/', locale));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('useLoginCustomer failed: ', error?.data?.error);
        setState({
          response: null,
          loading: false,
          error: error?.data?.error || 'Login email or password is incorrect',
        });
      }
    } else {
      setState({
        response: null,
        loading: false,
        error: 'Email or password is missing',
      });
    }
  };

  return [loginCustomer, state];
};

export default useLoginCustomer;
