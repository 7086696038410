export const keys = {
  CHECKOUT: `checkout_id::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}`,
  USER: `shopify_local_user::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}`,
  LOGGED_SALON_NO: `logged::salonNo`,

  SELECTED_SALON_NO: `selected::salonNo`,
  CUSTOMER_SALON_NO: `customer::salonNo`,
  CUSTOMER_SALON_NAME: `customer::salonName`,
  COOKIES_ACCEPTED: `cookies::accepted`,
  LOGGED_IN_CUSTOMER: `logged_in_customer::loggedInCustomer`,
  USER_LOCALE_PREFERENCE: 'user_locale_preference',
  WISHLIST: `wishlist::keune`,
  // France store
  CHECKOUT_FR: `checkout_id::${process.env.NEXT_PUBLIC_SHOPIFY_FRENCH_ACTUAL_STORE_DOMAIN}`,
  WISHLIST_FR: `wishlist::keune-fr`,
  // France kickbackfee data
  SELECTED_FR_SALON_NO: `selected-fr::salonNo`,
  CUSTOMER_FR_SALON_NO: `customer-fr::salonNo`,
  CUSTOMER_FR_SALON_NAME: `customer-fr::salonName`,
  // United States store
  CHECKOUT_US: `checkout_id::${process.env.NEXT_PUBLIC_SHOPIFY_US_ACTUAL_STORE_DOMAIN}`,
  WISHLIST_US: `wishlist::keune-us`,

  // Germany store
  CHECKOUT_DE: `checkout_id::${process.env.NEXT_PUBLIC_SHOPIFY_DE_STORE_DOMAIN}`,
  WISHLIST_DE: `wishlist::keune-de`,
  // Germany kickbackfee data
  SELECTED_DE_SALON_NO: `selected-de::salonNo`,
  CUSTOMER_DE_SALON_NO: `customer-de::salonNo`,
  CUSTOMER_DE_SALON_NAME: `customer-de::salonName`,

  // UK store
  CHECKOUT_UK: `checkout_id::${process.env.NEXT_PUBLIC_SHOPIFY_UK_STORE_DOMAIN}`,
  WISHLIST_UK: `wishlist::keune-uk`,
  // UK kickbackfee data
  SELECTED_UK_SALON_NO: `selected-uk::salonNo`,
  CUSTOMER_UK_SALON_NO: `customer-uk::salonNo`,
  CUSTOMER_UK_SALON_NAME: `customer-uk::salonName`,

  // Belgium store for both /fr-be and /nl-be locales:
  CHECKOUT_BE: `checkout_id::${process.env.NEXT_PUBLIC_SHOPIFY_BE_STORE_DOMAIN}`,
  WISHLIST_BE: `wishlist::keune-be`,
  // BE kickbackfee data
  SELECTED_BE_SALON_NO: `selected-be::salonNo`,
  CUSTOMER_BE_SALON_NO: `customer-be::salonNo`,
  CUSTOMER_BE_SALON_NAME: `customer-be::salonName`,

  // Spain store
  CHECKOUT_ES: `checkout_id::${process.env.NEXT_PUBLIC_SHOPIFY_ES_STORE_DOMAIN}`,
  WISHLIST_ES: `wishlist::keune-es`,
  // ES kickbackfee data
  SELECTED_ES_SALON_NO: `selected-es::salonNo`,
  CUSTOMER_ES_SALON_NO: `customer-es::salonNo`,
  CUSTOMER_ES_SALON_NAME: `customer-es::salonName`,

  // Norway store
  CHECKOUT_NO: `checkout_id::${process.env.NEXT_PUBLIC_SHOPIFY_NO_STORE_DOMAIN}`,
  WISHLIST_NO: `wishlist::keune-no`,
  // NO kickbackfee data
  SELECTED_NO_SALON_NO: `selected-no::salonNo`,
  CUSTOMER_NO_SALON_NO: `customer-no::salonNo`,
  CUSTOMER_NO_SALON_NAME: `customer-no::salonName`,

  // Sweden store
  CHECKOUT_SE: `checkout_id::${process.env.NEXT_PUBLIC_SHOPIFY_SE_STORE_DOMAIN}`,
  WISHLIST_SE: `wishlist::keune-se`,
  // SE kickbackfee data
  SELECTED_SE_SALON_NO: `selected-se::salonNo`,
  CUSTOMER_SE_SALON_NO: `customer-se::salonNo`,
  CUSTOMER_SE_SALON_NAME: `customer-se::salonName`,
};

export function set(key, value) {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    window.localStorage.setItem(key, value);
  }
}

export function remove(key) {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    window.localStorage.removeItem(key);
  }
}

export function get(key) {
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) {
    return null;
  }

  try {
    const item = window.localStorage.getItem(key);
    return item;
  } catch {
    return null;
  }
}
