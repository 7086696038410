const SearchIcon = ({ className, onClick, width = 28, height = 28 }) => (
  <svg
    className={className}
    width={width}
    height={height}
    onClick={onClick}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8148 16.8143L23.334 23.3335"
      stroke="#3C3C3B"
      strokeWidth="1.2"
      strokeLinecap="square"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.666 18.6665C15.532 18.6665 18.666 15.5325 18.666 11.6665C18.666 7.80051 15.532 4.6665 11.666 4.6665C7.80002 4.6665 4.66602 7.80051 4.66602 11.6665C4.66602 15.5325 7.80002 18.6665 11.666 18.6665Z"
      stroke="#3C3C3B"
      strokeWidth="1.2"
      strokeLinecap="square"
    />
  </svg>
);

export default SearchIcon;
