import { useWindowSize } from './useWindowSize';

export function useIsMobile() {
  const size = useWindowSize();

  if (size.width === undefined) {
    return null;
  }
  return size.width <= 900 && size.height > size.width;
}
export default useIsMobile;
