import { useRef, useState } from 'react';
import Image from 'components/shared/image/image';
import handleDrag from 'lib/utils/handleDrag';
import Sitelink from 'components/shared/sitelink';
import classNames from 'classnames';
import styles from './instagramFeed.module.scss';

const InstagramFeed = ({ data }) => {
  const { title, username, instagramPosts } = data;
  const carouselRef = useRef(null);
  const [dragging, setDragging] = useState(false);

  return (
    <section id="sectionInstagramFeed" className={styles.instagramFeedSection}>
      <div className={styles.feedInfo}>
        <p className={styles.feedUsername}>
          <a href={`https://www.instagram.com/${username}`} target="__blank">
            @{username}
          </a>
        </p>
        <h3 className={styles.feedTitle}>
          <a href={`https://www.instagram.com/${username}`} target="__blank">
            {title}
          </a>
        </h3>
      </div>
      <div
        role="tablist"
        className={styles.carouselWrapper}
        ref={carouselRef}
        tabIndex={0}
        onPointerDown={e => {
          setTimeout(() => setDragging(true), 100);
          const { scrollLeft } = carouselRef.current;
          handleDrag(e, carouselRef, {
            leftSideCallback: (ev, delta) => {
              carouselRef.current.scrollLeft = scrollLeft - delta.x;
            },
            rightSideCallback: (ev, delta) => {
              carouselRef.current.scrollLeft = scrollLeft - delta.x;
            },
          });
        }}
        onPointerUp={() => setDragging(false)}
      >
        <div className={classNames(styles.instagramCarousel, { [styles.disabledLinks]: dragging })}>
          {instagramPosts.map(instagramPost => (
            <Sitelink
              external={`https://www.instagram.com/${username}`}
              className={styles.instagramPost}
              key={instagramPost?.title}
              draggable={false}
            >
              <Image
                draggable={false}
                src={instagramPost}
                layout="responsive"
                loading="lazy"
                sizes="(max-width: 899px) 50vw, 25vw"
              />
            </Sitelink>
          ))}
        </div>
      </div>
    </section>
  );
};

export default InstagramFeed;
