import { createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import { useRouter } from 'next/router';
import { getSelectedSalonNo } from 'lib/shopify';

export const SETTING_ACTION_TYPES = {
  SET_SALONS: 'set_salons',
  SET_CURRENT_SALON: 'set_current_salon',
  ADD_PRODUCT: 'add_product',
  ADD_PRODUCTS: 'add_products',
  REMOVE_PRODUCT: 'remove_product',
  CLEAR_CART: 'clear_cart',
  DISABLE_FREE_ITEM: 'disable_free_item',
  SET_SELECT_ITEM_TRACKING: 'set_select_item_tracking',
  SET_COUNTRY_CODE: 'set_country_code',
  SET_SALON_MANUALLY_DELETED: 'set_salon_manually_deleted',
};

const initialState = {
  salons: [],
  cartContents: [],
  currentSalon: null,
  salonManuallyDeleted: false,
  isFreeItemDisabled: false,
  /* example of item object that is pushed to trackedItemsSelectList
  * {
    productId: null,
    itemListName: null,
    itemIndex: null,
  } */
  trackedItemsSelectList: [],
  countryCode: '',
};

export const SettingContext = createContext(initialState);

const settingReducer = (state, action) => {
  const productsToAdd = [];
  switch (action.type) {
    case SETTING_ACTION_TYPES.SET_SALONS:
      return {
        ...state,
        salons: [...action.value],
      };
    case SETTING_ACTION_TYPES.SET_CURRENT_SALON:
      return {
        ...state,
        currentSalon: action.value,
      };
    case SETTING_ACTION_TYPES.ADD_PRODUCT:
      if (
        state.cartContents.find(product => product.variantId === action.value.variantId) ===
        undefined
      ) {
        return {
          ...state,
          cartContents: [...state.cartContents, action.value],
        };
      }
      return { ...state };
    case SETTING_ACTION_TYPES.ADD_PRODUCTS:
      for (let i = 0; i < action.value.length; i += 1) {
        if (
          state.cartContents.find(product => product.variantId === action.value[i].variantId) ===
          undefined
        )
          productsToAdd.push(action.value[i]);
      }
      if (productsToAdd.length > 0)
        return {
          ...state,
          cartContents: [...state.cartContents, ...productsToAdd],
        };
      return { ...state };
    case SETTING_ACTION_TYPES.REMOVE_PRODUCT:
      return {
        ...state,
        cartContents: state.cartContents.filter(product => product.variantId !== action.value),
      };
    case SETTING_ACTION_TYPES.CLEAR_CART:
      return {
        ...state,
        cartContents: [],
      };
    case SETTING_ACTION_TYPES.DISABLE_FREE_ITEM:
      return {
        ...state,
        isFreeItemDisabled: true,
      };
    case SETTING_ACTION_TYPES.SET_SELECT_ITEM_TRACKING:
      return {
        ...state,
        trackedItemsSelectList: [...state.trackedItemsSelectList, action.value],
      };
    case SETTING_ACTION_TYPES.SET_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.value,
      };
    case SETTING_ACTION_TYPES.SET_SALON_MANUALLY_DELETED:
      return {
        ...state,
        salonManuallyDeleted: action.value,
      };
    default:
      return state;
  }
};

export const SettingProvider = props => {
  const [settingState, updateSettingState] = useReducer(settingReducer, initialState);

  const setSalons = salons =>
    updateSettingState({ type: SETTING_ACTION_TYPES.SET_SALONS, value: salons });
  const addProduct = product =>
    updateSettingState({ type: SETTING_ACTION_TYPES.ADD_PRODUCT, value: product });
  const addProducts = products =>
    updateSettingState({ type: SETTING_ACTION_TYPES.ADD_PRODUCTS, value: products });
  const removeProduct = productID =>
    updateSettingState({ type: SETTING_ACTION_TYPES.REMOVE_PRODUCT, value: productID });
  const clearProduct = () => updateSettingState({ type: SETTING_ACTION_TYPES.CLEAR_CART });
  const setCurrentSalon = salon =>
    updateSettingState({ type: SETTING_ACTION_TYPES.SET_CURRENT_SALON, value: salon });
  const disableFreeItem = () =>
    updateSettingState({ type: SETTING_ACTION_TYPES.DISABLE_FREE_ITEM });
  const setSaveItemSelectionList = trackingObj =>
    updateSettingState({ type: SETTING_ACTION_TYPES.SET_SELECT_ITEM_TRACKING, value: trackingObj });
  const setSalonManuallyDeleted = value =>
    updateSettingState({ type: SETTING_ACTION_TYPES.SET_SALON_MANUALLY_DELETED, value });
  const setCountryCode = newCountryCode =>
    updateSettingState({
      type: SETTING_ACTION_TYPES.SET_COUNTRY_CODE,
      value: newCountryCode,
    });

  const router = useRouter();

  useEffect(() => {
    const salonNo = getSelectedSalonNo(router.locale);
    const currentSalon = settingState.salons.find(salon => salon.salonNo === salonNo);
    setCurrentSalon(currentSalon);
  }, [settingState.salons, router.locale]);

  const settingsValue = useMemo(
    () => ({
      ...settingState,
      setSalons,
      addProduct,
      addProducts,
      removeProduct,
      clearProduct,
      setCurrentSalon,
      disableFreeItem,
      setSaveItemSelectionList,
      setCountryCode,
      setSalonManuallyDeleted,
    }),
    [settingState]
  );

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <SettingContext.Provider value={settingsValue} {...props} />;
};

export const useSettings = () => {
  const settingsContext = useContext(SettingContext);
  if (settingsContext === undefined) {
    throw new Error('Settings context can only be used within context provider');
  }
  return settingsContext;
};

export const SettingsContextProvider = ({ children }) => (
  <SettingProvider>{children}</SettingProvider>
);
