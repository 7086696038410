export const trackSelectedSalon = ({ salonNo, eventName, location }) => {
  try {
    if (location.action) {
      if (typeof window !== 'undefined' && window.dataLayer && salonNo) {
        window.dataLayer.push({
          event: eventName,
          salonNo,
        });
      }
    } else {
      setTimeout(() => {
        if (typeof window !== 'undefined' && window.dataLayer && salonNo) {
          window.dataLayer.push({
            event: eventName,
            salonNo,
          });
        }
      }, [process.env.GTM_DELAY]);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('There was an error sending data to Google Tag Manager: ', error);
  }
};

export default trackSelectedSalon;
