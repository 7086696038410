import { useEffect, useState } from 'react';
import styles from './evesStars.module.scss';

const EvesStars = ({ eanNumber, locale }) => {
  const [waeData, setWaeData] = useState({});

  const waeSettings = {
    target: '#wae-sdk',
    // ratingTargets: ['.wae-rating'],
    viewType: 'REVIEW',
    productIdType: 'BARCODE',
    productId: eanNumber,
    brandId: process.env.NEXT_PUBLIC_WAE_BRAND_ID,
    API_KEY: process.env.NEXT_PUBLIC_WAE_API_KEY,
    language: locale,
    country: locale,
  };

  const fetchWaeGeneralData = async () => {
    setWaeData({});
    try {
      const request = await fetch(
        `https://api.weareeves.com/embed/v2/productReviews?${new URLSearchParams({
          brandId: waeSettings.brandId,
          API_KEY: waeSettings.API_KEY,
          embedId: waeSettings.productId,
          idType: waeSettings.productIdType,
          country: waeSettings.country,
          language: waeSettings.language,
          pageSize: 0,
        })}`
      );

      if (request.ok) {
        const data = await request.json();
        setWaeData(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [half, setHalf] = useState(0);

  useEffect(() => {
    const isProd = window.location.hostname === process.env.NEXT_PUBLIC_SITE_HOSTNAME;

    if (isProd) {
      fetchWaeGeneralData();
    }
  }, [eanNumber]);

  useEffect(() => {
    const decimal = waeData?.averageRating - Math.floor(waeData?.averageRating);

    if (decimal) {
      setHalf(Math.trunc(Math.abs(decimal) * 100));
    }
  }, [waeData]);

  const getIcon = i => {
    const decimal = waeData?.averageRating - Math.floor(waeData?.averageRating);

    if (i > waeData?.averageRating - decimal) {
      return (
        <svg
          className={styles.starIcon}
          viewBox="0 0 32 32"
          style={{ fill: 'white', stroke: 'black' }}
        >
          {decimal && i - waeData?.averageRating < 1 ? (
            <use xlinkHref="#star" fill={`url(#half-${eanNumber})`} />
          ) : (
            <use xlinkHref="#star" />
          )}
        </svg>
      );
    }

    return (
      <>
        <svg
          className={styles.starIcon}
          viewBox="0 0 32 32"
          style={{ fill: 'black', stroke: 'black' }}
        >
          <use xlinkHref="#star" />
        </svg>
      </>
    );
  };

  const getRating = () => {
    const stars = [];
    for (let i = 1; i <= 5; i += 1) {
      stars.push(getIcon(i));
    }

    return (
      <p
        className={styles.averageRatingContainer}
        aria-label={`${waeData?.averageRating} stars out of 5`}
      >
        <span className={styles.averageRating}>{waeData.averageRating?.toFixed(1)}</span>
        {stars}
        <span className={styles.totalCount}>({waeData.totalCount})</span>
      </p>
    );
  };

  return (
    <section className={styles.container}>
      {waeData.totalCount && waeData?.averageRating ? (
        <>
          <div style={{ width: 0, height: 0 }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <defs>
                {half && (
                  <linearGradient id={`half-${eanNumber}`} x1="0" x2="100%" y1="0" y2="0">
                    <stop offset={`${half}%`} stopColor="black" />
                    <stop offset={`${half}%`} stopColor="white" />
                  </linearGradient>
                )}

                <symbol
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  id="star"
                  strokeWidth="6%"
                >
                  <path d="M 12 1.5 L 8.5878906 8.4121094 L 0.9609375 9.5214844 L 6.4804688 14.902344 L 5.1777344 22.5 L 12 18.912109 L 18.822266 22.5 L 17.519531 14.902344 L 23.039062 9.5214844 L 15.412109 8.4121094 L 12 1.5 z" />
                </symbol>
              </defs>
            </svg>
          </div>
          {getRating()}
        </>
      ) : null}
    </section>
  );
};

export default EvesStars;
