import { mutate } from 'swr';
import { useCustomer, useCustomerWishlist, useLocalWishlist } from 'hooks';
import { set } from 'lib/utils/localStorage';
import fetchJson from 'lib/utils/fetchJson';
import { getShopifyGid } from 'lib/shopify/shopifyGid';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { getWishlistKey } from '../../lib/shopify';

/**
 * Let me explain the wishlist system here:
 *
 * - The wishlist is essentially an array of Shopify product ids, which is used to identify unique product variants across platforms (Shopify, Storyblok)
 *
 * - For the following requirements, we have 2 wishlists which are identical but saved in different places:
 * (1) localStorage wishlist - for customers who are not logged in
 * (2) Shopify wishlist - for customers with an account, so logging out and in again will have the same wishlist products. Saved to a customer metafield via Shopify Admin API
 *
 * - /wishlist/ is for customers who are not logged in; if customer is logged in, visiting this page will redirect to /account/wishlist/
 *
 * - When not logged in, wishlist is saved to and retrieved from localStorage; when logged in, the two wishlists should be synced. This will be done in the /account/ page main component, because this is where all freshly logged in customers will land; and after the customer is logged in, all wishlist functions will begin to update Shopify wishlist and localStorage wishlist so the wishlists will be in sync from this moment on.
 *
 * - When not logged in, only localStorage wishlist is updated of course
 */

export function useWishlist() {
  const { mutateCustomer } = useCustomer();
  const { locale } = useRouter();
  const wishlistKey = getWishlistKey(locale);

  const { setCustomerWishlist, getCustomerWishlist } = useCustomerWishlist(locale);
  const { localWishlist } = useLocalWishlist();
  const [state, setState] = useState({
    response: null,
    loading: false,
    error: null,
  });

  const addToWishlist = async shopifyProductIdArr => {
    setState({
      response: null,
      loading: true,
      error: null,
    });

    const customer = await mutateCustomer(
      fetchJson('/api/shopify/account/customer', {
        method: 'POST',
      })
    );

    const GIDs = shopifyProductIdArr.map(id => getShopifyGid('Product', id));
    const newWishlist = localWishlist ? [...localWishlist, ...GIDs] : GIDs;
    const newUniqueWishlist = [...new Set(newWishlist)]; // make sure there is no duplicates

    set(wishlistKey, JSON.stringify(newUniqueWishlist));
    mutate('local-wishlist'); // updates the local wishlist useSWR to update the wishlist buttons across the site

    if (customer?.isLoggedIn) {
      const data = await setCustomerWishlist(customer.email, newUniqueWishlist);
      if (!data) {
        setState({
          response: null,
          loading: false,
          error: true,
        });
        return null;
      }
    }

    setState({
      response: true,
      loading: false,
      error: null,
    });

    return null;
  };

  const removeFromWishlist = async shopifyProductIdArr => {
    if (!localWishlist) {
      return;
    }

    setState({
      response: null,
      loading: true,
      error: null,
    });

    const customer = await mutateCustomer(
      fetchJson('/api/shopify/account/customer', {
        method: 'POST',
      })
    );

    const GIDs = shopifyProductIdArr.map(id => getShopifyGid('Product', id));
    const newWishlist = localWishlist.filter(
      localShopifyProductId => !GIDs.includes(localShopifyProductId)
    );
    const newUniqueWishlist = [...new Set(newWishlist)]; // make sure there is no duplicates

    set(wishlistKey, JSON.stringify(newUniqueWishlist));
    mutate('local-wishlist'); // updates the local wishlist useSWR to update the wishlist buttons across the site

    if (customer?.isLoggedIn) {
      const data = await setCustomerWishlist(customer.email, newUniqueWishlist);
      if (!data) {
        setState({
          response: null,
          loading: false,
          error: true,
        });
        return;
      }
    }

    setState({
      response: true,
      loading: false,
      error: null,
    });
  };

  const syncWishlists = async customer => {
    setState({
      response: null,
      loading: true,
      error: null,
    });
    // sync local and shopify wishlists when logged in
    const shopifyWishlist = await getCustomerWishlist(customer.email);

    if (!shopifyWishlist) {
      setState({
        response: false,
        loading: false,
        error: true,
      });
      return;
    }

    const syncedWishlist = [
      ...new Set(localWishlist ? localWishlist.concat(shopifyWishlist) : shopifyWishlist),
    ];

    const data = await setCustomerWishlist(customer.email, syncedWishlist);
    if (!data) {
      setState({
        response: false,
        loading: false,
        error: true,
      });
      // eslint-disable-next-line no-console
      console.error('Error at useWishlist: Failed to sync Shopify wishlist');
    }

    set(wishlistKey, JSON.stringify(syncedWishlist));
    mutate('local-wishlist'); // updates the local wishlist useSWR to update the wishlist buttons across the site

    setState({
      response: true,
      loading: false,
      error: null,
    });
  };

  return {
    addToWishlist,
    removeFromWishlist,
    syncWishlists,
    wishlistState: state,
  };
}

export default useWishlist;
