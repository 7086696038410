import manageFreeItem from 'lib/shopify/manageFreeItem';
import fetchJson from 'lib/utils/fetchJson';
import { mutate } from 'swr';
import { useSettings } from 'contexts/SettingContext';
import { useRouter } from 'next/router';
import { getCheckoutIdByLocale } from 'lib/shopify';
import { useUI } from 'hooks';

export function useAddItemsToCart() {
  const { isFreeItemDisabled } = useSettings();
  const { locale, pathname } = useRouter();
  const { openCartPopup } = useUI();

  async function addItems(items, freeItem, freeItemResources) {
    // [{quantity: quantity, variantId: 'variantId'}]
    const checkoutId = getCheckoutIdByLocale(locale);
    let error = null;

    if (items.length < 1) {
      error = 'Must include at least one line item, empty line items found';
    }

    items.forEach(item => {
      if (item.variantId == null) {
        error = `Missing variantId in item`;
      }

      if (item.quantity == null) {
        error = `Missing quantity in item with variant id: ${item.variantId}`;
      } else if (typeof item.quantity !== 'number') {
        error = `Quantity is not a number in item with variant id: ${item.variantId}`;
      } else if (item.quantity < 1) {
        error = `Quantity must not be less than one in item with variant id: ${item.variantId}`;
      }
    });

    if (error) {
      console.log(error);
      return null;
    }

    async function addLineItems(itemsArray) {
      const data = await fetchJson('/api/shopify/checkout/add-items/', {
        method: 'POST',
        body: JSON.stringify({
          checkoutId,
          lineItems: itemsArray,
          locale,
        }),
      });
      // Update cart

      await mutate('/api/shopify/checkout/request/', { ...data, locale }, false);

      // If freeitem adding has been disabled
      // we don't need to do anything
      if (!isFreeItemDisabled) {
        await manageFreeItem(data, freeItem, freeItemResources, locale);
      }
      if (pathname !== '/cart') openCartPopup();
      return data;
    }

    return addLineItems(items);
  }

  return addItems;
}

export default useAddItemsToCart;
