const useLoggedInTracking = ({ affiliatedSalonNo, selectedSalonNo, location }) => {
  try {
    if (location.action) {
      if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({
          event: 'login',
          affiliatedSalonNo,
          salonNo: selectedSalonNo,
        });
      }
    } else {
      setTimeout(() => {
        if (typeof window !== 'undefined' && window.dataLayer) {
          window.dataLayer.push({
            event: 'login',
            affiliatedSalonNo,
            salonNo: selectedSalonNo,
          });
        }
      }, [process.env.GTM_DELAY]);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('There was an error sending data to Google Tag Manager: ', error);
  }
};

export default useLoggedInTracking;
