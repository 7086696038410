import useSWR from 'swr';
import fetchJson from 'lib/utils/fetchJson';
import { useRouter } from 'next/router';
import getNewCheckout from 'lib/shopify/getNewCheckout';
import { getCheckoutIdByLocale } from 'lib/shopify';

export function useCart() {
  const { locale } = useRouter();

  // Get initial cart from localstorage
  const checkoutId = getCheckoutIdByLocale(locale);

  const getCheckoutLanguageByLocale = l => {
    switch (l) {
      case 'uk':
        return 'en';
      default:
        return locale;
    }
  };

  const { data, error } = useSWR(
    '/api/shopify/checkout/request/',
    async url => {
      let checkout;

      // if there is a checkout id - fetch the checkout
      if (checkoutId) {
        try {
          checkout = await fetchJson(url, {
            method: 'POST',
            body: JSON.stringify({ id: checkoutId, locale }),
          });
        } catch {
          // If there is an error with the checkout, generate a new one(cart expired as example)
          checkout = await getNewCheckout(locale);
        }
      }

      // create new checkout when there is no id or a completed checkout or when checkout.data === null
      // also check if id starts with gid://shopify/Cart/

      if (
        !checkout?.data ||
        checkout?.data?.completedAt ||
        !checkoutId ||
        !checkoutId.startsWith('gid://shopify/Cart/')
      ) {
        checkout = await getNewCheckout(locale);
      }

      // Make sure to refetch a new cart if it was created more than 3 months from now;
      const createdAt = new Date(checkout.data.createdAt);
      const now = new Date();
      const differenceInTime = now.getTime() - createdAt.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      if (differenceInDays > 85) {
        checkout = await getNewCheckout(locale);
      }

      const lineItems = checkout?.data?.lines?.edges ?? [];
      const existingLineItems = lineItems.filter(item => item.node.merchandise?.id);

      // If there are draft line items, create a new checkout with only existing ones
      if (lineItems.length !== existingLineItems.length) {
        checkout = await getNewCheckout(locale, existingLineItems);
      }

      return checkout;
    },
    {
      revalidateOnFocus: false,
    }
  );

  return {
    cart: {
      ...data?.data,
      webUrl: `${data?.data?.checkoutUrl}?locale=${getCheckoutLanguageByLocale(locale)}`,
    },
    error,
    isEmpty: !(data?.data?.lines?.edges?.length ?? 0),
  };
}

export default useCart;
