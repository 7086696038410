const useAccountCreatedTracking = ({ affiliatedSalonNo, selectedSalonNo, location }) => {
  const registerEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'create_account',
      affiliatedSalonNo,
      salonNo: selectedSalonNo,
    });
  };

  try {
    if (location.action && typeof window !== 'undefined' && window.dataLayer) {
      registerEvent();
    } else {
      setTimeout(() => {
        registerEvent();
      }, [process.env.GTM_DELAY]);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('There was an error sending data to Google Tag Manager: ', error);
  }
};

export default useAccountCreatedTracking;
