import { set, keys } from 'lib/utils/localStorage';
import fetchJson from 'lib/utils/fetchJson';
import { WEBSHOP_STORES } from './index';

export const getNewCheckout = async (locale, lineItems) => {
  const formattedLineItems = lineItems?.map(({ node }) => ({
    variantId: node.merchandise.id,
    quantity: node.quantity,
    sellingPlanId: node.sellingPlanId || null,
  }));

  const checkout = await fetchJson('/api/shopify/checkout/create/', {
    method: 'POST',
    body: JSON.stringify({ lineItems: formattedLineItems || [], locale }),
  });
  // set new checkout id in localStorage

  switch (locale) {
    case WEBSHOP_STORES.FR:
      set(keys.CHECKOUT_FR, checkout.data.id);
      break;

    case WEBSHOP_STORES.US:
      set(keys.CHECKOUT_US, checkout.data.id);
      break;

    case WEBSHOP_STORES.DE:
      set(keys.CHECKOUT_DE, checkout.data.id);
      break;

    case WEBSHOP_STORES.UK:
      set(keys.CHECKOUT_UK, checkout.data.id);
      break;

    case WEBSHOP_STORES.NL_BE:
    case WEBSHOP_STORES.FR_BE:
      set(keys.CHECKOUT_BE, checkout.data.id);
      break;

    case WEBSHOP_STORES.ES:
      set(keys.CHECKOUT_ES, checkout.data.id);
      break;

    case WEBSHOP_STORES.NO:
      set(keys.CHECKOUT_NO, checkout.data.id);
      break;

    case WEBSHOP_STORES.SE:
      set(keys.CHECKOUT_SE, checkout.data.id);
      break;

    default:
      // NL store
      set(keys.CHECKOUT, checkout.data.id);
  }

  return checkout;
};

export default getNewCheckout;
