import { getCurrencyCode } from 'lib/shopify';

const useCollectionSelectItemTracking = ({ product, list, setSaveItemSelectionList, locale }) => {
  try {
    const ecommerce = {
      currency: getCurrencyCode(locale),
      items: [
        {
          item_name: product?.name,
          item_id: String(product?.id),
          price: product?.price,
          item_brand: 'Keune',
          item_category: product?.category,
          ...(product?.category2 && { item_category2: product?.category2 }),
          ...(product?.category3 && { item_category3: product?.category3 }),
          item_list_name: list,
          index: product?.position,
          quantity: 1,
        },
      ],
    };

    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

      window.dataLayer.push({
        event: 'select_item',
        ecommerce,
      });
      setSaveItemSelectionList({
        itemListName: ecommerce.items[0]?.item_list_name,
        itemIndex: ecommerce.items[0]?.index,
        productId: ecommerce.items[0]?.item_id,
      });
    } else {
      setTimeout(() => {
        if (typeof window !== 'undefined' && window.dataLayer) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

          window.dataLayer.push({
            event: 'select_item',
            ecommerce,
          });
          setSaveItemSelectionList({
            itemListName: ecommerce.items[0]?.item_list_name,
            itemIndex: ecommerce.items[0]?.index,
            productId: ecommerce.items[0]?.item_id,
          });
        }
      }, [process.env.GTM_DELAY]);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('There was an error sending data to Google Tag Manager: ', error);
  }
};

export default useCollectionSelectItemTracking;
