import classNames from 'classnames';
import Img from 'next/image';
import { useRouter } from 'next/router';
import { addLocaleToHref } from 'lib/utils/helpers';
import imageLoader from 'lib/contentful/imageLoader';
import styles from './image.module.scss';
import SiteLink from '../sitelink';

const Image = ({
  src,
  alt,
  className,
  layout = 'responsive',
  loading = 'lazy',
  draggable = true,
  priority = false,
  sizes = '100vw',
  quality = 75,
  lazyBoundary = '200px',
}) => {
  const { query } = useRouter();

  // NOTE: when using layout='fill' the class must have css -- position: relative;
  // DOCS: https://nextjs.org/docs/api-reference/next/image

  const altText = () => {
    if (alt) {
      return alt;
    }
    if (src?.title) {
      return src?.title;
    }
    return process.env.NEXT_PUBLIC_SITENAME;
  };

  const ImageFromSrc = (src?.baseUrl || src?.url || src?.transformedSrc || src?.originalSrc) && (
    <Img
      loader={imageLoader}
      draggable={draggable}
      src={`${src?.baseUrl || src?.url || src?.transformedSrc || src?.originalSrc}`}
      height={layout === 'fill' ? null : src?.height}
      width={layout === 'fill' ? null : src?.width}
      objectFit={layout === 'fill' ? 'cover' : 'fill'}
      layout={layout}
      alt={altText()}
      loading={loading}
      priority={priority}
      sizes={sizes}
      quality={quality}
      lazyBoundary={lazyBoundary}
    />
  );

  const imageDesktop = src?.desktop || null;
  const imageMobile = src?.mobile || null;
  const link = src?.link || imageMobile?.link || imageDesktop?.link;
  const externalLink = src?.externalLink || imageMobile?.externalLink || imageDesktop?.externalLink;

  const ImageMobile = imageMobile?.baseUrl && (
    <div className={classNames(styles.mobile, { [styles.visibleDesktop]: !imageDesktop })}>
      <Img
        loader={imageLoader}
        draggable={draggable}
        src={`${imageMobile?.baseUrl}`}
        height={layout === 'fill' ? null : imageMobile?.height}
        width={layout === 'fill' ? null : imageMobile?.width}
        objectFit={layout === 'fill' ? 'cover' : 'fill'}
        layout={layout}
        alt={altText()}
        loading={loading}
        priority={priority}
        sizes={sizes}
        quality={quality}
        lazyBoundary={lazyBoundary}
      />
    </div>
  );

  const ImageDesktop = imageDesktop?.baseUrl && (
    <div className={classNames(styles.desktop)}>
      <Img
        loader={imageLoader}
        draggable={draggable}
        src={`${imageDesktop?.baseUrl}`}
        height={layout === 'fill' ? null : imageDesktop?.height}
        width={layout === 'fill' ? null : imageDesktop?.width}
        objectFit={layout === 'fill' ? 'cover' : 'fill'}
        loading={loading}
        priority={priority}
        layout={layout}
        alt={altText()}
        sizes={sizes}
        quality={quality}
        lazyBoundary={lazyBoundary}
      />
    </div>
  );

  if (externalLink) {
    return (
      <SiteLink
        locale={query.locale}
        external={externalLink}
        className={classNames(className, styles.imageLink)}
      >
        {ImageFromSrc}
        {ImageMobile}
        {ImageDesktop}
      </SiteLink>
    );
  }

  if (link) {
    return (
      <SiteLink
        locale={query.locale}
        href={addLocaleToHref(
          link.slug,
          query.locale?.split('-')?.[0]?.length > 2 ? 'en' : query.locale
        )}
        className={classNames(className, styles.imageLink)}
      >
        {ImageFromSrc}
        {ImageMobile}
        {ImageDesktop}
      </SiteLink>
    );
  }

  return (
    <div className={classNames(className)}>
      {ImageFromSrc}
      {ImageMobile}
      {ImageDesktop}
    </div>
  );
};

export default Image;
