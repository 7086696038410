import Icon from 'components/shared/icon';
import { useEffect, useState } from 'react';
import RichText from 'components/shared/richtext';
import { useRouter } from 'next/router';
import styles from './kickbackfeePopup.module.scss';
import SiteLink from '../../shared/sitelink';
import { addLocaleToHref } from '../../../lib/utils/helpers';

const KickbackfeePopup = ({ globalModules, locale }) => {
  const { title, message, shopNowLink } = globalModules?.['kickbackfee-popup'] || {};
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [salonName, setSalonName] = useState('');

  useEffect(() => {
    if (!router.isReady) return;

    const kickbackFeeSalonName = router.query.fromKickbackFeeCode;

    if (kickbackFeeSalonName) {
      setOpen(true);
      setSalonName(kickbackFeeSalonName);
      document.body.style.overflow = 'hidden';
    }
  }, [router.query, router.isReady]);

  const handleClose = () => {
    document.body.style.overflow = 'auto';
    const { pathname, query } = router;
    delete router.query.fromKickbackFeeCode;
    router.replace({ pathname, query }, undefined, { shallow: true });
    setOpen(false);
  };

  if (!open) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <button
          type="button"
          className={styles.closeBtn}
          onClick={() => {
            handleClose();
          }}
        >
          <Icon type="close" />
        </button>
        <div className={styles.title}>
          {title?.text}
          <span>{salonName}</span>
        </div>
        <RichText text={message?.text?.json} className={styles.message} />

        <SiteLink
          locale={locale}
          className={styles.button}
          href={addLocaleToHref(shopNowLink?.link.slug, locale, router)}
        >
          {shopNowLink?.link.title}
        </SiteLink>
      </div>
    </div>
  );
};

export default KickbackfeePopup;
