import { reformatContentfulLocaleToLocale } from 'lib/utils/helpers';
import Head from 'next/head';
import getSchema from 'lib/utils/getSchema';

const siteName = process.env.NEXT_PUBLIC_SITENAME;
const siteUrl = process.env.NEXT_PUBLIC_SITEURL;

/** Remove /en from canonical URL, since that is better for SEO purposes */
const formatUrl = (locale, seoSlug) => {
  const realLocale = locale === 'en' ? null : locale;

  if (realLocale === null) return `${siteUrl}/${`${seoSlug}` === '/' ? '' : `${seoSlug}/`}`;

  return `${siteUrl}/${realLocale}/${`${seoSlug}` === '/' ? '' : `${seoSlug}/`}`;
};

const SEO = ({ seo, pageData }) => {
  const title = seo ? seo?.title : siteName;
  const description = seo ? seo.description : '';
  const keywords = seo && seo?.keywords?.length >= 1 ? seo?.keywords?.map(word => word) : '';
  const ogImage = seo ? seo?.ogImage : '';
  const realLocale = reformatContentfulLocaleToLocale(seo?.locale) || '';
  const url = seo?.slug ? formatUrl(realLocale, seo.slug) : '';
  const robotsContent =
    (seo?.robots?.includes('noindex') ||
      seo?.robots?.includes('nofollow') ||
      seo?.robots?.includes('noindex,nofollow')) &&
    seo.robots;

  const decideHref = (hrefLocale, slug) => {
    const isXDefault = hrefLocale === 'en';
    if (isXDefault) return `${siteUrl}/${slug === '/' ? '' : `${slug}/`}`;

    // replace back to 'na' because there is no 'us' locale
    return `${siteUrl}/${hrefLocale?.replace('us', 'na')}/${slug === '/' ? '' : `${slug}/`}`;
  };

  const decideHrefLang = hrefLangLocale => {
    if (hrefLangLocale === 'en') return 'x-default';
    if (hrefLangLocale === 'fr') return 'fr-fr';
    if (hrefLangLocale === 'fr-fr') return 'fr';
    if (hrefLangLocale === 'na') return 'en-ca';
    if (hrefLangLocale === 'us') return 'en-us';
    if (hrefLangLocale === 'ba') return 'hr-ba';
    if (hrefLangLocale === 'si') return 'sl';
    if (hrefLangLocale === 'uk') return 'en-gb';
    if (hrefLangLocale === 'ie') return 'en-ie';

    return hrefLangLocale;
  };

  const naHref = seo?.href?.find(obj => obj?.locale === 'na');

  // adding us to have en-us
  const seoHrefs = [
    ...seo?.href,
    { slug: naHref?.slug, locale: naHref?.locale.replace('na', 'us') },
  ].filter(Boolean);

  const schema = getSchema(pageData);

  return (
    <Head>
      <title>{title}</title>
      <meta httpEquiv="Accept-CH" content="DPR, Width, Viewport-Width" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="description" content={description} />
      <meta name="image" content={ogImage} />
      <meta name="keywords" content={keywords} />
      <meta name="twitter:site" content={siteName} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image:src" content={ogImage} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={url} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content={seo ? realLocale : ''} />$
      {url && <link rel="canonical" href={url} />}
      {robotsContent && <meta name="robots" content={robotsContent} />}
      {seoHrefs &&
        seoHrefs?.length >= 0 &&
        seoHrefs.map(({ locale: hrefLocale, slug }) => (
          <link
            key={hrefLocale}
            rel="alternate"
            hrefLang={decideHrefLang(hrefLocale)}
            href={decideHref(hrefLocale, slug)}
          />
        ))}
      {schema && (
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema),
          }}
        />
      )}
    </Head>
  );
};

export default SEO;
