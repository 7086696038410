const LockIcon = ({ width = 20, height = 20, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6663 7.33325H3.33301V13.9999H12.6663V7.33325Z"
      stroke={color || 'white'}
      strokeLinecap="square"
    />
    <path
      d="M8.00033 2C9.84127 2 11.3337 3.49238 11.3337 5.33333V7.33333H4.66699V5.33333C4.66699 3.49238 6.15938 2 8.00033 2Z"
      stroke={color || 'white'}
      strokeLinecap="square"
    />
  </svg>
);

export default LockIcon;
