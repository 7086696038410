import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import SiteLink from 'components/shared/sitelink';
import {
  checkIsWindowsOS,
  countryCodeEmoji,
  getAllowedParams,
  serializeURIParams,
} from 'lib/utils/helpers';
import { keys, set } from 'lib/utils/localStorage';
import { useCustomer, useLogoutCustomer } from 'hooks';
import { useSettings } from 'contexts';
import styles from './localeSelector.module.scss';

const LocaleSelector = ({ locale, allLocales, pageData }) => {
  const router = useRouter();
  const { countryCode, setCountryCode } = useSettings();
  const { customer } = useCustomer();
  const [logoutCustomer] = useLogoutCustomer(locale);
  // todo countryCode will be Contentful sys.id

  const [selectedLocale, setSelectedLocale] = useState(
    countryCode.toLowerCase() === locale ? countryCode : locale
  );
  const [countrySelectorIsOpen, setCountrySelectorIsOpen] = useState(false);
  const [isWindows, setIsWindows] = useState('');

  useEffect(() => {
    setIsWindows(checkIsWindowsOS());
  }, []);

  // todo change countryCode and selectedLocale to use sys.id
  useEffect(() => {
    if (countryCode !== selectedLocale) {
      setSelectedLocale(countryCode.toLowerCase() === locale ? countryCode : locale);
    }
  }, [countryCode, locale]);

  if (!allLocales) return null;

  // Utility functions.
  const getLocaleFromSlug = slug => slug?.replace(/\//g, '')?.toLowerCase();
  const getCountryName = () => {
    // if el.slug === na set the default to US because both Canada and US have the same slug
    const currentLocale = allLocales.find(el => {
      // if selected in locale selector
      if (selectedLocale === 'CA') {
        return el.countryCode === selectedLocale;
      }
      if (selectedLocale === 'BE' && locale === 'fr-be') {
        return el.slug === '/fr-be/';
      }
      if (selectedLocale === 'BE' && locale === 'nl-be') {
        return el.slug === '/nl-be/';
      }
      // if was set from locale
      if (selectedLocale === 'na') {
        return el.countryCode === 'US';
      }
      if (selectedLocale === 'de') {
        return el.countryCode === 'DE';
      }

      return getLocaleFromSlug(el?.slug) === selectedLocale || el.countryCode === selectedLocale;
    })?.name;

    return currentLocale || '';
  };

  const isCountryAndLanguageLocale = cc => cc?.length >= 2 && cc.length <= 6;
  const getCountryFromLocale = cc => cc?.slice(-2);
  const getEmoji = cc =>
    isCountryAndLanguageLocale(cc) &&
    countryCodeEmoji(cc === 'fr-fr' ? 'tn' : getCountryFromLocale(cc)); // changing fr-fr to tn(Tunisia)

  const generateHref = slug => {
    if (!pageData) {
      return slug === '/' ? slug : `/${getLocaleFromSlug(slug)}/`;
    }

    let queryParams = '';

    if (Object.keys(router.query).length) {
      const filteredParams = Object.keys(router.query)
        .filter(key => getAllowedParams().includes(key.toLowerCase()))
        .reduce((obj, key) => {
          // eslint-disable-next-line no-param-reassign
          obj[key] = router.query[key];
          return obj;
        }, {});

      const params = new URLSearchParams(serializeURIParams(filteredParams));
      queryParams = params.toString() !== '' ? `?${params.toString()}` : '';
    }

    // pageHrefObject contains of locale and slug
    const [pageHrefObject] = pageData.href.filter(
      ({ locale: hrefLocale }) => hrefLocale === getLocaleFromSlug(slug === '/' ? 'en' : slug)
    );
    // locale from pageSlug
    const finalLocale = pageHrefObject?.locale === 'en' ? '' : `/${pageHrefObject?.locale}`;
    const generateFinalSlug = () => {
      if (pageHrefObject?.slug) {
        return pageHrefObject?.slug?.startsWith('/')
          ? pageHrefObject.slug
          : `/${pageHrefObject.slug}`;
      }
      return '';
    };
    // add the page if it is in router.query
    const preservedPage = router.query?.page ? `/page/${router.query?.page}` : '';

    return `${finalLocale}${generateFinalSlug()}${preservedPage}${queryParams}`;
  };

  const handleLanguageChange = (e, slug, cc) => {
    setCountrySelectorIsOpen(false);

    // non included locales have '/' slug. to stay on the international version hardcoding en
    const localeFromSlug = getLocaleFromSlug(slug === '/' ? 'en' : slug);
    setSelectedLocale(cc);
    setCountryCode(cc);
    if (selectedLocale !== localeFromSlug) {
      router
        .push(
          {
            pathname: generateHref(localeFromSlug),
          },
          null,
          {
            locale: localeFromSlug,
          }
        )
        .then(() => {
          set(keys.USER_LOCALE_PREFERENCE, `${localeFromSlug}`);
          // logout customer because nl and fr are different stores
          if (customer) {
            logoutCustomer(customer?.customerAccessToken?.accessToken);
          }
        });
    }
  };

  return (
    <nav className={styles.countrySelector} role="navigation" aria-label="Locale navigation">
      <div className={styles.selected}>
        <button type="button" onClick={() => setCountrySelectorIsOpen(true)}>
          <span
            role="img"
            aria-label={getCountryName(selectedLocale)?.title}
            className={styles.flag}
          >
            {getEmoji(selectedLocale)}
          </span>
          <span className={styles.label}>{getCountryName(selectedLocale) || ''}</span>
        </button>
      </div>
      <div
        className={cx(styles.dropdownBackdrop, { [styles.visible]: countrySelectorIsOpen })}
        onClick={() => setCountrySelectorIsOpen(false)}
        role="none"
      />
      <div className={cx(styles.dropdown, { [styles.visible]: countrySelectorIsOpen })}>
        {allLocales.map(({ externalLink, name, slug, countryCode: cc, sys }) => {
          if (externalLink === null) {
            return (
              // eslint-disable-next-line
              <div
                key={sys?.id}
                className={styles.localeContainer}
                onClick={e => handleLanguageChange(e, slug, cc)}
              >
                {!isWindows && (
                  <span role="img" aria-label={name} className={styles.flag}>
                    {getEmoji(cc)}
                  </span>
                )}
                {name}
              </div>
            );
          }
          return (
            // eslint-disable-next-line
            <div
              key={sys?.id}
              className={styles.localeContainer}
              onClick={() => setCountrySelectorIsOpen(false)}
            >
              <SiteLink key={sys?.id} external={`${externalLink}`}>
                {!isWindows && (
                  <span role="img" aria-label={name} className={styles.flag}>
                    {getEmoji(cc)}
                  </span>
                )}
                {name}
              </SiteLink>
            </div>
          );
        })}
      </div>
    </nav>
  );
};

export default LocaleSelector;
