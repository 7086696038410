import { getPageType, getPage, getGlobal } from 'lib/contentful';
import SEO from 'components/shared/SEO';
import PageGeneral from 'components/pages/general';
import Layout from 'components/layout';
import { reformatLocaleToContentfulLocale } from 'lib/utils/helpers';
import CountrySelectorModal from '../components/pages/countrySelector';
import { useSalons } from '../hooks';

// eslint-disable-next-line arrow-body-style
const Home = ({ page, locale, previewData, globalModules }) => {
  useSalons(locale);

  return (
    <Layout locale={locale} previewData={previewData} globalModules={globalModules} pageData={page}>
      <>
        {page?.seo && <SEO seo={page.seo} pageData={page} />}
        <PageGeneral locale={locale} data={page} resources={globalModules} />
        <CountrySelectorModal locale={locale} globalModules={globalModules} pageData={page} />
      </>
    </Layout>
  );
};

export async function getStaticProps({ locale, preview = false, previewData = null }) {
  const reformattedLocale = reformatLocaleToContentfulLocale(locale);

  const type = await getPageType('/', reformattedLocale);
  const page = await getPage(type, '/', reformattedLocale, preview);

  if (!page) {
    return {
      notFound: true,
    };
  }

  page.locale = locale;
  if (page.seo) {
    page.seo.locale = locale;
  }

  /**
   * We are retrieving all salon data from Firebase, but since we only need
   * the salon number, parent email and customer email (for tracking purposes), we map the salons
   * into a new array consisting only of these 3 properties, which increased performance MASSIVELY.
   */

  const globalModules = await getGlobal(
    [
      'productpage',
      'secondpartproductpage',
      'collection',
      'cookies',
      'announcement-bar',
      'countryselector',
      'firstcountryselectorlocales',
      'kickbackfee-popup',
    ],
    reformattedLocale
  );

  return {
    props: { page, locale, previewData, globalModules },
  };
}

export default Home;
