import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import {
  checkIsWindowsOS,
  countryCodeEmoji,
  getAllowedParams,
  reformatCountryCodeToCountryName,
  serializeURIParams,
} from 'lib/utils/helpers';
import { keys, set, get } from 'lib/utils/localStorage';
import { useSettings } from 'contexts';
import styles from './country-selector.module.scss';
import SiteLink from '../../shared/sitelink';
import ChevronUp from '../../shared/chevron-up';
import ChevronDown from '../../shared/chevron-down';

const CountrySelectorModal = ({ globalModules, pageData, locale }) => {
  const extractedCountries = Object.values(globalModules?.firstcountryselectorlocales);
  const allLocales = extractedCountries.map(country => country.countryList).flat();
  const [userCountry, setUserCountry] = useState({});
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [title, setTitle] = useState(globalModules?.countryselector?.chooseAnotherLocation?.text);
  const [countries] = useState(allLocales);
  const [showModal, setShowModal] = useState(false);
  const [isWindows, setIsWindows] = useState('');
  const router = useRouter();
  const { countryCode, setCountryCode } = useSettings();

  useEffect(() => {
    setIsWindows(checkIsWindowsOS());
  }, []);

  useEffect(() => {
    // qr-code scenario from pages/_middleware.js
    if (router.query.from) {
      set(keys.USER_LOCALE_PREFERENCE, 'en');
      setShowModal(false);
    }

    const timer = setTimeout(() => {
      const isModalOpen = // if user selected international - do not show, else not selected and locale is en - show
        get(keys.USER_LOCALE_PREFERENCE)?.toLocaleLowerCase().includes('en') && locale === 'en'
          ? false
          : !get(keys.USER_LOCALE_PREFERENCE)?.includes('en') && locale === 'en';
      setShowModal(isModalOpen);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [router.query.from]);

  useEffect(() => {
    document.body.style.overflow = showModal ? 'hidden' : 'auto';

    if (showModal) {
      const setLanguage = country => {
        setUserCountry(country);
        setTitle(country.name);
        setCountryCode(country.countryCode.toLowerCase());
      };

      const setDefaultEn = () => {
        const internationalEnglishCountryObj = countries.find(
          country => country.name.includes('International') && country.name.includes('English')
        );
        setLanguage(internationalEnglishCountryObj);
      };

      fetch('/api/user-location')
        .then(res => res.json())
        .then(location => {
          const countryMatch = countries?.find(
            c => c.countryCode.toLowerCase() === location.countryCode.toLowerCase()
          );
          if (countryMatch && countryMatch?.name) {
            setLanguage(countryMatch);
          } else {
            setDefaultEn();
          }
        })
        .catch(() => {
          setDefaultEn();
        });
    }
  }, [showModal]);

  const generateHref = slug => {
    if (!pageData) {
      return `${slug}/`;
    }

    let paramsStr = '';

    if (Object.keys(router.query).length) {
      const filteredParams = Object.keys(router.query)
        .filter(key => getAllowedParams().includes(key.toLowerCase()))
        .reduce((obj, key) => {
          // eslint-disable-next-line no-param-reassign
          obj[key] = router.query[key];
          return obj;
        }, {});

      const params = new URLSearchParams(serializeURIParams(filteredParams));
      paramsStr = params.toString() !== '' ? `?${params.toString()}` : '';
    }

    const [pageSlug] = pageData.href.filter(
      ({ locale: hrefLocale }) => hrefLocale === slug?.replace(/\//g, '')?.toLowerCase()
    );

    // handle not activated locale. For instance in Contentful if you leave the slug empty it will return a 404
    if (!pageSlug) {
      // go to international version
      return `/${router.query.locale ? router.query.locale?.toLowerCase() : ''}${paramsStr}`;
    }

    if (pageSlug?.locale === 'en') {
      return `/${pageSlug?.slug}${paramsStr}`;
    }

    return `/${pageSlug?.locale}${pageSlug?.slug?.startsWith('/') ? '' : '/'}${
      pageSlug?.slug
    }${paramsStr}`;
  };
  const getCountryFromCountryCode = cc => cc?.slice(-2);
  const countryName = reformatCountryCodeToCountryName(countryCode.toLowerCase());

  if (showModal) {
    return (
      <div className={styles.ccSelectorModal}>
        <div className={styles.ccSelectorContainer}>
          <h2 className={styles.updateContent}>
            {globalModules?.countryselector?.updateContent?.text}
          </h2>
          <div className={styles.countryDetection}>
            {`${globalModules?.countryselector?.countryDetection?.text} `}
            <span>{countryName?.length > 2 ? countryName : userCountry?.name}</span>
            {globalModules?.countryselector?.chooseLocation?.text}
          </div>
          <div className={styles.controlsContainer}>
            <div
              className={styles.confirmationButtonContent}
              onClick={() => setActiveDropdown(!activeDropdown)}
              role="none"
            >
              <button type="button" className={cn(styles.button, styles.resetMargin)}>
                <>
                  {!isWindows &&
                    title !== globalModules?.countryselector?.chooseAnotherLocation?.text && (
                      <span className={styles.countryCode}>
                        {countryCodeEmoji(
                          getCountryFromCountryCode(userCountry?.countryCode || countryCode)
                        )}{' '}
                      </span>
                    )}
                  <span>{title}</span>
                </>
              </button>
              <div className={styles.chevronContainer}>
                {activeDropdown ? (
                  <ChevronUp width="24px" height="24px" />
                ) : (
                  <ChevronDown width="24px" height="24px" />
                )}
              </div>
              {activeDropdown && (
                <ul className={styles.locationsContainer}>
                  {countries.map(country => {
                    if (country?.externalLink === null) {
                      return (
                        <SiteLink href={generateHref(country?.slug)} key={country?.sys.id}>
                          <li
                            role="none"
                            className={styles.countrySelector}
                            value={country?.countryCode}
                            onClick={e => {
                              // if a country has no locale in Contentful
                              if (country.slug === '/') {
                                // compare with country slug
                                e.preventDefault();
                                set(keys.USER_LOCALE_PREFERENCE, 'en');
                              } else {
                                set(keys.USER_LOCALE_PREFERENCE, country.countryCode);
                              }
                              // todo setCountryCode(sys.id)
                              setCountryCode(country.countryCode);
                              setTitle(country?.name);
                              setActiveDropdown(!activeDropdown);
                              setShowModal(false);
                            }}
                          >
                            {!isWindows && (
                              <span className={styles.countryCode}>
                                {countryCodeEmoji(getCountryFromCountryCode(country?.countryCode))}
                              </span>
                            )}{' '}
                            <span>{country?.name}</span>
                          </li>
                        </SiteLink>
                      );
                    }

                    return (
                      <SiteLink external={`${country?.externalLink}`} key={country?.sys.id}>
                        <li
                          role="none"
                          className={styles.countrySelector}
                          value={country?.countryCode}
                          onClick={() => {
                            setTitle(country?.name);
                            setActiveDropdown(!activeDropdown);
                            setUserCountry(country);
                          }}
                        >
                          {!isWindows && (
                            <span className={styles.countryCode}>
                              {countryCodeEmoji(getCountryFromCountryCode(country?.countryCode))}{' '}
                            </span>
                          )}
                          <span className={styles.localeTitle}>{country?.name}</span>
                        </li>
                      </SiteLink>
                    );
                  })}
                </ul>
              )}
            </div>
            {userCountry && userCountry?.slug === null ? (
              <SiteLink
                external={`${userCountry?.externalLink}`}
                // onClick={() => set(keys.USER_LOCALE_PREFERENCE, userCountry.externalLink)}
              >
                <div className={styles.confirmContent}>
                  <span>{globalModules?.countryselector?.confirmContent?.text}</span>
                </div>
              </SiteLink>
            ) : (
              <SiteLink
                href={generateHref(userCountry.slug)}
                onClick={() => {
                  set(keys.USER_LOCALE_PREFERENCE, countryCode);
                  setShowModal(false);
                  // todo setCountryCode(sys.id)
                  // todo probably not needed here?
                  setCountryCode(countryCode);
                }}
              >
                <div className={styles.confirmContent}>
                  <span>{globalModules?.countryselector?.confirmContent?.text}</span>
                </div>
              </SiteLink>
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default CountrySelectorModal;
