const Icon = ({ type, className }) => {
  switch (type) {
    case 'back':
      return (
        <svg
          className={className}
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 13L0.999997 7L7 0.999999"
            stroke="#3C3C3B"
            strokeWidth="1.2"
            strokeLinecap="square"
          />
        </svg>
      );
    case 'dropdown':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
        </svg>
      );
    case 'hamburger':
      return (
        <svg
          className={className}
          viewBox="0 0 18 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1H17" stroke="#3C3C3B" strokeWidth="1.2" strokeLinecap="square" />
          <path d="M1 6H17" stroke="#3C3C3B" strokeWidth="1.2" strokeLinecap="square" />
          <path d="M1 11H17" stroke="#3C3C3B" strokeWidth="1.2" strokeLinecap="square" />
        </svg>
      );
    case 'close':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
          <path d="M4.707 3.293L3.293 4.707 10.586 12l-7.293 7.293 1.414 1.414L12 13.414l7.293 7.293 1.414-1.414L13.414 12l7.293-7.293-1.414-1.414L12 10.586 4.707 3.293z" />
        </svg>
      );
    case 'search':
      return (
        <svg
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            d="M11.4121 11.4121L17 17"
            stroke="#3C3C3B"
            strokeWidth="1.2"
            strokeLinecap="square"
          />
          <path
            d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
            stroke="#3C3C3B"
            strokeWidth="1.2"
            strokeLinecap="square"
          />
        </svg>
      );
    case 'account':
      return (
        <svg
          className={className}
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 17C1 14 5 14 7 12C8 11 5 11 5 6C5 2.667 6.333 1 9 1C11.667 1 13 2.667 13 6C13 11 10 11 11 12C13 14 17 14 17 17"
            stroke="#3C3C3B"
            strokeWidth="1.2"
            strokeLinecap="square"
          />
        </svg>
      );
    case 'cart':
      return (
        <svg
          className={className}
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M15 5H1V17H15V5Z" stroke="#3C3C3B" strokeWidth="1.2" strokeLinecap="square" />
          <path
            d="M4 5C4 3.93913 4.42143 2.92172 5.17157 2.17157C5.92172 1.42143 6.93913 1 8 1C9.06086 1 10.0783 1.42143 10.8284 2.17157C11.5786 2.92172 12 3.93913 12 5"
            stroke="#3C3C3B"
            strokeWidth="1.2"
            strokeLinecap="square"
          />
        </svg>
      );
    case 'info':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g clipPath="url(#clip0)">
            <path
              d="M10 12.5002C10 10.8335 11.3133 10.5546 11.7656 10.1035C12.2193 9.65087 12.5 9.02497 12.5 8.3335C12.5 6.95278 11.3807 5.8335 10 5.8335C9.25341 5.8335 8.58326 6.16077 8.12517 6.67968C7.91335 6.91962 7.74688 7.20053 7.63916 7.50901"
              stroke="#3C3C3B"
              strokeOpacity="0.5"
              strokeLinecap="square"
            />
            <path
              d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z"
              stroke="#3C3C3B"
              strokeOpacity="0.5"
              strokeLinecap="square"
            />
            <rect x="9.5" y="14" width="1" height="1" fill="#3C3C3B" fillOpacity="0.5" />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'warning':
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="45.818px"
          height="45.818px"
          viewBox="0 0 45.818 45.818"
          className={className}
        >
          <g>
            <path
              d="M22.909,0C10.258,0,0,10.257,0,22.908c0,12.652,10.258,22.91,22.909,22.91s22.909-10.258,22.909-22.91
		C45.818,10.257,35.561,0,22.909,0z M26.411,35.417c0,1.921-1.573,3.478-3.492,3.478c-1.92,0-3.492-1.557-3.492-3.478V20.201
		c0-1.92,1.572-3.477,3.492-3.477c1.919,0,3.492,1.556,3.492,3.477V35.417z M22.909,13.851c-2.119,0-3.837-1.718-3.837-3.836
		c0-2.12,1.718-3.836,3.837-3.836c2.118,0,3.837,1.716,3.837,3.836C26.746,12.133,25.027,13.851,22.909,13.851z"
            />
          </g>
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
        </svg>
      );
    case 'loading':
      return (
        <svg
          className={className}
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="24px"
          height="30px"
          viewBox="0 0 24 30"
          xmlSpace="preserve"
        >
          <rect x="0" y="0" width="4" height="10" fill="currentColor">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="translate"
              values="0 0; 0 20; 0 0"
              begin="0"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="10" y="0" width="4" height="10" fill="currentColor">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="translate"
              values="0 0; 0 20; 0 0"
              begin="0.2s"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="20" y="0" width="4" height="10" fill="currentColor">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="translate"
              values="0 0; 0 20; 0 0"
              begin="0.4s"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </rect>
        </svg>
      );
    default:
      return null;
  }
};

export default Icon;
