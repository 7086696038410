import Button from 'components/shared/button/button';
import Image from 'components/shared/image/image';
import RichText from 'components/shared/richtext';
import useIsMobile from 'hooks/utils/useIsMobile';
import VideoPlayer from 'components/shared/video/video';
import styles from './sideImageCta.module.scss';

const SideImageCta = ({ data, locale }) => {
  const { title, body, media, button, style, backgroundColor } = data;
  const isMobile = useIsMobile();

  const reverse = style === 'Image on the right side';

  const flexDirection = () => {
    if (isMobile) return 'column';
    if (reverse) return 'row-reverse';
    return 'row';
  };

  const mediaType = media.type;
  const source = mediaType === 'Image' ? media.image : media.video;

  return (
    <section id="sectionSideImageCta">
      <div
        className={styles.ctaWrapper}
        style={{
          backgroundColor: backgroundColor?.color || null,
          flexDirection: flexDirection(),
        }}
      >
        {isMobile && <h2 className={styles.ctaTitle}>{title}</h2>}

        <div className={styles.mediaContainer}>
          {mediaType === 'Image' && (
            <Image
              className={styles.media}
              src={source}
              loading="lazy"
              sizes="(max-width: 899px) 100vw, 50vw"
            />
          )}
          {mediaType === 'Video' && source && (
            <VideoPlayer
              className={styles.media}
              thumbnail={media.thumbnail}
              source={source}
              autoPlay
              aspectRatio={media.aspectRatio}
            />
          )}
        </div>

        <div className={styles.textDiv}>
          {!isMobile && <h2 className={styles.ctaTitle}>{title}</h2>}
          <RichText text={body?.json} />
          <Button className={styles.ctaButton} locale={locale} button={button} />
        </div>
      </div>
    </section>
  );
};

export default SideImageCta;
