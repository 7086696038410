export const FILTER_ACTION_TYPES = {
  SELECTED_FILTER: {
    REMOVE: 'remove_selected_filter',
    ADD: 'add_selected_filter',
    ADD_MULTIPLE: 'add_selected_filters',
    SET_FROM_DESIRED: 'set_selected_filters_from_desired_filters',
    SET: 'set',
    CLEAR: 'clear_selected_filters',
  },
  DESIRED_FILTER: {
    REMOVE: 'remove_desired_filter',
    ADD: 'add_desired_filter',
  },
  OPENED_DROPDOWN: {
    SET: 'set_opened_dropdown',
    CLEAR: 'clear_opened_dropdown',
  },
  FILTERED_PRODUCTS: {
    SET: 'set_filtered_products',
  },
  RESET_STATE: 'reset_state',
};

const filterReducer = (filterState, action) => {
  let newState = { ...filterState };

  switch (action.type) {
    case FILTER_ACTION_TYPES.SELECTED_FILTER.ADD:
      if (
        newState.selectedFilters.find(filter => filter.value === action.value.value) === undefined
      )
        newState.selectedFilters.push(action.value);
      break;

    // First remove filter from selected filters, then remove the filter from desired filters.
    case FILTER_ACTION_TYPES.SELECTED_FILTER.REMOVE:
      // eslint-disable-next-line no-case-declarations
      const newSelectedFilters = [...newState.selectedFilters];
      newState.selectedFilters = newSelectedFilters.filter(
        filter => filter.id !== action.value.id || filter.value !== action.value.value
      );

      // eslint-disable-next-line no-case-declarations
      const newUpdatedDesiredFilters = [...newState.desiredFilters];
      newState.desiredFilters = newUpdatedDesiredFilters.filter(
        filter => filter.id !== action.value.id || filter.value !== action.value.value
      );
      break;

    case FILTER_ACTION_TYPES.SELECTED_FILTER.ADD_MULTIPLE:
      // eslint-disable-next-line no-case-declarations
      newState.selectedFilters = [...action.value];
      newState.desiredFilters = [...action.value];
      newState.openedDropdown = null;
      break;

    case FILTER_ACTION_TYPES.SELECTED_FILTER.SET_FROM_DESIRED:
      newState.selectedFilters = newState.desiredFilters;
      break;

    case FILTER_ACTION_TYPES.SELECTED_FILTER.CLEAR:
      newState.selectedFilters = [];
      newState.desiredFilters = [];
      break;

    case FILTER_ACTION_TYPES.SELECTED_FILTER.SET:
      // eslint-disable-next-line no-case-declarations
      const filtersCopy = [...action.value];
      newState.selectedFilters = filtersCopy;
      newState.desiredFilters = filtersCopy;
      break;

    case FILTER_ACTION_TYPES.DESIRED_FILTER.ADD:
      newState.desiredFilters.push(action.value);
      break;

    case FILTER_ACTION_TYPES.DESIRED_FILTER.REMOVE:
      // eslint-disable-next-line no-case-declarations
      const newDesiredFilters = [...newState.desiredFilters];
      newDesiredFilters.splice(action.index, 1);
      newState.desiredFilters = newDesiredFilters;
      break;

    case FILTER_ACTION_TYPES.OPENED_DROPDOWN.SET:
      newState.openedDropdown = action.value;
      break;

    case FILTER_ACTION_TYPES.OPENED_DROPDOWN.CLEAR:
      newState.openedDropdown = null;
      break;

    case FILTER_ACTION_TYPES.FILTERED_PRODUCTS.SET:
      newState.filteredProducts = action.value;
      break;

    case FILTER_ACTION_TYPES.RESET_STATE:
      // eslint-disable-next-line no-case-declarations
      const newStateCopy = { ...action.value };
      newState = newStateCopy;
      break;

    default:
      throw new Error(
        'The action type specified in the action is not an existing one. Did you type it in correctly?'
      );
  }

  return newState;
};

export default filterReducer;
