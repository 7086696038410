import { getStoreFrontApiCredentials, preparePayload } from 'lib/shopify';
import { PRODUCT_DATA_QUERY } from './fragments';

const parseProduct = product => {
  const salePrice = product?.salePrice?.value ? JSON.parse(product?.salePrice?.value).amount : null;
  const price = product?.variants.edges[0].node.price?.amount;

  const finalPrice = salePrice || price;
  const finalCompareAtPrice = salePrice
    ? price
    : product?.variants.edges[0].node.compareAtPrice?.amount;

  return {
    productId: product?.id,
    productSKU: product?.variants.edges[0].node.sku,
    productTitle: product?.title,
    productHandle: product?.handle,
    productType: product?.productType,
    productVendor: product?.productVendor?.value || '',
    productPrice: finalPrice ? parseFloat(finalPrice).toFixed(2) : null,
    currencyCode: product?.variants.edges[0].node.price?.currencyCode,
    productCompareAtPrice: finalCompareAtPrice ? parseFloat(finalCompareAtPrice).toFixed(2) : null,
    outOfStock: product?.tags?.includes('out-of-stock'),
    variantId: product?.variants.edges[0].node.id,
    relatedProducts: product?.relatedProducts?.value && JSON.parse(product?.relatedProducts?.value),
    isFreeItem: product?.tags?.includes('free-item'),
    unitPrice: {
      amount: product?.variants.edges[0].node.unitPrice?.amount || '',
      currencyCode: product?.variants.edges[0].node.unitPrice?.currencyCode || '',
      referenceValue: product?.variants.edges[0].node.unitPriceMeasurement?.referenceValue || '',
      referenceUnit: product?.variants.edges[0].node.unitPriceMeasurement?.referenceUnit || '',
    },
  };
};

const getShopifyData = async ({
  productIds,
  productType = 'PageProduct',
  itemsInBundle = 2,
  locale,
}) => {
  try {
    const getAllShopifyData = async () => {
      const payload = preparePayload(PRODUCT_DATA_QUERY, {
        ids: productIds.flat(),
      });

      const { storeDomain, shopifyApiVersion, storeFrontAccessToken } =
        getStoreFrontApiCredentials(locale);

      const shopifyData = await fetch(`https://${storeDomain}/api/${shopifyApiVersion}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Shopify-Storefront-Access-Token': storeFrontAccessToken,
        },
        body: JSON.stringify(payload),
      }).then(response => response.json());

      return shopifyData;
    };

    const productData = await getAllShopifyData();

    const allProducts = productData.data.nodes;
    // If product type is PageProduct, parse products as single objects
    if (productType === 'PageProduct') {
      const parsedProducts = allProducts.map(product => parseProduct(product));

      return parsedProducts;
    }
    // Otherwise if the type of product is a product bundle, handle them as nested array objects
    const parsedProductBundles = [];

    for (let i = 0; i < allProducts.length; i += itemsInBundle) {
      const productBundle = [];
      for (let j = 0; j < itemsInBundle; j += 1) {
        productBundle.push(parseProduct(allProducts[i + j]));
      }
      parsedProductBundles.push(productBundle);
    }

    return parsedProductBundles;
  } catch (error) {
    return error;
  }
};

export default getShopifyData;
