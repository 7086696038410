import filterReducer from 'hooks/filterGlobalState/filterReducer';
import { useReducer, createContext } from 'react';

// Set up initial filter state
const initialFilterState = {
  selectedFilters: [],
  desiredFilters: [],
  filteredProducts: [],
  openedDropdown: null,
};

// Create a context to update selected filters from within the FilterSelect components
export const FilterContext = createContext({
  globalFilterState: initialFilterState,
  updateGlobalFilterState: () => null,
});

const useFilterGlobalState = products => {
  const [filterGlobalState, setFilterGlobalState] = useReducer(filterReducer, {
    ...initialFilterState,
    filteredProducts: products,
  });

  return [filterGlobalState, action => setFilterGlobalState(action)];
};

/**
 * Wraps the child elements inside of a global state provider
 * @param {*} children The child elements that need to be wrapped
 * @returns A component that's used to wrap elements in a global state provider
 */
export const GlobalFilterProvider = ({ products, children }) => {
  const [filterState, updateFilterState] = useFilterGlobalState(products);

  return (
    <FilterContext.Provider value={[filterState, updateFilterState]}>
      {children}
    </FilterContext.Provider>
  );
};

export default useFilterGlobalState;
