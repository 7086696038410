import { get, keys, set } from 'lib/utils/localStorage';
import { useMemo, useReducer, createContext, useContext } from 'react';

// Salons has been temporarily added to UI context because of time constraints. Needs to be refactored to external context
const initialState = {
  displayMenu: false,
  displayDrawer: false,
  displayCookiebar: Boolean(get(keys.COOKIES_ACCEPTED)) !== true,
  displayCartPopup: false,
  displaySalonSelector: false,
  displayConfirmWishlistPopup: false,
  confirmWishlistData: null,
};

export const UIContext = createContext(initialState);

function uiReducer(state, action) {
  switch (action.type) {
    case 'OPEN_MENU': {
      return {
        ...state,
        displayMenu: true,
      };
    }
    case 'CLOSE_MENU': {
      return {
        ...state,
        displayMenu: false,
      };
    }
    case 'OPEN_DRAWER': {
      return {
        ...state,
        displayDrawer: true,
      };
    }
    case 'CLOSE_DRAWER': {
      return {
        ...state,
        displayDrawer: false,
      };
    }
    case 'ACCEPT_COOKIES': {
      set(keys.COOKIES_ACCEPTED, true);
      return {
        ...state,
        displayCookiebar: false,
      };
    }
    case 'OPEN_CART_POPUP': {
      return {
        ...state,
        displayCartPopup: true,
      };
    }
    case 'CLOSE_CART_POPUP': {
      return {
        ...state,
        displayCartPopup: false,
      };
    }
    case 'OPEN_SALON_SELECTOR': {
      return {
        ...state,
        displaySalonSelector: true,
      };
    }
    case 'CLOSE_SALON_SELECTOR': {
      return {
        ...state,
        displaySalonSelector: false,
      };
    }
    case 'OPEN_CONFIRM_WISHLIST_POPUP': {
      return {
        ...state,
        displayConfirmWishlistPopup: true,
        confirmWishlistData: action.payload,
      };
    }
    case 'CLOSE_CONFIRM_WISHLIST_POPUP': {
      return {
        ...state,
        displayConfirmWishlistPopup: false,
        confirmWishlistData: null,
      };
    }
    default: {
      return console.log('no action');
    }
  }
}

export const UIProvider = props => {
  const [state, dispatch] = useReducer(uiReducer, initialState);

  const openMenu = () => dispatch({ type: 'OPEN_MENU' });
  const closeMenu = () => dispatch({ type: 'CLOSE_MENU' });
  const openDrawer = () => dispatch({ type: 'OPEN_DRAWER' });
  const closeDrawer = () => dispatch({ type: 'CLOSE_DRAWER' });
  const toggleMenu = () =>
    state.displayCart ? dispatch({ type: 'CLOSE_MENU' }) : dispatch({ type: 'OPEN_MENU' });
  const toggleDrawer = () =>
    state.displayDrawer ? dispatch({ type: 'CLOSE_DRAWER' }) : dispatch({ type: 'OPEN_DRAWER' });
  const acceptCookies = () => dispatch({ type: 'ACCEPT_COOKIES' });
  const openCartPopup = () => dispatch({ type: 'OPEN_CART_POPUP' });
  const closeCartPopup = () => dispatch({ type: 'CLOSE_CART_POPUP' });
  const openSalonSelector = () => dispatch({ type: 'OPEN_SALON_SELECTOR' });
  const closeSalonSelector = () => dispatch({ type: 'CLOSE_SALON_SELECTOR' });
  const openConfirmWishlistPopup = payload =>
    dispatch({ type: 'OPEN_CONFIRM_WISHLIST_POPUP', payload });
  const closeConfirmWishlistPopup = () => dispatch({ type: 'CLOSE_CONFIRM_WISHLIST_POPUP' });

  const value = useMemo(
    () => ({
      ...state,
      openMenu,
      closeMenu,
      toggleMenu,
      openDrawer,
      closeDrawer,
      toggleDrawer,
      acceptCookies,
      openCartPopup,
      closeCartPopup,
      openSalonSelector,
      closeSalonSelector,
      openConfirmWishlistPopup,
      closeConfirmWishlistPopup,
    }),
    [state]
  );

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <UIContext.Provider value={value} {...props} />;
};

export const useUI = () => {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};

export const UIContextProvider = ({ children }) => <UIProvider>{children}</UIProvider>;
