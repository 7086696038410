const ChevronUp = ({ width = 16, height = 16 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 9.33325L8 5.33325L12 9.33325" stroke="#3C3C3B" strokeLinecap="square" />
  </svg>
);

export default ChevronUp;
