export const parseShopifyGid = id => {
  if (!id) {
    return null;
  }

  if (!Number.isNaN(parseInt(id, 10))) {
    return id;
  }

  const idArr = id.split('/');
  return idArr[idArr.length - 1];
};

export const getShopifyGid = (type, id) => {
  if (!type || !id) {
    return null;
  }

  if (id.toString().split(':')[0] === 'gid') {
    return id;
  }

  return `gid://shopify/${type}/${id}`;
};
