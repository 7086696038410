import { formatPrice } from 'lib/utils/helpers';
import { formatPriceByStoreLocale } from 'lib/shopify';
import cn from 'classnames';
import styles from './unitPrice.module.scss';

const UnitPrice = ({ unitPrice, locale, className, size, price, globalModules }) => {
  const { amount, referenceUnit, referenceValue } = unitPrice || {};
  const { secondpartsettings } = globalModules || {};

  const getFormattedUnitPrice = () => {
    if (!amount && !price) return '';

    const money = formatPriceByStoreLocale({
      locale,
      price: formatPrice(amount || price),
    });

    const formatted = `${money}/${referenceValue || 1}${
      referenceUnit || ` ${secondpartsettings?.unitPriceStackText?.text}`
    }`;

    if (size) {
      return `${size} (${formatted})`;
    }

    return formatted;
  };

  if (!secondpartsettings?.localesWithUnitPrice?.onOrOff) return null;

  return <span className={cn(styles.unitPrice, className)}>{getFormattedUnitPrice()}</span>;
};

export default UnitPrice;
