import { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import Image from 'components/shared/image/image';
import Play from 'components/shared/play';
import Pause from 'components/shared/pause';
import VolumeControl from 'components/shared/volume-control';
import useIsMobile from 'hooks/utils/useIsMobile';

import styles from './video.module.scss';

const VideoPlayer = ({
  source,
  sourceDesktop,
  thumbnail,
  autoPlay = false,
  className,
  controls = true,
  controlsVersion = 1,
  aspectRatio,
  forceKeepPlaying = false,
}) => {
  const videoRef = useRef();
  const sourceRef = useRef();
  const containerRef = useRef();
  const [isReady, setIsReady] = useState(false);
  const [initialPlay, setInitialPlay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoVolume, setVideoVolume] = useState(0);
  const [videoMuted, setVideoMuted] = useState(true);
  const [showControls, setShowControls] = useState(false);
  const isMobile = useIsMobile();
  const lastIsMobile = useRef(null);
  lastIsMobile.current = isMobile;
  const [videoSource, setVideoSource] = useState(source);

  useEffect(() => {
    if (source?.url && isMobile !== null && isReady) {
      setVideoSource(isMobile ? source : sourceDesktop || source);
      videoRef.current.load();
    }
  }, [isMobile]);

  useEffect(() => {
    if (!controls) return;
    setShowControls(!!videoRef.current);
  }, [videoRef]);

  const toggleMute = () => {
    videoRef.current.muted = !videoRef.current.muted || false;
    if (!videoRef.current.muted && videoVolume === 0) {
      setVideoVolume(0.5);
    }
    setVideoMuted(videoRef.current.muted);
  };

  // On both the below useEffect we check if `initialPlay` is true.
  // We do this because should only change volume/mute state after
  // the video has started playing. Otherwise we will block
  // the autoplay.
  useEffect(() => {
    if (videoRef.current && isReady) {
      videoRef.current.volume = videoVolume;

      // If we are changing volume, but the video is muted
      // We manually unmute the video
      if (videoMuted && videoVolume > 0) {
        toggleMute();
      }
    }
  }, [videoVolume, isReady]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.25,
    };
    const observer = new IntersectionObserver(entries => {
      const [video] = entries;
      if (autoPlay && sourceRef?.current?.src === '' && video.intersectionRatio >= 0.25) {
        sourceRef.current.src = videoSource?.url;
        video.target.load();
        setIsReady(true);
        setIsPlaying(true);
        setInitialPlay(true);
        videoRef.current.muted = true;
        videoRef.current.defaultMuted = true;
      }
    }, observerOptions);
    observer.observe(videoRef.current);
  }, [sourceRef, autoPlay, videoSource?.url]);

  useEffect(() => {
    if (autoPlay) {
      const interval = setInterval(() => {
        if (videoRef.current.readyState >= 3) {
          videoRef.current.play();
          setIsReady(true);
          clearInterval(interval);
          setInitialPlay(true);
          videoRef.current.muted = true;
          videoRef.current.defaultMuted = true;
        }
      }, 200);

      return () => clearInterval(interval);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // function to stop the video on click outside
    if (forceKeepPlaying) return false;
    const handleClickOutside = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        videoRef?.current?.pause();
        setIsPlaying(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, videoRef]);

  const playPauseButtonHandler = video => {
    if (video.current.paused) {
      if (sourceRef.current.src === '') {
        sourceRef.current.src = videoSource.url;
        video.current.load();
        setIsReady(true);
      } else if (video.current.readyState >= 3) {
        setIsReady(true);
      }
      setIsReady(true);
      video.current.play();
      setIsPlaying(true);
    } else {
      video.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div
      className={classNames(styles.playerWrapper, className)}
      ref={containerRef}
      style={{ '--aspectRatio': aspectRatio }}
    >
      <video
        preload="metadata"
        className={styles.video}
        autoPlay={autoPlay}
        disableRemotePlayback
        loop
        playsInline
        muted
        ref={videoRef}
      >
        <track kind="captions" />
        <source type={source?.contentType} ref={sourceRef} src={videoSource?.url} />
      </video>
      {!isReady && (
        <Image
          className={styles.thumbnailImage}
          src={thumbnail}
          layout="fill"
          objectFit="cover"
          alt="poster"
          loading="lazy"
        />
      )}
      {showControls && controlsVersion === 1 && (
        <div
          className={classNames(styles.videoControlsWrapper, {
            [styles.dissapearingControls]: isPlaying,
          })}
        >
          <button
            className={classNames(styles.videoControl)}
            onClick={() => playPauseButtonHandler(videoRef)}
            type="button"
          >
            {isPlaying ? <Pause width="60%" height="60%" /> : <Play width="40%" height="40%" />}
          </button>
          <VolumeControl
            onVolumeChange={newVolume => setVideoVolume(parseFloat(newVolume))}
            toggleMute={() => toggleMute()}
            volume={videoMuted ? 0 : videoVolume}
            classes={styles.videoControl}
          />
        </div>
      )}

      {showControls && controlsVersion === 2 && (
        <>
          <div
            className={classNames(styles.videoControlsWrapperV2, {
              [styles.dissapearingControls]: isPlaying,
            })}
          >
            <button
              className={classNames(styles.playPauseToggle, styles.videoControl)}
              onClick={() => playPauseButtonHandler(videoRef)}
              type="button"
            >
              {isPlaying ? <Pause width="60%" height="60%" /> : <Play width="40%" height="40%" />}
            </button>
            <VolumeControl
              onVolumeChange={newVolume => setVideoVolume(parseFloat(newVolume))}
              toggleMute={() => toggleMute()}
              volume={videoMuted ? 0 : videoVolume}
              classes={[styles.videoControl, styles.volumeControl]}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default VideoPlayer;
