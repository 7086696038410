const CloseContainer = ({ width = '1.4rem', height = '1.4rem' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.34326 1.34277L12.657 12.6565M1.34326 12.6565L12.657 1.34277"
      stroke="#3C3C3B"
      strokeWidth="1.2"
      strokeLinecap="square"
    />
  </svg>
);

export default CloseContainer;
