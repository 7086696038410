import { useState, useEffect } from 'react';
import VolumeIcon from 'components/shared/volume-icon';
import classNames from 'classnames';
import useIsMobile from 'hooks/utils/useIsMobile';
import { Range, getTrackBackground } from 'react-range';
import styles from './volume-control.module.scss';

const STEP = 0.01;
const MIN = 0;
const MAX = 1;

const VolumeControl = ({ volume, onVolumeChange, toggleMute, classes }) => {
  const isMobile = useIsMobile();
  const [values, setValues] = useState([volume]);
  const handleChange = newVolume => {
    setValues(newVolume);
    onVolumeChange(newVolume);
  };

  useEffect(() => {
    setValues([volume]);
  }, [volume]);

  // Only show the volume input slider on desktop devices
  // On mobile, the user can use the device volume buttons
  return (
    <div
      className={classNames(classes, styles.volumeToggle, { [styles.withVolumeSlider]: !isMobile })}
    >
      <div className={styles.volumeSliderWrapper}>
        <button onClick={toggleMute} type="button">
          <VolumeIcon volume={volume} />
        </button>

        <div className={styles.customVolumeSlider}>
          <Range
            values={values}
            step={STEP}
            min={MIN}
            max={MAX}
            onChange={volume => handleChange(volume)}
            renderTrack={({ props, children }) => (
              <div
                role="button"
                tabIndex="-1"
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: '36px',
                  display: 'flex',
                  width: '100%',
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: '5px',
                    width: '100%',
                    borderRadius: '4px',
                    background: getTrackBackground({
                      values,
                      colors: ['#3c3c3b', '#f9f9f9'],
                      min: MIN,
                      max: MAX,
                    }),
                    alignSelf: 'center',
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '20px',
                  width: '20px',
                  borderRadius: '20px',
                  backgroundColor: '#3c3c3b',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  outline: 'none',
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default VolumeControl;
