export default ({ src, quality, width }) => {
  if (src.includes('images.ctfassets.net')) {
    const url = new URL(src);
    url.searchParams.set('fm', 'webp');
    url.searchParams.set('w', width.toString());
    url.searchParams.set('q', quality.toString() || '75');
    return url.href;
  }

  return src;
};
