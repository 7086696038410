import sortSections from 'lib/utils/sortSections';
import {
  minimizeQuerySize,
  delay,
  logContentfulQueryErrors,
  reformatContentfulLocaleToLocale,
  reformatLocaleToContentfulLocale,
} from 'lib/utils/helpers';

import {
  ADVICE_PAGE_LIMIT,
  COMPONENT_BUTTON_QUERY,
  COMPONENT_COUNTRY_SELECTOR_ITEM,
  COMPONENT_MEDIA_QUERY,
  COMPONENT_MENU_QUERY,
  COMPONENT_SUBMENU_QUERY,
  GLOBAL_COMPONENT_LINK_QUERY,
  IMAGE_QUERY_FIELDS,
  KLAVIYO_COMPONENT_FIELDS,
  SECTION_BREADCRUMBS_QUERY,
  SECTION_BUYING_PROS_QUERY,
  SECTION_READ_MORE_ARTICLES_QUERY,
  SEO_QUERY,
  SECTION_TYPEFORM_QUERY,
  PRODUCT_SPEC_QUERY_FIELDS,
} from './fragments';
import { getShopifyDataFieldName } from '../shopify';
import { getShopifyGid } from '../shopify/shopifyGid';
import { parseCollectionProducts } from './parseComponents';

const SPACE_ID = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID || process.env.CONTENTFUL_SPACE_ID;
const ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_CONTENTFUL_CONTENT_DELIVERY_ACCESS_TOKEN ||
  process.env.CONTENTFUL_CONTENT_DELIVERY_ACCESS_TOKEN;
const PREVIEW_TOKEN =
  process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN ||
  process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN;

const ENVIRONMENT_ID =
  process.env.NEXT_PUBLIC_DEVELOPMENT_CONTENTFUL_ENVIRONMENT_ID ||
  process.env.CONTENTFUL_ENVIRONMENT_ID;

export const retryFetch = (url, options = {}, retries = 100, retryDelay = 2000) =>
  new Promise((resolve, reject) => {
    const fetchId =
      Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const fetchWrapper = async retriesLeft => {
      const retry = async (res, status) => {
        if (retriesLeft > 0) {
          const delayInMs =
            Number(res?.headers?.get('X-Contentful-RateLimit-Reset')) * 1000 || retryDelay;
          /* eslint-disable no-console */
          console.log(
            '\x1b[31m',
            `fetch #${fetchId} failed with ${status}, retry in ${delayInMs}ms, iterations left ${retriesLeft}`
          );
          await delay(delayInMs);
          fetchWrapper(retriesLeft - 1);
        } else {
          const errorResponse = {
            status: 500,
            message: `reached max amount of iterations, fetch failed with ${status}`,
          };
          reject(errorResponse);
        }
      };
      try {
        const res = await fetch(url, options);
        if (res) {
          const status = res.status || 500;
          switch (status) {
            case 429:
            case 500:
            case 503:
            case 504: {
              retry(res, status);
              break;
            }

            case 200: {
              if (retriesLeft < retries) {
                console.log('\x1b[32m', `fetch #${fetchId} succeed`);
              }
              resolve(res);
              break;
            }
            default: {
              reject(res);
              break;
            }
          }
        }
      } catch (err) {
        if (JSON.stringify(err).includes('ECONNRESET')) {
          retry(null, 'ECONNRESET');
        } else {
          reject(err);
        }
      }
    };
    fetchWrapper(retries);
  });

export async function fetchContent(query, preview) {
  const authToken = preview ? PREVIEW_TOKEN : ACCESS_TOKEN;
  try {
    const res = await retryFetch(
      `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/${ENVIRONMENT_ID}`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ query }),
      }
    );
    const response = await res.json();
    return response.data;
  } catch (err) {
    if (err.json) {
      let response = {};
      try {
        response = await err.json();
      } catch (e) {
        return {
          errors: [e],
          errorQuery: query,
        };
      }
      if (response.errors && response.errors instanceof Array) {
        logContentfulQueryErrors(response.errors, query);
        return {
          errors: response.errors,
          errorQuery: query,
        };
      }
      if (response.message) {
        return {
          errors: [response.message],
          errorQuery: query,
        };
      }
      return {
        errors: [response],
        errorQuery: query,
      };
    }
    return {
      errors: [err],
      errorQuery: query,
    };
  }
}

let allLocales = null;
export async function fetchAllLocales() {
  if (allLocales) {
    return allLocales;
  }
  try {
    const res = await retryFetch(
      `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/${ENVIRONMENT_ID}/locales`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      }
    );
    const { items } = await res.json();

    allLocales = items.map(locale => locale.code);
    return allLocales;
  } catch (error) {
    /* eslint-disable no-console */
    console.error('There was a problem retrieving locales');
    console.error(error);
    /* eslint-enable */
    return null;
  }
}

const parsePages = (response, locale) => {
  const values = Object.values(response);
  const pages = values
    // eslint-disable-next-line
    .map(page =>
      page?.items.map(item => {
        // eslint-disable-next-line no-underscore-dangle
        const newPage = { slug: item.slug, locale, type: item.__typename, id: item.sys.id };

        return newPage;
      })
    )
    .flat();

  return pages;
};

export async function getAllProducts(locale) {
  const productAmountQuery = await fetchContent(`
  {
    pageProductCollection(where: { slug_exists: true, availableLocales_contains_some: "${locale}" }, locale: "${locale}") {
         total
       }
  }
  `);

  const productsQuery = async skip => {
    const products = await fetchContent(`
    {
      pageProductCollection(where: { slug_exists: true, availableLocales_contains_some: "${locale}" }, locale: "${locale}", skip: ${skip}) {
        items {
          slug
          __typename
        }
      }
    }
    `);

    return products.pageProductCollection.items.map(product => ({
      slug: product.slug,
      locale: reformatContentfulLocaleToLocale(locale),
      // eslint-disable-next-line
      type: product.__typename,
    }));
  };

  if (productAmountQuery) {
    // Calculate the amount of requests needed to get all products from Contentful
    const amountOfRequests = Math.ceil(productAmountQuery.pageProductCollection.total / 100);

    const iterableArray = Array.from(Array(amountOfRequests).keys());
    const allProducts = await Promise.all(
      iterableArray.map(async number => {
        const productsBySkip = await productsQuery(number * 100);

        return {
          products: productsBySkip,
        };
      })
    );

    return (await allProducts).flatMap(item => item.products);
  }

  return null;
}

export async function fetchAdvicePageTotalArticles(locale) {
  const totalAdviceArticles = await fetchContent(
    `
    {
      pageAdviceCollection(limit: 1, where: { slug: "advice", availableLocales_contains_some: "${locale}" }, locale: "${locale}") {
        items {
          sectionsCollection (limit: 5) {
            items {
              ... on SectionAdvice {
                adviceArticlesCollection {
                  total
                }
              }
            }
          }
        }
      }
    }
  `
  );

  return totalAdviceArticles?.pageAdviceCollection?.items?.[0]?.sectionsCollection?.items?.filter(
    item => Object.keys(item)?.length
  )[0]?.adviceArticlesCollection?.total;
}

export async function fetchAllPagesWithSlug(locale) {
  const reformattedLocale = reformatLocaleToContentfulLocale(locale);

  const generalResponse = await fetchContent(`
  {
    pageGeneralCollection(where: { slug_exists: true }, locale: "${reformattedLocale}") {
      items {
        slug
        __typename
        sys {
          id
        }
      }
    }
  }
  `);

  const productResponse = await fetchContent(`
  {
    pageProductCollection(where: { slug_exists: true, availableLocales_contains_some: "${reformattedLocale}" }, locale: "${reformattedLocale}") {
      items {
       slug
       __typename
       sys {
        id
       }
      }
    }
  }
  `);

  const collectionResponse = await fetchContent(`
  {
    pageCollectionCollection(where: { slug_exists: true }, locale: "${reformattedLocale}") {
      items {
       slug
       __typename
       sys {
        id
       }
      }
    }
  }
  `);

  const productBundleResponse = await fetchContent(`
  {
    pageProductBundleCollection(where: { slug_exists: true, availableLocales_contains_some: "${reformattedLocale}" }, locale: "${reformattedLocale}") {
      items {
       slug
       __typename
       sys {
        id
       }
      }
    }
  }
  `);

  const adviceResponse = await fetchContent(`
  {
    pageAdviceCollection(where: { slug_exists: true, availableLocales_contains_some: "${reformattedLocale}" }, locale: "${reformattedLocale}") {
      items {
       slug
       __typename
       sys {
        id
       }
      }
    }
  }
  `);

  if (
    generalResponse &&
    productResponse &&
    collectionResponse &&
    productBundleResponse &&
    adviceResponse
  ) {
    const generalPages = parsePages(generalResponse, locale);
    const productPages = await getAllProducts(reformattedLocale);
    const collectionPages = parsePages(collectionResponse, locale);
    const productBundlePages = parsePages(productBundleResponse, locale);
    const advicePages = parsePages(adviceResponse, locale);
    const [advicePage] = advicePages;
    const paginatedAdvicePages = [];

    if (advicePages?.length) {
      const totalArticles = await fetchAdvicePageTotalArticles(reformattedLocale);
      const totalPages = Math.ceil(totalArticles / ADVICE_PAGE_LIMIT);

      paginatedAdvicePages.push(advicePage);
      if (totalPages >= 2) {
        // eslint-disable-next-line no-plusplus
        for (let page = 2; page <= totalPages; page++) {
          paginatedAdvicePages.push({ ...advicePage, slug: `${advicePage.slug}/page/${page}` });
        }
      }
    }

    return [
      ...generalPages,
      ...productPages,
      ...collectionPages,
      ...productBundlePages,
      ...paginatedAdvicePages,
    ];
  }

  // eslint-disable-next-line no-console
  console.error('Error returning fetchAllPagesWithSlug');
  return null;
}

export async function fetchAllPagesForSlugComponent(locale) {
  const reformattedLocale = reformatLocaleToContentfulLocale(locale);

  const generalResponse = await fetchContent(`
  {
    pageGeneralCollection(where: { slug_exists: true }, locale: "${reformattedLocale}") {
      items {
        slug
        __typename
        sys {
          id
        }
      }
    }
  }
  `);

  const productResponse = await fetchContent(`
  {
    pageProductCollection(where: { slug_exists: true, availableLocales_contains_some: "${reformattedLocale}" }, locale: "${reformattedLocale}") {
      items {
       slug
       __typename
       sys {
        id
       }
      }
    }
  }
  `);

  const collectionResponse = await fetchContent(`
  {
    pageCollectionCollection(where: { slug_exists: true }, locale: "${reformattedLocale}") {
      items {
       slug
       __typename
       sys {
        id
       }
      }
    }
  }
  `);

  const productBundleResponse = await fetchContent(`
  {
    pageProductBundleCollection(where: { slug_exists: true, availableLocales_contains_some: "${reformattedLocale}"  }, locale: "${reformattedLocale}") {
      items {
       slug
       __typename
       sys {
        id
       }
      }
    }
  }
  `);

  if (generalResponse && productResponse && collectionResponse && productBundleResponse) {
    const generalPages = parsePages(generalResponse, locale);
    const productPages = await getAllProducts(reformattedLocale);
    const collectionPages = parsePages(collectionResponse, locale);
    const productBundlePages = parsePages(productBundleResponse, locale);

    return [...generalPages, ...productPages, ...collectionPages, ...productBundlePages];
  }

  // eslint-disable-next-line no-console
  console.error('Error returning fetchAllPagesWithSlug');
  return null;
}

export async function fetchAllGeneralPagesWithSlug(locale) {
  const reformattedLocale = reformatLocaleToContentfulLocale(locale);
  const generalResponse = await fetchContent(`
  {
    pageGeneralCollection(where: { slug_exists: true }, locale: "${reformattedLocale}") {
      items {
        slug
        __typename
        sys {
          id
        }
      }
    }
  }
  `);

  if (generalResponse) {
    return parsePages(generalResponse, locale);
  }

  // eslint-disable-next-line no-console
  console.error('Error returning fetchAllGeneralPagesWithSlug');
  return null;
}

export async function fetchAllProductPagesWithSlug(locale) {
  const reformattedLocale = reformatLocaleToContentfulLocale(locale);

  const productResponse = await fetchContent(`
  {
    pageProductCollection(where: { slug_exists: true, availableLocales_contains_some: "${reformattedLocale}" }, locale: "${reformattedLocale}") {
      items {
       slug
       __typename
       sys {
        id
       }
      }
    }
  }
  `);

  if (productResponse) {
    const productPages = await getAllProducts(reformattedLocale);
    return productPages;
  }

  // eslint-disable-next-line no-console
  console.error('Error returning fetchAllProductPagesWithSlug');
  return null;
}

export async function fetchAllCollectionPagesWithSlug(locale) {
  const reformattedLocale = reformatLocaleToContentfulLocale(locale);

  const collectionResponse = await fetchContent(`
  {
    pageCollectionCollection(where: { slug_exists: true }, locale: "${reformattedLocale}") {
      items {
       slug
       __typename
       sys {
        id
       }
      }
    }
  }
  `);

  if (collectionResponse) {
    return parsePages(collectionResponse, locale);
  }

  // eslint-disable-next-line no-console
  console.error('Error returning fetchAllCollectionPagesWithSlug');
  return null;
}

export async function fetchAllProductBundlePagesWithSlug(locale) {
  const reformattedLocale = reformatLocaleToContentfulLocale(locale);

  const productBundleResponse = await fetchContent(`
  {
    pageProductBundleCollection(where: { slug_exists: true, availableLocales_contains_some: "${reformattedLocale}"  }, locale: "${reformattedLocale}") {
      items {
       slug
       __typename
       sys {
        id
       }
      }
    }
  }
  `);

  if (productBundleResponse) {
    return parsePages(productBundleResponse, locale);
  }

  // eslint-disable-next-line no-console
  console.error('Error returning fetchAllProductPagesWithSlug');
  return null;
}

export async function fetchAllAdvicePagesWithSlug(locale) {
  const reformattedLocale = reformatLocaleToContentfulLocale(locale);

  const adviceResponse = await fetchContent(`
  {
    pageAdviceCollection(where: { slug_exists: true, availableLocales_contains_some: "${reformattedLocale}" }, locale: "${reformattedLocale}") {
      items {
       slug
       __typename
       sys {
        id
       }
      }
    }
  }
  `);

  if (adviceResponse) {
    const advicePages = parsePages(adviceResponse, locale);
    const [advicePage] = advicePages;
    const paginatedAdvicePages = [];

    if (advicePages?.length) {
      const totalArticles = await fetchAdvicePageTotalArticles(reformattedLocale);
      const totalPages = Math.ceil(totalArticles / ADVICE_PAGE_LIMIT);

      paginatedAdvicePages.push(advicePage);
      if (totalPages >= 2) {
        // eslint-disable-next-line no-plusplus
        for (let page = 2; page <= totalPages; page++) {
          paginatedAdvicePages.push({ ...advicePage, slug: `${advicePage.slug}/page/${page}` });
        }
      }
    }

    return paginatedAdvicePages;
  }

  // eslint-disable-next-line no-console
  console.error('Error returning fetchAllPagesWithSlug');
  return null;
}

export async function fetchPageType(slug, locale) {
  const res = await fetchContent(`
    {
      pageGeneralCollection( where: { slug: "${slug}" }, locale: "${locale}", limit: 1) {
        items {
          __typename
        }
      }
      pageProductCollection( where: { slug: "${slug}", availableLocales_contains_some: "${locale}"  }, locale: "${locale}", limit: 1) {
        items {
          __typename
        }
      }
      pageCollectionCollection( where: { slug: "${slug}" }, locale: "${locale}", limit: 1) {
        items {
          __typename
        }
      }
      pageProductBundleCollection( where: { slug: "${slug}", availableLocales_contains_some: "${locale}"  }, locale: "${locale}", limit: 1) {
        items {
          __typename
        }
      }
      pageAdviceCollection( where: { slug: "${slug}", availableLocales_contains_some: "${locale}" }, locale: "${locale}", limit: 1) {
        items {
          __typename
        }
      }
      pageCollaboratorCollection( where: { slug: "${slug}"}, locale: "${locale}", limit: 1) {
        items {
          __typename
        }
      }
      pageBundleBuilderCollection( where: { slug: "${slug}" }, locale: "${locale}", limit: 1) {
        items {
          __typename
        }
      }
    }
    `);

  if (res) {
    const values = Object.values(res);
    const entry = values.filter(value => value.items.length > 0)[0];

    // eslint-disable-next-line no-underscore-dangle
    const type = entry?.items[0]?.__typename;

    return type;
  }
  // eslint-disable-next-line no-console
  console.error('Error returning getPageType', res);
  return null;
}

// fetch a specific page entry of a specific locale
export async function fetchLocalizedPage(type, locale, id) {
  const fetchingLocale = locale;

  const localeToReturn = reformatContentfulLocaleToLocale(locale);
  const pageType = type.charAt(0).toLowerCase() + type.slice(1); // first letter lowercase

  const res = await fetchContent(`
    {
      ${pageType}(locale: "${fetchingLocale}", id: "${id}") {
        slug
      }
    }
  `);

  if (res?.[pageType]) {
    const localizedPage = { slug: res[pageType].slug, locale: localeToReturn };

    return localizedPage;
  }
  // eslint-disable-next-line no-console
  console.error(`Error returning ${pageType},${locale},${id}, entry for fetchLocalizedPage`);
  return null;
}

const cachedAllProducts = {};

export async function fetchProductsByIds(productIds, ctfLocale) {
  if (!productIds || !productIds.length) {
    return [];
  }

  const productsQuery = `
      {
        pageProductCollection(limit: 210, where: { slug_exists: true }, locale: "${ctfLocale}") {
          items {
            __typename
            sys {
              id
            }
            title
            slug
            shopifyData: ${getShopifyDataFieldName(reformatContentfulLocaleToLocale(ctfLocale))} 
            availableLocales
            size
            thumbnailImage {
              ${IMAGE_QUERY_FIELDS}
            }
          }
        }
      }
    `;

  let products;
  if (cachedAllProducts[ctfLocale]) {
    products = cachedAllProducts[ctfLocale];
  } else {
    products = await fetchContent(productsQuery);
    cachedAllProducts[ctfLocale] = products;
  }

  if (products.pageProductCollection?.items.length > 0) {
    const filteredProducts = productIds
      .map(id =>
        products.pageProductCollection?.items.find(
          item => id === getShopifyGid('Product', item?.shopifyData?.variants?.[0]?.product_id)
        )
      )
      .filter(Boolean);
    const parsedProducts = parseCollectionProducts(filteredProducts);
    return parsedProducts;
  }

  // eslint-disable-next-line no-console
  console.error(`Error returning fetchProductsByIds ${productIds}`);
  return null;
}

export async function fetchGlobal(type, locale) {
  const globalModuleQuery = `
  {
    globalModuleCollection(where: {title_contains:"${type}"}, locale: "${locale}", limit: 1) {
      items {
        __typename
        sys {
          id
        }
      }
    }
  }
  `;

  const globalModuleMenusQuery = `
  {
    globalModuleCollection(where: {title_contains:"${type}"}, locale: "${locale}", limit: 1) {
      items {
        title
        resourcesCollection (limit: 15) {
          items {
            sys {
              id
            }
            source
            menusCollection(limit: 15) {
              items {
                ${COMPONENT_MENU_QUERY}
              }
            }
          }
        }
      }
    }
  }
  `;

  const globalModuleSubmenusQuery = `
  {
    globalModuleCollection(where: {title_contains:"${type}"}, locale: "${locale}", limit: 1) {
      items {
        title
        resourcesCollection (limit: 11) {
          items {
            sys {
              id
            }
            source
            menusCollection(limit: 15) {
              items {
                ${COMPONENT_SUBMENU_QUERY}
              }
            }
          }
        }
      }
    }
  }
  `;

  const globalModuleOtherFieldsQuery = minimizeQuerySize(`
  {
    globalModuleCollection(where: {title_contains:"${type}"}, locale: "${locale}", limit: 1) {
      items {
        title
        resourcesCollection (limit: 15) {
          items {
            sys {
              id
            }
            title
            source
            text
            multipleTexts
            richText {
              json
            }
            image {
              ${IMAGE_QUERY_FIELDS}
            }
            componentNewsletter {
              ${KLAVIYO_COMPONENT_FIELDS}
            }
            mediaCollection(limit: 6) {
              items {
                ${COMPONENT_MEDIA_QUERY}
              }
            }
            componentLink {
              ${GLOBAL_COMPONENT_LINK_QUERY}
            }
            componentButton {
              ${COMPONENT_BUTTON_QUERY}
            }
            componentCountrySelectorItemCollection {
              items {
                ${COMPONENT_COUNTRY_SELECTOR_ITEM}
              } 
            }
            componentSeo {
              ${SEO_QUERY}
            }
            product {
              shopifyData: ${getShopifyDataFieldName(reformatContentfulLocaleToLocale(locale))}
            }
            sectionsCollection (limit: 3) {
              items {
                ${SECTION_READ_MORE_ARTICLES_QUERY}
                ${SECTION_BUYING_PROS_QUERY}
                ${SECTION_BREADCRUMBS_QUERY}
                ${SECTION_TYPEFORM_QUERY}
              }
            }
            onOrOff
          }
        }
      }
    }
  }
  `);

  const globalModuleMenusOrder = `
  {
    globalModuleCollection(where: {title_contains:"${type}"}, locale: "${locale}", limit: 1) {
      items {
        __typename
        sys {
          id
        }
        resourcesCollection (limit: 15) {
          items {
            sys {
              id
            }
            menusCollection (limit: 15) {
              items {
                ... on ComponentMenu {
                  sys {
                    id
                  }
                }
                ... on ComponentSubmenu {
                  sys {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `;

  const globalModuleResponse = await fetchContent(globalModuleQuery);
  const globalModuleMenusResponse = await fetchContent(globalModuleMenusQuery);
  const globalModuleSubmenusResponse = await fetchContent(globalModuleSubmenusQuery);
  const globalModuleOtherFieldsResponse = await fetchContent(globalModuleOtherFieldsQuery);
  const globalModuleMenusOrderResponse = await fetchContent(globalModuleMenusOrder);

  if (
    globalModuleResponse?.globalModuleCollection.items.length &&
    globalModuleMenusResponse?.globalModuleCollection.items.length &&
    globalModuleSubmenusResponse?.globalModuleCollection.items.length &&
    globalModuleOtherFieldsResponse?.globalModuleCollection.items.length &&
    globalModuleMenusOrderResponse?.globalModuleCollection.items.length
  ) {
    const globalModule = globalModuleResponse.globalModuleCollection.items[0];

    const resourceMenusOrder =
      globalModuleMenusOrderResponse.globalModuleCollection.items[0].resourcesCollection.items
        .filter(resource => resource?.sys)
        .map(resource => ({
          menuIds: [
            ...resource?.menusCollection?.items
              ?.filter(menu => menu?.sys)
              .map(menu => menu?.sys?.id),
          ],
        }));

    const resources = [
      ...globalModuleOtherFieldsResponse?.globalModuleCollection.items[0].resourcesCollection.items,
    ]
      .filter(resource => resource?.sys)
      .map(resource => ({
        id: resource.sys?.id,
        ...resource,
      }));

    const menus = [
      ...globalModuleMenusResponse?.globalModuleCollection.items[0].resourcesCollection.items,
    ]
      .filter(resource => resource?.sys)
      .map(resource => ({
        menusCollection: [
          ...resource.menusCollection?.items
            ?.filter(menu => menu?.sys)
            .map(menu => ({
              id: menu?.sys?.id,
              ...menu,
            })),
        ],
        id: resource?.sys?.id,
      }));

    const subMenus = [
      ...globalModuleSubmenusResponse?.globalModuleCollection.items[0].resourcesCollection.items,
    ]
      .filter(resource => resource?.sys)
      .map(resource => ({
        menusCollection: [...resource.menusCollection?.items?.filter(menu => menu?.sys)].map(
          menu => ({
            id: menu?.sys?.id,
            ...menu,
          })
        ),
        id: resource?.sys?.id,
      }));

    const resourcesWithBothMenus = () => {
      const combinedResources = [];
      // We only have to loop through either menus or submenus, since they're the same length.
      for (let i = 0; i < menus.length; i += 1) {
        const resource = {
          menusCollection: {
            items: sortSections(
              [...menus[i]?.menusCollection, ...subMenus[i]?.menusCollection],
              resourceMenusOrder[i].menuIds,
              'id'
            ),
          },
          ...resources[i],
        };
        combinedResources.push(resource);
      }

      return combinedResources;
    };

    const combinedResources = resourcesWithBothMenus();
    globalModule.resourcesCollection = { items: combinedResources };

    return globalModule;
  }

  return null;
}

export async function fetchProductRatings({ productId, locale }) {
  const waeLocale = reformatContentfulLocaleToLocale(locale);
  const waeSettings = {
    target: '#wae-sdk',
    viewType: 'REVIEW',
    productIdType: 'BARCODE',
    productId,
    brandId: process.env.NEXT_PUBLIC_WAE_BRAND_ID,
    API_KEY: process.env.NEXT_PUBLIC_WAE_API_KEY,
    language: waeLocale,
    country: waeLocale,
  };

  const fetchWaeGeneralData = async () => {
    try {
      const request = await fetch(
        `https://api.weareeves.com/embed/v2/productReviews?${new URLSearchParams({
          brandId: waeSettings.brandId,
          API_KEY: waeSettings.API_KEY,
          embedId: waeSettings.productId,
          idType: waeSettings.productIdType,
          country: waeSettings.country,
          language: waeSettings.language,
          pageSize: 0,
        })}`
      );

      if (request.ok) {
        const res = await request.json();
        return res;
      }
      return {};
    } catch (e) {
      console.log(e);
      return {};
    }
  };

  const data = await fetchWaeGeneralData();
  return data;
}
