import useQuickAddToCartTracking from 'hooks/tracking/useQuickAddToCartTracking';
import useProductAddedTracking from 'hooks/tracking/useProductAddedTracking';
import { getShopifyGid } from 'lib/shopify/shopifyGid';
import { useSettings } from 'contexts/SettingContext';
import { useAddItemsToCart } from 'hooks';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { getSelectedSalonNo, getStorageCustomerSalonNo } from 'lib/shopify';

const DatatricsAddToCart = ({ globalModules, locale }) => {
  const { addProduct, setSaveItemSelectionList } = useSettings();
  const router = useRouter();
  const {
    freeItem,
    amountOfProductsToGetFreeItem,
    promoCollectionName,
    shopifyTagsToExcludeFromPromo,
  } = globalModules?.secondpartsettings || {};

  const addItems = useAddItemsToCart();

  const parseSpecs = productData => {
    const specs = [];
    if (!productData) return specs;

    const specNames = {
      cproductcategory: 'Product Category',
      cproducttype: 'Product type',
      cresult: 'Result',
      csubcategory: 'Subcategory',
      ccategory: 'Category',
      ccollection: 'Collection',
      cfreeof: 'Free of',
      chairtype: 'Hair type',
      chold: 'Hold',
    };

    Object.entries(specNames).forEach(([key, val]) => {
      if (productData[key]) {
        const valuesArr = productData[key].split(',');
        valuesArr.forEach(value => {
          specs.push({
            type: {
              filterType: val,
            },
            value,
          });
        });
      }
    });

    return specs;
  };

  const trackAddToCart = productData => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuickAddToCartTracking({
      product: {
        name: productData.name,
        id: getShopifyGid('Product', productData.id),
        productSpecs: parseSpecs(productData),
        price: productData.price,
        index: 0,
        quantity: 1,
      },
      list: `Cart Datatrics`,
      setSaveItemSelectionList,
      locale,
    });
    // Universal Analytics
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useProductAddedTracking({
      affiliatedSalonNo: getStorageCustomerSalonNo(locale),
      selectedSalonNo: getSelectedSalonNo(locale),
      product: {
        name: productData.name,
        id: getShopifyGid('Product', productData.id),
        brand: 'Keune',
        category: productData.cproducttype,
        price: productData.price,
        discount: productData.compareatpricerice
          ? productData.compareatpricerice - productData.price
          : null,
        quantity: 1,
      },
      location: { action: router },
    });

    addProduct({
      title: productData.name,
      slug: productData.url.split('/').pop(),
      variantId: getShopifyGid('ProductVariant', productData.variantid),
    });
  };

  const datatrictsAddItems = async () => {
    const product = document.querySelector('#addToCartPseudoButton').getAttribute('data-product');
    const productData = JSON.parse(product);
    const items = [
      {
        variantId: getShopifyGid('ProductVariant', productData.variantid),
        quantity: 1,
      },
    ];

    const data = await addItems(items, freeItem?.product?.shopifyData, {
      amountOfProductsToGetFreeItem,
      promoCollectionName,
      shopifyTagsToExcludeFromPromo,
    });

    if (data) {
      trackAddToCart(productData);
    }
  };

  return (
    <>
      <Script type="text/javascript">
        {`
          function datatricsAddToCart(d) {
            const button = document.querySelector('#addToCartPseudoButton');
            button.setAttribute('data-product', JSON.stringify(d.dataset));
            button.click();
          };
        `}
      </Script>
      {/* eslint-disable-next-line */}
      <div
        id="addToCartPseudoButton"
        style={{ display: 'none' }}
        data-product=""
        onClick={datatrictsAddItems}
      />
      {/* <span
        // a test button for lcalhost. Appears on top of any page

        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
            <button onclick="datatricsAddToCart(this)"
              data-id="6946535997621"
              data-variantId="gid://shopify/ProductVariant/40892313796789"
              data-name="Care Color Brillianz Mask"
              data-size="200ml"
              data-price="27.45"
              data-compareatprice
              data-url="https://www.keune.com/nl/care-color-brillianz-mask"
              data-image="https://images.ctfassets.net/9kjqrnn60hxu/1hm30FeiYvVSMVQ8rDBiDk/76cd6d8affc65966b33de788cc9f204c/Keune-Care-Color-Brillianz-Mask.png"
              data-ccategory="Care"
              data-ccollection="Keune Care"
              data-cfreeof="Dierproeven"
              data-chairtype="Gekleurd,Steil,Golvend"
              data-cproductcategory="Haarverzorging"
              data-cproducttype="Haarmasker"
              data-cresult="ezond gekleurd haar,Zacht haar"
              data-csubcategory="Care Color Brillianz"
              class="quickCartSection_addToCartButton__txmc0"
              type="button">ddd</button>
            `,
        }}
      /> */}
    </>
  );
};

export default DatatricsAddToCart;
