import {
  get as getSession,
  keys as sessionKeys,
  set as sessionSet,
} from 'lib/utils/sessionStorage';
import { get as getStorage, keys, set as setStorage } from '../utils/localStorage';

export const preparePayload = (query, variables) => ({
  query,
  variables,
});

export const WEBSHOP_STORES = {
  NL: 'nl',
  FR: 'fr',
  US: 'na',
  // adding fr-fr to have the same collection sorting on fr-fr locale
  FR_FR: 'fr-fr',
  DE: 'de',
  UK: 'uk',
  NL_BE: 'nl-be',
  FR_BE: 'fr-be', // is returned in methods: getLocaleByShopDomain
  ES: 'es',
  NO: 'no',
  SE: 'se',
};

export const getStoreFrontApiCredentials = locale => {
  const nlStore = {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN,
    shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION,
    storeFrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  };
  switch (locale) {
    case WEBSHOP_STORES.FR:
    case WEBSHOP_STORES.FR_FR:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_FRENCH_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_FRENCH_API_VERSION,
        storeFrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_FRENCH_STOREFRONT_ACCESS_TOKEN,
      };
    case WEBSHOP_STORES.US:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_US_ACTUAL_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_US_API_VERSION,
        storeFrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_US_STOREFRONT_ACCESS_TOKEN,
      };
    case WEBSHOP_STORES.DE:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_DE_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_DE_API_VERSION,
        storeFrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_DE_STOREFRONT_ACCESS_TOKEN,
      };
    case WEBSHOP_STORES.UK:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_UK_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_UK_API_VERSION,
        storeFrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_UK_STOREFRONT_ACCESS_TOKEN,
      };
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_BE_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_BE_API_VERSION,
        storeFrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_BE_STOREFRONT_ACCESS_TOKEN,
      };
    case WEBSHOP_STORES.ES:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_ES_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_ES_API_VERSION,
        storeFrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_ES_STOREFRONT_ACCESS_TOKEN,
      };
    case WEBSHOP_STORES.NO:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_NO_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_NO_API_VERSION,
        storeFrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_NO_STOREFRONT_ACCESS_TOKEN,
      };
    case WEBSHOP_STORES.SE:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_SE_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_SE_API_VERSION,
        storeFrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_SE_STOREFRONT_ACCESS_TOKEN,
      };
    case WEBSHOP_STORES.NL:
      return nlStore;
    default:
      return nlStore;
  }
};

export const getAdminApiCredentials = locale => {
  const nlAdmin = {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_ACTUAL_STORE_DOMAIN,
    shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Shopify-Access-Token': process.env.SHOPIFY_ADMIN_ACCESS_TOKEN,
    },
  };
  switch (locale) {
    case WEBSHOP_STORES.FR:
    case WEBSHOP_STORES.FR_FR:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_FRENCH_ACTUAL_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_FRENCH_API_VERSION,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Shopify-Access-Token': process.env.SHOPIFY_FRENCH_ADMIN_ACCESS_TOKEN,
        },
      };
    case WEBSHOP_STORES.US:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_US_ACTUAL_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_US_API_VERSION,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Shopify-Access-Token': process.env.SHOPIFY_US_ADMIN_ACCESS_TOKEN,
        },
      };
    case WEBSHOP_STORES.DE:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_DE_ACTUAL_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_DE_API_VERSION,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Shopify-Access-Token': process.env.SHOPIFY_DE_ADMIN_ACCESS_TOKEN,
        },
      };
    case WEBSHOP_STORES.UK:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_UK_ACTUAL_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_UK_API_VERSION,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Shopify-Access-Token': process.env.SHOPIFY_UK_ADMIN_ACCESS_TOKEN,
        },
      };
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_BE_ACTUAL_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_BE_API_VERSION,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Shopify-Access-Token': process.env.SHOPIFY_BE_ADMIN_ACCESS_TOKEN,
        },
      };
    case WEBSHOP_STORES.ES:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_ES_ACTUAL_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_ES_API_VERSION,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Shopify-Access-Token': process.env.SHOPIFY_ES_ADMIN_ACCESS_TOKEN,
        },
      };
    case WEBSHOP_STORES.NO:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_NO_ACTUAL_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_NO_API_VERSION,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Shopify-Access-Token': process.env.SHOPIFY_NO_ADMIN_ACCESS_TOKEN,
        },
      };
    case WEBSHOP_STORES.SE:
      return {
        storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_SE_ACTUAL_STORE_DOMAIN,
        shopifyApiVersion: process.env.NEXT_PUBLIC_SHOPIFY_SE_API_VERSION,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Shopify-Access-Token': process.env.SHOPIFY_SE_ADMIN_ACCESS_TOKEN,
        },
      };
    case WEBSHOP_STORES.NL:
      return nlAdmin;
    default:
      return nlAdmin;
  }
};

export const getCheckoutIdByLocale = locale => {
  const checkoutNl = getStorage(keys.CHECKOUT);
  const checkoutFr = getStorage(keys.CHECKOUT_FR);
  const checkoutUS = getStorage(keys.CHECKOUT_US);
  const checkoutDE = getStorage(keys.CHECKOUT_DE);
  const checkoutUK = getStorage(keys.CHECKOUT_UK);
  const checkoutBE = getStorage(keys.CHECKOUT_BE);
  const checkoutES = getStorage(keys.CHECKOUT_ES);
  const checkoutNO = getStorage(keys.CHECKOUT_NO);
  const checkoutSE = getStorage(keys.CHECKOUT_SE);

  let error = '';

  // if (locale === WEBSHOP_STORES.NL && !checkoutNl) {
  //   error = 'No Active NL checkout';
  // }

  // if (locale === WEBSHOP_STORES.FR && !checkoutFr) {
  //   error = 'No Active FR checkout';
  // }

  // if (locale === WEBSHOP_STORES.US && !checkoutUS) {
  //   error = 'No Active United States checkout';
  // }

  // if (locale === WEBSHOP_STORES.DE && !checkoutDE) {
  //   error = 'No Active Germany checkout';
  // }

  // if (locale === WEBSHOP_STORES.UK && !checkoutUK) {
  //   error = 'No Active UK checkout';
  // }

  // if ((locale === WEBSHOP_STORES.FR_BE || locale === WEBSHOP_STORES.NL_BE) && !checkoutBE) {
  //   error = 'No Active BE checkout';
  // }

  // if (locale === WEBSHOP_STORES.ES && !checkoutUK) {
  //   error = 'No Active Spain checkout';
  // }

  if (!locale) {
    error = 'No locale parameter';
  }

  if (error || !locale) {
    console.error('getCheckoutIdByLocale function errored with:', error);
  }

  switch (locale) {
    case WEBSHOP_STORES.FR:
      return checkoutFr;

    case WEBSHOP_STORES.NL:
      return checkoutNl;

    case WEBSHOP_STORES.US:
      return checkoutUS;

    case WEBSHOP_STORES.DE:
      return checkoutDE;

    case WEBSHOP_STORES.UK:
      return checkoutUK;

    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      return checkoutBE;

    case WEBSHOP_STORES.ES:
      return checkoutES;

    case WEBSHOP_STORES.NO:
      return checkoutNO;

    case WEBSHOP_STORES.SE:
      return checkoutSE;

    default:
      return '';
  }
};

export const getWishlistKey = locale => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
      return keys.WISHLIST_FR;
    case WEBSHOP_STORES.NL:
      return keys.WISHLIST;
    case WEBSHOP_STORES.US:
      return keys.WISHLIST_US;
    case WEBSHOP_STORES.DE:
      return keys.WISHLIST_DE;
    case WEBSHOP_STORES.UK:
      return keys.WISHLIST_UK;
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      return keys.WISHLIST_BE;
    case WEBSHOP_STORES.ES:
      return keys.WISHLIST_ES;
    case WEBSHOP_STORES.NO:
      return keys.WISHLIST_NO;
    case WEBSHOP_STORES.SE:
      return keys.WISHLIST_SE;
    default:
      return '';
  }
};

export const getSessionCheckoutUrl = locale => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
      return getSession(sessionKeys.CHECKOUT_FR_URL);
    case WEBSHOP_STORES.NL:
      return getSession(sessionKeys.CHECKOUT_URL);
    case WEBSHOP_STORES.US:
      return getSession(sessionKeys.CHECKOUT_US_URL);
    case WEBSHOP_STORES.DE:
      return getSession(sessionKeys.CHECKOUT_DE_URL);
    case WEBSHOP_STORES.UK:
      return getSession(sessionKeys.CHECKOUT_UK_URL);
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      return getSession(sessionKeys.CHECKOUT_BE_URL);
    case WEBSHOP_STORES.ES:
      return getSession(sessionKeys.CHECKOUT_ES_URL);
    case WEBSHOP_STORES.NO:
      return getSession(sessionKeys.CHECKOUT_NO_URL);
    case WEBSHOP_STORES.SE:
      return getSession(sessionKeys.CHECKOUT_SE_URL);
    default:
      return '';
  }
};

export const setSessionCheckoutUrl = (locale, checkoutUrl) => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
      sessionSet(sessionKeys.CHECKOUT_FR_URL, checkoutUrl);
      break;
    case WEBSHOP_STORES.NL:
      sessionSet(sessionKeys.CHECKOUT_URL, checkoutUrl);
      break;
    case WEBSHOP_STORES.US:
      sessionSet(sessionKeys.CHECKOUT_US_URL, checkoutUrl);
      break;
    case WEBSHOP_STORES.DE:
      sessionSet(sessionKeys.CHECKOUT_DE_URL, checkoutUrl);
      break;
    case WEBSHOP_STORES.UK:
      sessionSet(sessionKeys.CHECKOUT_UK_URL, checkoutUrl);
      break;
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      sessionSet(sessionKeys.CHECKOUT_UK_URL, checkoutUrl);
      break;
    case WEBSHOP_STORES.ES:
      sessionSet(sessionKeys.CHECKOUT_ES_URL, checkoutUrl);
      break;
    case WEBSHOP_STORES.NO:
      sessionSet(sessionKeys.CHECKOUT_NO_URL, checkoutUrl);
      break;
    case WEBSHOP_STORES.SE:
      sessionSet(sessionKeys.CHECKOUT_SE_URL, checkoutUrl);
      break;
    default:
      break;
  }
};

export const getMultiPassSecret = locale => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
      return process.env.SHOPIFY_FRENCH_MULTIPASS_SECRET;
    case WEBSHOP_STORES.NL:
      return process.env.SHOPIFY_MULTIPASS_SECRET;
    case WEBSHOP_STORES.US:
      return process.env.SHOPIFY_US_MULTIPASS_SECRET;
    case WEBSHOP_STORES.DE:
      return process.env.SHOPIFY_DE_MULTIPASS_SECRET;
    case WEBSHOP_STORES.UK:
      return process.env.SHOPIFY_UK_MULTIPASS_SECRET;
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      return process.env.SHOPIFY_BE_MULTIPASS_SECRET;
    case WEBSHOP_STORES.ES:
      return process.env.SHOPIFY_ES_MULTIPASS_SECRET;
    case WEBSHOP_STORES.NO:
      return process.env.SHOPIFY_NO_MULTIPASS_SECRET;
    case WEBSHOP_STORES.SE:
      return process.env.SHOPIFY_SE_MULTIPASS_SECRET;
    default:
      return '';
  }
};

export const getStoreDomain = locale => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
      return `${process.env.NEXT_PUBLIC_SHOPIFY_FRENCH_STORE_DOMAIN}`;
    case WEBSHOP_STORES.NL:
      return `${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}`;
    case WEBSHOP_STORES.US: // todo check when the new domain is US store added
      return `${process.env.NEXT_PUBLIC_SHOPIFY_US_ACTUAL_STORE_DOMAIN}`;
    case WEBSHOP_STORES.DE:
      return `${process.env.NEXT_PUBLIC_SHOPIFY_DE_ACTUAL_STORE_DOMAIN}`;
    case WEBSHOP_STORES.UK:
      return `${process.env.NEXT_PUBLIC_SHOPIFY_UK_STORE_DOMAIN}`;
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      return `${process.env.NEXT_PUBLIC_SHOPIFY_BE_STORE_DOMAIN}`;
    case WEBSHOP_STORES.ES:
      return `${process.env.NEXT_PUBLIC_SHOPIFY_ES_STORE_DOMAIN}`;
    case WEBSHOP_STORES.NO:
      return `${process.env.NEXT_PUBLIC_SHOPIFY_NO_STORE_DOMAIN}`;
    case WEBSHOP_STORES.SE:
      return `${process.env.NEXT_PUBLIC_SHOPIFY_SE_STORE_DOMAIN}`;
    default:
      return '';
  }
};

const WEBSHOP_COUNTRY_NAMES = {
  NETHERLANDS: 'Netherlands',
  FRANCE: 'France',
  UNITED_STATES: 'United States',
  GERMANY: 'Germany',
  UNITED_KINGDOM: 'United Kingdom',
  BELGIUM: 'Belgium',
  SPAIN: 'Spain',
  NORWAY: 'Norway',
  SWEDEN: 'Sweden',
};

export const getLocaleByCountry = countryName => {
  switch (countryName) {
    case WEBSHOP_COUNTRY_NAMES.FRANCE:
      return WEBSHOP_STORES.FR;
    case WEBSHOP_COUNTRY_NAMES.NETHERLANDS:
      return WEBSHOP_STORES.NL;
    case WEBSHOP_COUNTRY_NAMES.UNITED_STATES:
      return WEBSHOP_STORES.US;
    case WEBSHOP_COUNTRY_NAMES.GERMANY:
      return WEBSHOP_STORES.DE;
    case WEBSHOP_COUNTRY_NAMES.UNITED_KINGDOM:
      return WEBSHOP_STORES.UK;
    case WEBSHOP_COUNTRY_NAMES.BELGIUM:
      return WEBSHOP_STORES.FR_BE; // in this case returning only fr-be
    case WEBSHOP_COUNTRY_NAMES.SPAIN:
      return WEBSHOP_STORES.ES;
    case WEBSHOP_COUNTRY_NAMES.NORWAY:
      return WEBSHOP_STORES.NO;
    case WEBSHOP_COUNTRY_NAMES.SWEDEN:
      return WEBSHOP_STORES.SE;
    default:
      return '';
  }
};

export const getCountryByLocale = locale => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
      return WEBSHOP_COUNTRY_NAMES.FRANCE;
    case WEBSHOP_STORES.NL:
      return WEBSHOP_COUNTRY_NAMES.NETHERLANDS;
    case WEBSHOP_STORES.US:
      return WEBSHOP_COUNTRY_NAMES.UNITED_STATES;
    case WEBSHOP_STORES.DE:
      return WEBSHOP_COUNTRY_NAMES.GERMANY;
    case WEBSHOP_STORES.UK:
      return WEBSHOP_COUNTRY_NAMES.UNITED_KINGDOM;
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      return WEBSHOP_COUNTRY_NAMES.BELGIUM;
    case WEBSHOP_STORES.ES:
      return WEBSHOP_COUNTRY_NAMES.SPAIN;
    case WEBSHOP_STORES.NO:
      return WEBSHOP_COUNTRY_NAMES.NORWAY;
    case WEBSHOP_STORES.SE:
      return WEBSHOP_COUNTRY_NAMES.SWEDEN;
    default:
      return '';
  }
};

export const getLocaleByShopDomain = shopDomain => {
  switch (shopDomain) {
    case process.env.NEXT_PUBLIC_SHOPIFY_FRENCH_ACTUAL_STORE_DOMAIN:
    case process.env.NEXT_PUBLIC_SHOPIFY_FRENCH_STORE_DOMAIN:
      return WEBSHOP_STORES.FR;
    case process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN:
    case process.env.NEXT_PUBLIC_SHOPIFY_ACTUAL_STORE_DOMAIN:
      return WEBSHOP_STORES.NL;
    case process.env.NEXT_PUBLIC_SHOPIFY_US_ACTUAL_STORE_DOMAIN:
      return WEBSHOP_STORES.US;
    case process.env.NEXT_PUBLIC_SHOPIFY_DE_ACTUAL_STORE_DOMAIN:
    case process.env.NEXT_PUBLIC_SHOPIFY_DE_STORE_DOMAIN:
      return WEBSHOP_STORES.DE;
    case process.env.NEXT_PUBLIC_SHOPIFY_UK_ACTUAL_STORE_DOMAIN:
    case process.env.NEXT_PUBLIC_SHOPIFY_UK_STORE_DOMAIN:
      return WEBSHOP_STORES.UK;
    case process.env.NEXT_PUBLIC_SHOPIFY_BE_ACTUAL_STORE_DOMAIN:
    case process.env.NEXT_PUBLIC_SHOPIFY_BE_STORE_DOMAIN:
      return WEBSHOP_STORES.FR_BE; // in this case returning only fr-be
    case process.env.NEXT_PUBLIC_SHOPIFY_ES_ACTUAL_STORE_DOMAIN:
    case process.env.NEXT_PUBLIC_SHOPIFY_ES_STORE_DOMAIN:
      return WEBSHOP_STORES.ES;
    case process.env.NEXT_PUBLIC_SHOPIFY_NO_ACTUAL_STORE_DOMAIN:
    case process.env.NEXT_PUBLIC_SHOPIFY_NO_STORE_DOMAIN:
      return WEBSHOP_STORES.NO;
    case process.env.NEXT_PUBLIC_SHOPIFY_SE_ACTUAL_STORE_DOMAIN:
    case process.env.NEXT_PUBLIC_SHOPIFY_SE_STORE_DOMAIN:
      return WEBSHOP_STORES.SE;
    default:
      return '';
  }
};

const SHOPIFY_DATA_FIELD_NAME = {
  SHOPIFY_DATA_FR: 'shopifyDataFr',
  SHOPIFY_DATA_NL: 'shopifyData',
  SHOPIFY_DATA_US: 'shopifyDataUs',
  SHOPIFY_DATA_DE: 'shopifyDataDe',
  SHOPIFY_DATA_UK: 'shopifyDataUk',
  SHOPIFY_DATA_BE: 'shopifyDataBe',
  SHOPIFY_DATA_ES: 'shopifyDataEs',
  SHOPIFY_DATA_NO: 'shopifyDataNo',
  SHOPIFY_DATA_SE: 'shopifyDataSe',
};

export const getShopifyDataFieldName = locale => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
    case WEBSHOP_STORES.FR_FR:
      return SHOPIFY_DATA_FIELD_NAME.SHOPIFY_DATA_FR;
    case WEBSHOP_STORES.NL:
      return SHOPIFY_DATA_FIELD_NAME.SHOPIFY_DATA_NL;
    case WEBSHOP_STORES.US:
      return SHOPIFY_DATA_FIELD_NAME.SHOPIFY_DATA_US;
    case WEBSHOP_STORES.DE:
      return SHOPIFY_DATA_FIELD_NAME.SHOPIFY_DATA_DE;
    case WEBSHOP_STORES.UK:
      return SHOPIFY_DATA_FIELD_NAME.SHOPIFY_DATA_UK;
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      return SHOPIFY_DATA_FIELD_NAME.SHOPIFY_DATA_BE;
    case WEBSHOP_STORES.ES:
      return SHOPIFY_DATA_FIELD_NAME.SHOPIFY_DATA_ES;
    case WEBSHOP_STORES.NO:
      return SHOPIFY_DATA_FIELD_NAME.SHOPIFY_DATA_NO;
    case WEBSHOP_STORES.SE:
      return SHOPIFY_DATA_FIELD_NAME.SHOPIFY_DATA_SE;
    default:
      return SHOPIFY_DATA_FIELD_NAME.SHOPIFY_DATA_NL;
  }
};

export const getWebhookSecretByStoreLocale = locale => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
      return process.env.SHOPIFY_FRENCH_WEBHOOK_SECRET;
    case WEBSHOP_STORES.NL:
      return process.env.SHOPIFY_WEBHOOK_SECRET;
    case WEBSHOP_STORES.US:
      return process.env.SHOPIFY_US_WEBHOOK_SECRET;
    case WEBSHOP_STORES.DE:
      return process.env.SHOPIFY_DE_WEBHOOK_SECRET;
    case WEBSHOP_STORES.UK:
      return process.env.SHOPIFY_UK_WEBHOOK_SECRET;
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      return process.env.SHOPIFY_BE_WEBHOOK_SECRET;
    case WEBSHOP_STORES.ES:
      return process.env.SHOPIFY_ES_WEBHOOK_SECRET;
    case WEBSHOP_STORES.NO:
      return process.env.SHOPIFY_NO_WEBHOOK_SECRET;
    case WEBSHOP_STORES.SE:
      return process.env.SHOPIFY_SE_WEBHOOK_SECRET;
    default:
      return '';
  }
};

const CURRENCY_CODES = {
  EUR: 'EUR',
  USD: 'USD',
  GBP: 'GBP',
  NOK: 'NOK',
  SEK: 'SEK',
};

const CURRENCY_CHARS = {
  EUR: '€',
  USD: '$',
  GBP: '£',
  NOK: 'kr',
  SEK: 'kr',
};

export const getCurrencySymbol = locale => {
  switch (locale) {
    case WEBSHOP_STORES.US:
      return CURRENCY_CHARS.USD;
    case WEBSHOP_STORES.UK:
      return CURRENCY_CHARS.GBP;
    case WEBSHOP_STORES.NO:
      return CURRENCY_CHARS.NOK;
    case WEBSHOP_STORES.SE:
      return CURRENCY_CHARS.SEK;
    default:
      // case WEBSHOP_STORES.NL:
      // case WEBSHOP_STORES.FR:
      // case WEBSHOP_STORES.DE:
      // case WEBSHOP_STORES.FR_BE:
      // case WEBSHOP_STORES.NL_BE:
      // case WEBSHOP_STORES.ES:

      return CURRENCY_CHARS.EUR;
  }
};

export const getCurrencyCodeByShopDomain = shopDomain => {
  switch (shopDomain) {
    // just to make sure it works as expected added two domains for France. todo figure out which oe works
    case process.env.NEXT_PUBLIC_SHOPIFY_FRENCH_ACTUAL_STORE_DOMAIN:
    case process.env.NEXT_PUBLIC_SHOPIFY_FRENCH_STORE_DOMAIN:
    case process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN:
    case process.env.NEXT_PUBLIC_SHOPIFY_DE_ACTUAL_STORE_DOMAIN:
    case process.env.NEXT_PUBLIC_SHOPIFY_ES_ACTUAL_STORE_DOMAIN:
    case process.env.NEXT_PUBLIC_SHOPIFY_ES_STORE_DOMAIN:
      return CURRENCY_CODES.EUR;
    case process.env.NEXT_PUBLIC_SHOPIFY_US_ACTUAL_STORE_DOMAIN:
      return CURRENCY_CODES.USD;
    case process.env.NEXT_PUBLIC_SHOPIFY_UK_ACTUAL_STORE_DOMAIN:
      return CURRENCY_CODES.GBP;
    case process.env.NEXT_PUBLIC_SHOPIFY_NO_ACTUAL_STORE_DOMAIN:
      return CURRENCY_CODES.NOK;
    case process.env.NEXT_PUBLIC_SHOPIFY_SE_ACTUAL_STORE_DOMAIN:
      return CURRENCY_CODES.SEK;
    default:
      /*
       * NL store
       * FR store
       * DE store
       * BE store
       * */
      return CURRENCY_CODES.EUR;
  }
};

export const getCurrencyCode = locale => {
  switch (locale) {
    case WEBSHOP_STORES.US:
      return CURRENCY_CODES.USD;
    case WEBSHOP_STORES.UK:
      return CURRENCY_CODES.GBP;
    case WEBSHOP_STORES.NO:
      return CURRENCY_CODES.NOK;
    case WEBSHOP_STORES.SE:
      return CURRENCY_CODES.SEK;
    default:
      // case WEBSHOP_STORES.NL:
      // case WEBSHOP_STORES.FR:
      // case WEBSHOP_STORES.DE:
      // case WEBSHOP_STORES.FR_BE:
      // case WEBSHOP_STORES.NL_BE:
      // case WEBSHOP_STORES.ES:
      return CURRENCY_CODES.EUR;
  }
};

export const setStorageCustomerSalonNo = (locale, salonNo) => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
      setStorage(keys.CUSTOMER_FR_SALON_NO, salonNo);
      break;
    case WEBSHOP_STORES.NL:
      setStorage(keys.CUSTOMER_SALON_NO, salonNo);
      break;
    case WEBSHOP_STORES.DE:
      setStorage(keys.CUSTOMER_DE_SALON_NO, salonNo);
      break;
    case WEBSHOP_STORES.UK:
      setStorage(keys.CUSTOMER_UK_SALON_NO, salonNo);
      break;
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      setStorage(keys.CUSTOMER_BE_SALON_NO, salonNo);
      break;
    case WEBSHOP_STORES.ES:
      setStorage(keys.CUSTOMER_ES_SALON_NO, salonNo);
      break;
    case WEBSHOP_STORES.NO:
      setStorage(keys.CUSTOMER_NO_SALON_NO, salonNo);
      break;
    case WEBSHOP_STORES.SE:
      setStorage(keys.CUSTOMER_SE_SALON_NO, salonNo);
      break;
    default:
      break;
  }
};

export const setStorageCustomerSalonName = (locale, salonName) => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
      setStorage(keys.CUSTOMER_FR_SALON_NAME, salonName);
      break;
    case WEBSHOP_STORES.NL:
      setStorage(keys.CUSTOMER_SALON_NAME, salonName);
      break;
    case WEBSHOP_STORES.DE:
      setStorage(keys.CUSTOMER_DE_SALON_NAME, salonName);
      break;
    case WEBSHOP_STORES.UK:
      setStorage(keys.CUSTOMER_UK_SALON_NAME, salonName);
      break;
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      setStorage(keys.CUSTOMER_BE_SALON_NAME, salonName);
      break;
    case WEBSHOP_STORES.ES:
      setStorage(keys.CUSTOMER_ES_SALON_NAME, salonName);
      break;
    case WEBSHOP_STORES.NO:
      setStorage(keys.CUSTOMER_NO_SALON_NAME, salonName);
      break;
    case WEBSHOP_STORES.SE:
      setStorage(keys.CUSTOMER_SE_SALON_NAME, salonName);
      break;
    default:
      break;
  }
};

export const setStorageSelectedSalonNo = (locale, selectedSalon) => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
      setStorage(keys.SELECTED_FR_SALON_NO, selectedSalon);
      break;
    case WEBSHOP_STORES.NL:
      setStorage(keys.SELECTED_SALON_NO, selectedSalon);
      break;
    case WEBSHOP_STORES.DE:
      setStorage(keys.SELECTED_DE_SALON_NO, selectedSalon);
      break;
    case WEBSHOP_STORES.UK:
      setStorage(keys.SELECTED_UK_SALON_NO, selectedSalon);
      break;
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      setStorage(keys.SELECTED_BE_SALON_NO, selectedSalon);
      break;
    case WEBSHOP_STORES.ES:
      setStorage(keys.SELECTED_ES_SALON_NO, selectedSalon);
      break;
    case WEBSHOP_STORES.NO:
      setStorage(keys.SELECTED_NO_SALON_NO, selectedSalon);
      break;
    case WEBSHOP_STORES.SE:
      setStorage(keys.SELECTED_SE_SALON_NO, selectedSalon);
      break;
    default:
      break;
  }
};

export const getStorageCustomerSalonNo = locale => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
      return getStorage(keys.CUSTOMER_FR_SALON_NO);
    case WEBSHOP_STORES.NL:
      return getStorage(keys.CUSTOMER_SALON_NO);
    case WEBSHOP_STORES.DE:
      return getStorage(keys.CUSTOMER_DE_SALON_NO);
    case WEBSHOP_STORES.UK:
      return getStorage(keys.CUSTOMER_UK_SALON_NO);
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      return getStorage(keys.CUSTOMER_BE_SALON_NO);
    case WEBSHOP_STORES.ES:
      return getStorage(keys.CUSTOMER_ES_SALON_NO);
    case WEBSHOP_STORES.NO:
      return getStorage(keys.CUSTOMER_NO_SALON_NO);
    case WEBSHOP_STORES.SE:
      return getStorage(keys.CUSTOMER_SE_SALON_NO);
    default:
      return '';
  }
};

export const getStorageCustomerSalonName = locale => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
      return getStorage(keys.CUSTOMER_FR_SALON_NAME);
    case WEBSHOP_STORES.NL:
      return getStorage(keys.CUSTOMER_SALON_NAME);
    case WEBSHOP_STORES.DE:
      return getStorage(keys.CUSTOMER_DE_SALON_NAME);
    case WEBSHOP_STORES.UK:
      return getStorage(keys.CUSTOMER_UK_SALON_NAME);
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      return getStorage(keys.CUSTOMER_BE_SALON_NAME);
    case WEBSHOP_STORES.ES:
      return getStorage(keys.CUSTOMER_ES_SALON_NAME);
    case WEBSHOP_STORES.NO:
      return getStorage(keys.CUSTOMER_NO_SALON_NAME);
    case WEBSHOP_STORES.SE:
      return getStorage(keys.CUSTOMER_SE_SALON_NAME);
    default:
      return '';
  }
};

export const getSelectedSalonNo = locale => {
  switch (locale) {
    case WEBSHOP_STORES.FR:
      return getStorage(keys.SELECTED_FR_SALON_NO);
    case WEBSHOP_STORES.NL:
      return getStorage(keys.SELECTED_SALON_NO);
    case WEBSHOP_STORES.DE:
      return getStorage(keys.SELECTED_DE_SALON_NO);
    case WEBSHOP_STORES.UK:
      return getStorage(keys.SELECTED_UK_SALON_NO);
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.NL_BE:
      return getStorage(keys.SELECTED_BE_SALON_NO);
    case WEBSHOP_STORES.ES:
      return getStorage(keys.SELECTED_ES_SALON_NO);
    case WEBSHOP_STORES.NO:
      return getStorage(keys.SELECTED_NO_SALON_NO);
    case WEBSHOP_STORES.SE:
      return getStorage(keys.SELECTED_SE_SALON_NO);
    default:
      return '';
  }
};

const formatPrice = p => {
  if (!p) return '';
  return parseFloat(p).toFixed(2);
};

export const formatPriceByStoreLocale = ({ locale, price }) => {
  const currencySymbol = getCurrencySymbol(locale);
  switch (locale) {
    case WEBSHOP_STORES.FR:
    case WEBSHOP_STORES.DE:
    case WEBSHOP_STORES.FR_BE:
    case WEBSHOP_STORES.ES:
    case WEBSHOP_STORES.NO:
    case WEBSHOP_STORES.SE:
      return `${formatPrice(price)}${currencySymbol}`;
    default:
      return `${currencySymbol}${formatPrice(price)}`;
  }
};
