import { useState } from 'react';
import PasswordValidator from 'password-validator';
import { useCustomer } from 'hooks';
import fetchJson from 'lib/utils/fetchJson';
import Router from 'next/router';
import useAccountCreatedTracking from 'hooks/tracking/useAccountCreatedTracking';
import { addLocaleToHref } from 'lib/utils/helpers';

export const useRegisterCustomer = locale => {
  const [state, setState] = useState({
    response: null,
    loading: false,
    error: null,
  });

  const { mutateCustomer } = useCustomer({
    redirectTo: addLocaleToHref('/account/dashboard/', locale),
    redirectIfFound: true,
  });

  const schema = new PasswordValidator();

  schema.is().min(8).is().max(100).has().lowercase().has().uppercase();

  const registerCustomer = async (
    email,
    passwordField1,
    passwordField2,
    firstName,
    lastName,
    affiliatedSalonNo,
    selectedSalonNo
  ) => {
    if (!schema.validate(passwordField1)) {
      setState({
        response: null,
        loading: false,
        error: 'Password invalid',
      });
    } else if (passwordField1 !== passwordField2) {
      setState({
        response: null,
        loading: false,
        error: 'Passwords do not match',
      });
    } else if (email && passwordField1 && firstName && lastName) {
      setState({ response: null, loading: true, error: null });

      try {
        await mutateCustomer(
          fetchJson('/api/shopify/account/register/', {
            method: 'POST',
            body: JSON.stringify({
              email,
              password: passwordField1,
              firstName,
              lastName,
              locale,
            }),
          })
        );
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useAccountCreatedTracking({ affiliatedSalonNo, selectedSalonNo, location: { Router } });
        setState({
          response: true,
          loading: false,
          error: null,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('useRegisterCustomer failed: ', error.message);
        setState({
          response: null,
          loading: false,
          error: error?.data,
        });
      }
    } else {
      setState({
        response: null,
        loading: false,
        error: 'Missing customer input',
      });
    }
  };

  return [registerCustomer, state];
};

export default useRegisterCustomer;
