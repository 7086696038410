import { db } from 'lib/firebase';
import { getCountryByLocale } from 'lib/shopify';

const cachedData = {};

const fetchSalons = async locale => {
  const country = getCountryByLocale(locale);
  const salonsResponse = await db.collection('salons').where('country', '==', country).get();
  const fullSalonData = salonsResponse.docs.map(doc => doc.data());
  const salons = fullSalonData.map(s => ({
    salonNo: s?.salonNo || '',
    salonName: s?.salonName || '',
    salonEmail: s?.salonEmail || '',
    parentEmail: s?.parentEmail || '',
    country: s?.country || '',
    city: s?.city || '',
    salonLoyalty: s?.salonLoyalty || false,
    client100: s?.client100 || false,
    position: s?.position || {},
  }));

  return salons;
};

export default async function getSalons(locale) {
  if (!cachedData[locale]?.length) {
    const allSalons = await fetchSalons(locale);
    cachedData[locale] = allSalons;
  }

  return cachedData[locale];
}
