const Logo = ({ className }) => (
  <svg
    version="1.1"
    className={className}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 476.22 148.535"
    style={{ enableBackground: 'new 0 0 476.22 148.535' }}
    xmlSpace="preserve"
  >
    <path
      d="M474.619,26.16c-0.375,1.939-1.486,3.618-3.13,4.727c-1.228,0.83-2.659,1.269-4.138,1.269c-1.536,0-3.014-0.565-4.401-1.681
    l-7.643,5.154c1.587,3.002,2.392,6.164,2.392,9.406c0,11.27-9.169,20.439-20.437,20.439c-11.27,0-20.439-9.169-20.439-20.439
    c0-3.241,0.805-6.402,2.395-9.406l-7.646-5.154c-1.387,1.116-2.865,1.681-4.399,1.681c-1.482,0-2.913-0.439-4.139-1.269
    c-1.64-1.106-2.751-2.788-3.129-4.729c-0.38-1.94,0.023-3.914,1.136-5.556c1.374-2.046,3.671-3.268,6.143-3.268
    c1.482,0,2.914,0.439,4.141,1.269c2.613,1.76,3.742,5.028,2.865,8.109l7.596,5.12c1.195-1.408,2.518-2.619,3.945-3.607l-4.284-8.057
    c-0.359,0.119-0.762,0.24-1.214,0.24c-2.751,0-5.259-1.506-6.546-3.932c-0.931-1.743-1.124-3.749-0.544-5.646
    c0.576-1.891,1.857-3.445,3.607-4.376c1.065-0.568,2.267-0.867,3.474-0.867c2.748,0,5.255,1.508,6.546,3.935
    c0.931,1.748,1.124,3.752,0.544,5.643c-0.315,1.034-0.91,1.979-1.809,2.875l4.256,8.011c1.579-0.616,3.325-1.028,5.211-1.231v-9.156
    c-3.044-0.996-5.12-3.759-5.12-6.912c0-4.085,3.325-7.409,7.411-7.409c4.087,0,7.412,3.323,7.412,7.409
    c0,3.153-2.077,5.915-5.121,6.912v9.156c1.884,0.203,3.63,0.615,5.208,1.231l4.259-8.011c-0.899-0.896-1.493-1.842-1.809-2.877
    c-0.579-1.887-0.386-3.891,0.542-5.643c1.289-2.425,3.797-3.932,6.548-3.932c1.208,0,2.41,0.299,3.475,0.869
    c1.747,0.931,3.028,2.484,3.604,4.377c0.579,1.889,0.388,3.894-0.539,5.644c-1.292,2.426-3.799,3.932-6.547,3.932
    c-0.454,0-0.857-0.121-1.216-0.24l-4.284,8.057c1.427,0.988,2.75,2.199,3.945,3.607l7.593-5.118
    c-0.877-3.082,0.253-6.348,2.869-8.111c1.231-0.83,2.662-1.269,4.137-1.269c2.469,0,4.77,1.221,6.154,3.268v0.001
    C474.595,22.242,474.997,24.215,474.619,26.16z M451.591,13.824c0.223,0.721,0.713,1.316,1.381,1.672
    c1.341,0.718,3.11,0.166,3.827-1.171c0.351-0.667,0.423-1.434,0.203-2.155c-0.221-0.721-0.711-1.315-1.377-1.671
    c-1.371-0.724-3.119-0.162-3.827,1.17C451.445,12.338,451.371,13.104,451.591,13.824z M422.723,11.672
    c-0.491-0.928-1.448-1.505-2.5-1.505c-0.463,0-0.909,0.112-1.324,0.334c-0.666,0.353-1.154,0.947-1.374,1.669
    c-0.223,0.723-0.149,1.486,0.206,2.154c0.708,1.336,2.464,1.892,3.824,1.171c0.669-0.355,1.157-0.947,1.376-1.669
    C423.15,13.1,423.077,12.335,422.723,11.672z M409.954,24.205c-0.145-0.744-0.569-1.384-1.195-1.804
    c-0.466-0.317-1.011-0.484-1.576-0.484c-0.946,0-1.823,0.466-2.35,1.245c-0.422,0.627-0.576,1.38-0.433,2.124
    c0.145,0.744,0.569,1.386,1.193,1.804c1.249,0.846,3.085,0.484,3.934-0.762C409.947,25.701,410.099,24.947,409.954,24.205z
     M437.262,11.578c1.561,0,2.829-1.268,2.829-2.828c0-1.56-1.268-2.831-2.829-2.831c-1.56,0-2.831,1.27-2.831,2.831
    C434.432,10.31,435.702,11.578,437.262,11.578z M452.25,45.036c0-8.263-6.723-14.986-14.988-14.986s-14.988,6.723-14.988,14.986
    c0,8.266,6.723,14.992,14.988,14.992S452.25,53.302,452.25,45.036z M470.121,25.283c0.145-0.74-0.009-1.492-0.432-2.118
    c-0.527-0.781-1.405-1.248-2.351-1.248c-0.562,0-1.106,0.169-1.576,0.484c-0.625,0.42-1.049,1.062-1.194,1.806
    c-0.143,0.742,0.008,1.498,0.43,2.124c0.84,1.242,2.69,1.601,3.924,0.761c0.635-0.424,1.058-1.063,1.198-1.803V25.283z"
    />
    <polygon
      points="50.915,91.7 109.553,145.401 96.741,145.401 44.138,98.103 42.082,100.046 42.082,145.401 32.74,145.401 
    32.74,42.881 42.082,42.881 42.082,88.533 93.878,42.881 106.396,42.881 "
    />
    <polygon
      points="118.847,145.401 174.76,145.401 174.76,136.722 128.189,136.722 128.189,93.159 173.748,93.159 173.748,84.479 
    128.189,84.479 128.189,51.56 174.76,51.56 174.76,42.88 118.847,42.88 "
    />
    <path
      d="M256.198,102.198c0,11.17-0.686,17.585-4.581,23.828c-7.202,11.651-21.215,12.554-25.339,12.554s-18.137-0.904-25.34-12.559
    c-3.894-6.239-4.579-12.653-4.579-23.824V42.88h-9.342v59.318c0,11.563,0.472,20.442,6.581,29.436
    c8.831,12.914,22.648,15.626,32.681,15.626c10.033,0,23.85-2.712,32.684-15.629c6.106-8.991,6.578-17.87,6.578-29.433V42.88h-9.342
    V102.198z"
    />
    <polygon
      points="366.411,127.38 280.873,42.881 277.972,42.881 277.972,145.402 287.314,145.402 287.314,62.391 371.239,145.402 
    375.753,145.402 375.753,42.881 366.411,42.881 "
    />
    <polygon
      points="397.528,93.159 443.086,93.159 443.086,84.479 397.528,84.479 397.528,51.559 411.194,51.559 411.194,42.881 
    388.186,42.881 388.186,145.401 444.098,145.401 444.098,136.723 397.528,136.723 "
    />
    <rect x="0.977" y="1.386" style={{ fill: 'none' }} width="2.835" height="2.835" />
  </svg>
);

export default Logo;
