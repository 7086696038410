import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import handleTouch from 'lib/utils/handleTouch';
import ArrowRight from 'components/shared/arrow-right';
import ArrowLeft from 'components/shared/arrow-left';
import { usePreloadImages } from 'hooks';
import Cta from '../cta/cta';

import styles from './hero.module.scss';

const Hero = ({ data, locale }) => {
  const { slides } = data;
  const carouselRef = useRef(null);
  const [iterationCount, setIterationCount] = useState(0);
  const [preloadedImages, setPreloadedImages] = useState([]);

  useEffect(() => {
    if (!slides) return;

    const imagesUrls = slides.reduce((acc, slide) => {
      if (slide?.media?.image) {
        if (slide?.media?.image?.mobile) {
          acc.push(slide.media.image.mobile.baseUrl);
        }
        if (slide?.media?.image?.desktop) {
          acc.push(slide.media.image.desktop.baseUrl);
        }
      }
      return acc;
    }, []);

    setPreloadedImages(imagesUrls);
  }, [slides]);

  usePreloadImages(preloadedImages);

  const changeSlide = targetSlide => {
    let targetTranslate;
    let newSlideIndex;
    const imageWidth = carouselRef?.current?.offsetWidth || 0;

    if (targetSlide > slides.length - 1) {
      newSlideIndex = 0;
      targetTranslate = 0;
    }

    if (targetSlide < 0) {
      targetTranslate = imageWidth * (slides.length - 1) * -1;
      newSlideIndex = slides.length - 1;
    }

    if (typeof targetTranslate === 'undefined') {
      targetTranslate = imageWidth * targetSlide * -1;
    }

    if (typeof newSlideIndex === 'undefined') {
      newSlideIndex = targetSlide;
    }

    setIterationCount(newSlideIndex);
    if (carouselRef.current) {
      carouselRef.current.style.transform = `translateX(${targetTranslate}px)`;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      changeSlide(iterationCount);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [iterationCount]);

  return (
    <section id="sectionHeroCarousel">
      <div className={styles.buttonWrapper}>
        <div
          role="button"
          tabIndex={0}
          className={styles.rightArrow}
          onClick={() => changeSlide(iterationCount + 1)}
          onKeyDown={() => changeSlide(iterationCount + 1)}
        >
          <ArrowRight />
        </div>
        <div
          role="button"
          tabIndex={0}
          className={styles.leftArrow}
          onClick={() => changeSlide(iterationCount - 1)}
          onKeyDown={() => changeSlide(iterationCount - 1)}
        >
          <ArrowLeft />
        </div>
      </div>
      <div
        className={styles.carouselWrapper}
        onTouchStart={e =>
          handleTouch(e, carouselRef, {
            leftSideCallback: () => changeSlide(iterationCount - 1),
            rightSideCallback: () => changeSlide(iterationCount + 1),
          })
        }
      >
        <div className={styles.carousel} ref={carouselRef}>
          {slides?.map((slide, index) => (
            <div
              key={slide?.name}
              className={classNames(styles.carouselItem, {
                [styles.active]: iterationCount === index,
              })}
            >
              <Cta
                className={styles.ctaSlide}
                data={slide}
                locale={locale}
                firstElement={index === 0}
                isHero
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Hero;
