import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import classNames from 'classnames';
import Image from 'components/shared/image';
import richTextEmbedTypes from 'lib/utils/richTextEmbedtypes';
import { InstagramEmbed } from 'react-social-media-embed';
import styles from './richtext.module.scss';
import VideoPlayer from './video/video';

const DataCookieConsentParent = () => (
  <div data-cookieconsent-parent="cookie-consent-io-cookie-data-table">
    <script
      type="text/javascript"
      id="cookie-consent-io-cookie-data-table"
      dangerouslySetInnerHTML={{
        __html: `
              function showCookieTable(scriptElement, cookieConsent) {
                scriptElement.parentNode.appendChild(cookieConsent.getCookieTable());
              };
              var scriptElement = document.getElementById('cookie-consent-io-cookie-data-table');
              if (typeof window.cookieConsent === 'undefined') {
                document.addEventListener('CookieConsentInitialize', () => {
                  showCookieTable(scriptElement, window.cookieConsent);
                });
              } else {
                showCookieTable(scriptElement, window.cookieConsent);
              }
              `,
      }}
    />
  </div>
);

const RichText = ({ text, links, className, embeddedClasses, isPrivacyPage, imagesResponsive }) => {
  // From Contentful article: https://www.contentful.com/blog/2021/04/14/rendering-linked-assets-entries-in-contentful/
  // create an asset map
  const assetMap = new Map();
  // loop through the assets and add them to the map
  for (let i = 0; i < links?.assets?.block?.length; i += 1) {
    if (links?.assets.block[i]?.sys?.id) {
      assetMap.set(links?.assets.block[i].sys.id, links.assets.block[i]);
    }
  }
  // create an entry map
  const entryMap = new Map();
  // loop through the block linked entries and the inline linked entries and add them to the map
  for (let i = 0; i < links?.entries.block.length; i += 1) {
    if (links.entries.block[i]?.sys?.id) {
      entryMap.set(links.entries.block[i].sys.id, links.entries.block[i]);
    }
  }

  const options = {
    renderMark: {
      [MARKS.ITALIC]: italicText => <i className={styles.italic}>{italicText}</i>,
      [MARKS.BOLD]: boldText => <b className={styles.bold}>{boldText}</b>,
      [MARKS.CODE]: codeText => <sub className={styles.sub}>{codeText}</sub>,
    },
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => <span className={styles.heading2}>{children}</span>,
      [BLOCKS.HEADING_3]: (node, children) => <span className={styles.heading3}>{children}</span>,
      [BLOCKS.HEADING_6]: (node, children) => (
        <p id={children} className={styles.heading2}>
          {children}
        </p>
      ),
      [INLINES.HYPERLINK]: ({ content, data }, children) => {
        if (data.uri?.startsWith('#')) {
          const anchorSplit = data.uri.split('#');
          if (anchorSplit?.length > 1) {
            return (
              <button
                type="button"
                className={styles.anchorLink}
                onClick={() => {
                  const id = anchorSplit[1];
                  const anchorTarget = document.getElementById(id);
                  if (anchorTarget) {
                    window.location.href = `#${id}`; // changing the url
                    anchorTarget.scrollIntoView({
                      behavior: 'smooth',
                      block: id?.startsWith('section') ? 'center' : 'start',
                    }); // scrolling to section
                  }
                }}
              >
                {content[0].value}
              </button>
            );
          }
        }
        if (content[0]?.value?.toLowerCase().includes('instagram embed')) {
          return (
            <div className={styles.instagramEmbed}>
              <InstagramEmbed url={data.uri.trim()} />
            </div>
          );
        }
        return (
          <u className={styles.hyperlink}>
            <a
              href={data.uri.endsWith('.pdf') ? data.uri : data.uri.match('(https:|http:)*(.*)')[2]}
              target={
                data.uri.endsWith('.pdf') ||
                (!data.uri.startsWith('https://www.keune.com') && !data.uri.includes('javascript')) // checking javascript for privacy and policy page. See anchor in Contentful
                  ? '_blank'
                  : '_self'
              }
              rel="noreferrer"
            >
              {children}
            </a>
          </u>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: { sys },
        },
      }) => {
        const asset = assetMap.get(sys.id);

        if (asset?.contentType === 'image/jpeg' || asset?.contentType === 'image/png') {
          return (
            <Image
              className={embeddedClasses[richTextEmbedTypes.EMBEDDED_ASSETS.IMAGE]}
              alt={asset.title}
              src={asset}
              layout={imagesResponsive ? 'responsive' : 'fill'}
              loading="lazy"
            />
          );
        }

        return null;
      },
      [BLOCKS.EMBEDDED_ENTRY]: ({
        data: {
          target: { sys },
        },
      }) => {
        const entry = entryMap.get(sys.id);
        // eslint-disable-next-line no-underscore-dangle
        const type = entry?.__typename || '';

        if (type === 'ComponentMedia') {
          if (entry.type === 'Video') {
            const { video, thumbnailImage } = entry;

            return (
              <VideoPlayer
                source={video}
                thumbnail={thumbnailImage}
                className={
                  embeddedClasses[richTextEmbedTypes.EMBEDDED_ENTRIES.COMPONENT_MEDIA.VIDEO]
                }
              />
            );
          }
          return (
            <Image
              src={entry.image}
              layout={imagesResponsive ? 'responsive' : 'fill'}
              loading="lazy"
              alt={entry.image.title}
            />
          );
        }

        return null;
      },
    },
  };
  return (
    <div className={classNames(styles.richText, className)}>
      {documentToReactComponents(text, options)}
      {isPrivacyPage && <DataCookieConsentParent />}
    </div>
  );
};

export default RichText;
