import classNames from 'classnames';
import styles from './progressBar.module.scss';

const ProgressBar = ({ current = 0, max = 100, className, color }) => {
  const calcReadyPercentage = () => {
    const readyPercentage = (parseFloat(current) / parseFloat(max)) * 100;
    if (readyPercentage > 100) return 100;
    return readyPercentage;
  };

  return (
    <div className={classNames(styles.container, className)}>
      <div
        className={styles.progress}
        style={{
          width: `${calcReadyPercentage()}%`,
          backgroundColor: color,
        }}
      />
    </div>
  );
};

export default ProgressBar;
