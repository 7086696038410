import { useSettings } from 'contexts/SettingContext';
import classNames from 'classnames';
import {
  setStorageSelectedSalonNo,
  setStorageCustomerSalonName,
  getStorageCustomerSalonName,
  setStorageCustomerSalonNo,
} from 'lib/shopify';
import { useUI } from 'hooks';
import styles from './salonsList.module.scss';

export default function SalonsList({ globalModules, locale, customClasses, showNameInTitle }) {
  // Check if there's a salon in localstorage already to preselect in the selector
  const preferredSalonName = getStorageCustomerSalonName(locale);
  const { salons, currentSalon, setCurrentSalon, salonManuallyDeleted, setSalonManuallyDeleted } =
    useSettings();
  const { openSalonSelector } = useUI();
  const { checkout, salonselector } = globalModules;

  const removeSalon = () => {
    /** Reset affilation and selected salon numbers and name */
    setStorageSelectedSalonNo(locale, '');
    setStorageCustomerSalonName(locale, '');
    setStorageCustomerSalonNo(locale, '');
    setCurrentSalon(null);
    setSalonManuallyDeleted(true);
  };

  /**
   * Displays the information the users current selection
   * @returns A JSX element
   */
  const SelectionInformation = () => {
    const decideTitleText = () => {
      if (!currentSalon && salons) return salonselector.selectAmbassadorSalon.text;
      if (currentSalon && currentSalon.salonLoyalty) return checkout.yourAmbassadorSalon.text;
      return checkout.yourSalon.text;
    };

    const decideInformationText = () => {
      if (!preferredSalonName && salons) {
        return salonselector.noSalonSelected.text;
      }
      return (
        <>
          <u>{preferredSalonName}</u>
          {` `}
          {currentSalon?.salonLoyalty
            ? checkout.ambassadorSalonSelectedText.text
            : checkout.salonSelectedText.text}
        </>
      );
    };

    return (
      <>
        <p className={classNames(styles.selectedTitle, customClasses?.title)}>
          {decideTitleText()}{' '}
          {showNameInTitle && <u style={{ whiteSpace: 'nowrap' }}>{preferredSalonName || ''}</u>}
        </p>
        <p className={classNames(styles.selectionInformation, customClasses?.text)}>
          {decideInformationText()}
        </p>
      </>
    );
  };

  const decideButtonText = () => {
    if (currentSalon) {
      return currentSalon?.salonLoyalty
        ? checkout.changeSalon.text
        : checkout.changeToAmbassadorSalon.text;
    }
    return salonselector.chooseYourSalon.text;
  };

  /**
   * Renders the buttons to help select/deselect a salon
   */
  const SelectionButtons = () => {
    const showButtons = () => {
      if (salons && !salonManuallyDeleted) {
        return (
          <>
            <button
              type="button"
              onClick={openSalonSelector}
              className={classNames(styles.changeSalonButton, customClasses?.buttonSelectSalon)}
            >
              {decideButtonText()}
            </button>
            <button
              type="button"
              onClick={removeSalon}
              className={classNames(styles.removeSalonButton, customClasses?.buttonDeleteSalon)}
            >
              {checkout.wontChooseSalon.text}
            </button>
          </>
        );
      }
      return (
        <>
          <button
            type="button"
            onClick={openSalonSelector}
            className={classNames(styles.wantToChangeButton, customClasses?.buttonStillSelectSalon)}
          >
            {salonselector.wantToSelect.text}
          </button>
        </>
      );
    };

    return (
      <>
        <div className={styles.salonSelectorButtons}>{showButtons()}</div>
      </>
    );
  };

  return (
    <>
      <div className={classNames(styles.findSalon, customClasses?.container)}>
        <SelectionInformation />
        <SelectionButtons />
      </div>
    </>
  );
}
