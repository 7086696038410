import classNames from 'classnames';
import { addLocaleToHref } from 'lib/utils/helpers';
import { useRouter } from 'next/router';
import SiteLink from '../sitelink/sitelink';

import styles from './button.module.scss';

const Button = ({ button, locale, className, onClick }) => {
  const router = useRouter();

  const { link, externalLink, title, pageReload, style, color, customColor, customTextColor } =
    button || {};
  // const isWhiteWithBlackText = color === 'White with black text';
  const isBlackWithWhiteText = color === 'Black with white text';

  const getTextColor = () => {
    if (customTextColor) return customTextColor;
    if (isBlackWithWhiteText) return '#f9f9f9';
    return '#3c3c3b';
  };

  const getBackgroundColor = () => {
    if (customColor) return customColor;
    if (isBlackWithWhiteText) return '#3c3c3b';
    return '#f9f9f9';
  };

  if (onClick) {
    return (
      <button
        type="button"
        onClick={onClick}
        style={{
          '--button-style': style,
          '--button-color': getTextColor(),
          '--button-background-color': getBackgroundColor(),
        }}
        className={classNames(className, styles.button, {
          [styles.outlined]: style === 'Outlined',
          [styles.solid]: style === 'Solid',
        })}
      >
        {title}
      </button>
    );
  }

  return (
    <SiteLink
      locale={locale}
      href={addLocaleToHref(link?.slug, locale, router)}
      external={externalLink}
      reloaded={pageReload}
      style={{
        '--button-style': style,
        '--button-color': getTextColor(),
        '--button-background-color': getBackgroundColor(),
      }}
      className={classNames(className, styles.button, {
        [styles.outlined]: style === 'Outlined',
        [styles.solid]: style === 'Solid',
      })}
    >
      {title}
    </SiteLink>
  );
};

export default Button;
