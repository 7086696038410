import { getCurrencyCode } from 'lib/shopify';

const useQuickAddToCartTracking = ({ product, list, setSaveItemSelectionList, locale }) => {
  try {
    const productType = product.productSpecs?.find(
      ({ type }) => type === 'Producttype' || type === 'Type de produit' || type === 'Product type'
    );
    const productCategory = product.productSpecs?.find(
      ({ type }) =>
        type === 'Productcategorie' ||
        type === 'Catégorie de produit' ||
        type === 'Product Category'
    );
    const productSubCategory = product.productSpecs?.find(
      ({ type }) => type === 'Subcategorie' || type === 'Sous-catégorie' || type === 'Sub Category'
    );

    const ecommerce = {
      currency: getCurrencyCode(locale),
      items: [
        {
          item_name: product?.name,
          item_id: String(product?.id),
          price: product?.price,
          item_brand: 'Keune',
          item_category: productType?.value,
          item_category2: productCategory?.value,
          ...(productSubCategory?.value && { item_category3: productSubCategory?.value }),
          item_list_name: list,
          index: product.index,
          quantity: 1,
        },
      ],
    };

    if (typeof window !== 'undefined' && window.dataLayer && product) {
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce,
      });
      setSaveItemSelectionList({
        itemListName: ecommerce.items[0]?.item_list_name,
        itemIndex: ecommerce.items[0]?.index,
        productId: ecommerce.items[0]?.item_id,
      });
    } else {
      setTimeout(() => {
        if (typeof window !== 'undefined' && window.dataLayer && product) {
          window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce,
          });
          setSaveItemSelectionList({
            itemListName: ecommerce.items[0]?.item_list_name,
            itemIndex: ecommerce.items[0]?.index,
            productId: ecommerce.items[0]?.item_id,
          });
        }
      }, [process.env.GTM_DELAY]);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('There was an error sending data to Google Tag Manager: ', error);
  }
};

export default useQuickAddToCartTracking;
