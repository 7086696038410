import { FILTER_ACTION_TYPES } from 'hooks/filterGlobalState/filterReducer';
import { FilterContext } from 'hooks/filterGlobalState/useFilterGlobalState';
import { useReducer, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { set } from 'lib/utils/sessionStorage';

const initialState = {
  changed: false,
  filters: [],
  conditions: {},
  sortOption: '',
};

function reducer(state, action) {
  const newState = { ...state };
  switch (action.type) {
    case 'ADD_FILTER': {
      if (
        newState.filters.find(
          filter => filter.id === action.payload.id && filter.value === action.payload.value
        ) === undefined
      )
        newState.filters.push(action.payload);
      newState.changed = true;
      break;
    }

    case 'REMOVE_FILTER': {
      const index = newState.filters.findIndex(
        filter => filter.id === action.payload.id && filter.value === action.payload.value
      );
      newState.filters.splice(index, 1);
      newState.changed = true;
      break;
    }

    case 'SET_FILTERS': {
      const filtersCopy = [...action.payload];
      newState.filters = filtersCopy;
      newState.changed = true;
      break;
    }

    case 'CLEAR_FILTERS': {
      newState.filters.length = 0;
      newState.changed = true;
      break;
    }

    case 'RESET_FILTERS': {
      newState.filters.length = 0;
      newState.changed = false;
      break;
    }

    case 'SET_CONDITIONS': {
      newState.conditions = action.payload;
      break;
    }

    case 'SET_SORT_OPTION': {
      newState.sortOption = action.payload;
      newState.changed = true;
      break;
    }

    default:
      throw new Error('The type of action is not recognized.');
  }
  return newState;
}

// Grouping the filters by filter type
const groupFilters = state => {
  const groupedFilters = {};
  state.filters.forEach(({ id, value }) => {
    if (!groupedFilters[id]) groupedFilters[id] = [];
    groupedFilters[id].push(value);
  });
  return groupedFilters;
};

const checkProduct = (product, key, values, condition) => {
  const { productSpecs } = product;
  const filteredSpecs = productSpecs.filter(spec => spec.type === key); // filter specs by key

  if (condition === 'OR') {
    return values.some(value => filteredSpecs.some(spec => spec.value === value)); // check if any of the values is in the filtered specs (condition OR)
  }
  return values.every(value => filteredSpecs.some(spec => spec.value === value)); // check if all of the values are in the filtered specs (condition AND)
};

const applyFilterGroup = (array, key, values, condition) => {
  const filteredProds = array.filter(product => {
    const productType = product.type;
    let result = false;

    if (productType === 'PageProduct') {
      result = checkProduct(product, key, values, condition);
    }

    if (productType === 'PageProductBundle') {
      result = product.products.some(bundleProduct =>
        checkProduct(bundleProduct, key, values, condition)
      );
    }

    return result;
  });

  return filteredProds;
};

const filterProducts = (array, state, onlyCondition) => {
  let filteredProducts = [...array];
  const groupedFilters = groupFilters(state);

  Object.entries(groupedFilters).forEach(([key, values]) => {
    const condition = state.conditions[key];
    if (onlyCondition && condition !== onlyCondition) return;
    filteredProducts = applyFilterGroup(filteredProducts, key, values, condition);
  });

  return filteredProducts;
};

const getProductPrice = product => {
  let price = 0;

  if (product.type === 'PageProduct') {
    price = parseFloat(product.shopifyData.variants[0].price);
  }

  if (product.type === 'PageProductBundle') {
    price = product.products.reduce(
      (prev, prod) => prev + parseFloat(prod.shopifyData.variants[0].price),
      0
    );
  }

  return price;
};

const sortProducts = (array, sortOption) => {
  const sortedProducts = [...array];

  switch (sortOption) {
    case 'sortByAlphabetAscending':
      sortedProducts.sort((a, b) => b.title.localeCompare(a.title));
      break;
    case 'sortByAlphabetDescending':
      sortedProducts.sort((a, b) => a.title.localeCompare(b.title));
      break;
    case 'sortByPriceAscending':
      sortedProducts.sort((a, b) => getProductPrice(a) - getProductPrice(b));
      break;
    case 'sortByPriceDescending':
      sortedProducts.sort((a, b) => getProductPrice(b) - getProductPrice(a));
      break;
    case 'sortByHighestRated':
      sortedProducts.sort((a, b) => b.rating?.averageRating - a.rating?.averageRating);
      break;
    case 'sortByPopularity':
      sortedProducts.sort((a, b) => b.soldQuantity - a.soldQuantity);
      break;
    default:
      break;
  }
  return sortedProducts;
};

export const useFilter = products => {
  const router = useRouter();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [filterState, updateFilterState] = useContext(FilterContext);

  const setFiltersToUrl = array => {
    const groupedFilters = {};
    const newQuery = { slug: router.query.slug, sortOption: router.query.sortOption };

    if (array && array.length) {
      array.forEach(({ id, value }) => {
        if (!groupedFilters[id]) groupedFilters[id] = [];
        groupedFilters[id].push(value);
      });
      Object.keys(groupedFilters).forEach(key => {
        if (groupedFilters[key].length) {
          newQuery[key] = groupedFilters[key].join(',');
        }
      });
    }

    router.replace(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      null,
      { scroll: false, shallow: true }
    );
  };

  useEffect(() => {
    if (!state.changed) {
      dispatch({ type: 'RESET_FILTERS' });
      updateFilterState({
        type: FILTER_ACTION_TYPES.FILTERED_PRODUCTS.SET,
        value: sortProducts(products, state.sortOption),
      });
    }
  }, [products]);

  useEffect(() => {
    if (state.changed) {
      setFiltersToUrl(state.filters);
      updateFilterState({
        type: FILTER_ACTION_TYPES.FILTERED_PRODUCTS.SET,
        value: filterProducts(sortProducts(products, state.sortOption), state),
      });
    }
  }, [state]);

  useEffect(() => {
    if (state.sortOption) {
      router.replace(
        {
          pathname: router.pathname,
          query: { ...router.query, sortOption: state.sortOption },
        },
        null,
        { scroll: false, shallow: true }
      );
    }
  }, [state.sortOption, router.query.slug]);

  // METHODS

  const addFilter = filter => {
    dispatch({ type: 'ADD_FILTER', payload: filter });
    updateFilterState({ type: FILTER_ACTION_TYPES.SELECTED_FILTER.ADD, value: filter });
  };

  const removeFilter = filter => {
    dispatch({ type: 'REMOVE_FILTER', payload: filter });
    updateFilterState({ type: FILTER_ACTION_TYPES.SELECTED_FILTER.REMOVE, value: filter });
  };

  const addFilters = filters => {
    for (let i = 0; i < filters.length; i += 1) {
      addFilter(filters[i], true);
    }
  };

  const setFilters = filters => {
    dispatch({ type: 'SET_FILTERS', payload: filters });
    updateFilterState({ type: FILTER_ACTION_TYPES.SELECTED_FILTER.SET, value: filters });
  };

  const clearFilters = () => {
    dispatch({ type: 'CLEAR_FILTERS' });
    updateFilterState({ type: FILTER_ACTION_TYPES.SELECTED_FILTER.CLEAR });
  };

  const resetFilters = () => {
    dispatch({ type: 'RESET_FILTERS' });
    updateFilterState({ type: FILTER_ACTION_TYPES.SELECTED_FILTER.CLEAR });
  };

  const setConditions = allFilters => {
    const newConditions = {};
    Object.entries(allFilters).forEach(([key, value]) => {
      newConditions[key] = value.condition || 'AND';
    });
    dispatch({ type: 'SET_CONDITIONS', payload: newConditions });
  };

  const setSortOption = option => {
    dispatch({ type: 'SET_SORT_OPTION', payload: option });
  };

  return {
    state,
    filterState,
    updateFilterState,
    addFilter,
    removeFilter,
    addFilters,
    setFilters,
    clearFilters,
    resetFilters,
    setConditions,
    setSortOption,
  };
};

export default useFilter;
