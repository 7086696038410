/**
 * A function that calculates the amount of screen space that has been dragged on the element.
 * Performs the passed callbacks, depending on the direction of drag
 * @param {*} e An event to pass if additional information is needed
 * @param {*} ref the current reference received from 'useRef'
 * @param {*} callbacks an object consisting of callbacks, structured like this: { leftSideCallback, rightSideCallback }
 */
const handleDrag = (e, ref, callbacks) => {
  const start = {
    time: new Date().getTime(),
    x: e.clientX,
    y: e.clientY,
  };
  const end = {
    time: null,
    x: null,
    y: null,
  };
  const thresholdTime = 10;
  const thresholdDistance = 5;

  let tracking = true;

  const mouseMove = ev => {
    if (!tracking) return;
    end.x = ev.clientX;
    end.y = ev.clientY;

    const now = new Date().getTime();
    const delta = {
      time: now - start.time,
      x: end.x - start.x,
      y: end.y - start.y,
    };
    if (delta.time > thresholdTime) {
      // Check type of gesture that has been performed
      if (delta.x > thresholdDistance) callbacks.leftSideCallback(ev, delta);
      else if (-delta.x > thresholdDistance) callbacks.rightSideCallback(ev, delta);
    }
  };

  const mouseUp = () => {
    tracking = false;
  };

  ref.current?.addEventListener('mousemove', ev => mouseMove(ev));
  ref.current?.addEventListener('mouseup', mouseEvent => mouseUp(mouseEvent));
  ref.current?.addEventListener('mouseleave', mouseEvent => mouseUp(mouseEvent));
};

export default handleDrag;
