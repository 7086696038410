/**
 * This is a list of all embed types that are supported in Richtext renderer. When passing an embeddedClasses property
 * to Richtext, use these properties to correctly match every class.
 */

const richTextEmbedTypes = {
  EMBEDDED_ASSETS: {
    IMAGE: 'image',
  },
  EMBEDDED_ENTRIES: {
    COMPONENT_MEDIA: {
      VIDEO: 'component-media-video',
      IMAGE: 'component-media-image',
    },
  },
};

export default richTextEmbedTypes;
