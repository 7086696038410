const ArrowLeft = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 18L8 12L14 6" stroke="#3C3C3B" strokeWidth="1.2" strokeLinecap="square" />
  </svg>
);

export default ArrowLeft;
