const useVisitorTypeTracking = ({ visitorType, salonNo, location }) => {
  try {
    if (location.action) {
      if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({ visitorType, salonNo });
      }
    } else {
      setTimeout(() => {
        if (typeof window !== 'undefined' && window.dataLayer) {
          window.dataLayer.push({ visitorType, salonNo });
        }
      }, [process.env.GTM_DELAY]);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('There was en error sending data to Google Tag Manager', error);
  }
};

export default useVisitorTypeTracking;
