import Link from 'next/link';

const SiteLink = ({
  href,
  className,
  external,
  reloaded = false,
  onClick,
  children,
  onMouseEnter,
  draggable,
  style,
  locale,
  prefetch = false,
  ariaLabel,
}) => {
  const clearSavedScrollPosition = () => {
    const slug = href?.split('/').pop();
    sessionStorage.removeItem(`scrollPosition-${slug}`);
  };

  const handleLinkClick = () => {
    // eslint-disable-next-line no-unused-expressions
    onClick && onClick();
    clearSavedScrollPosition();
  };

  const renderButton = () => {
    if (external) {
      const hrefSplit = external.split('#');
      if (hrefSplit?.length > 1 && hrefSplit[0] === '') {
        return (
          <button
            type="button"
            className={className}
            style={style}
            onClick={() => {
              const id = hrefSplit[1];
              const anchorTarget = document.getElementById(id);

              if (anchorTarget) {
                window.location.href = `#${id}`; // changing the url
                // anchorTarget.scrollIntoView({
                //   behavior: 'smooth',
                //   block: 'center',
                // }); // scrolling to section
              }
            }}
          >
            {children}
          </button>
        );
      }
      return (
        <a
          draggable={draggable}
          href={external}
          className={className}
          onMouseEnter={onMouseEnter}
          onClick={handleLinkClick}
          target={reloaded ? '_self' : '_blank'}
          rel="noreferrer"
          style={style}
        >
          {children}
        </a>
      );
    }

    // This has been added to force page reload for scripts like Bookeo
    if (reloaded) {
      const trueHref =
        /* eslint-disable no-nested-ternary */
        href === '/'
          ? '/'
          : href?.slice(0, 1) === '/' && href?.slice(-1) === '/'
          ? href
          : href?.slice(0, 1) === '/'
          ? `${href}/`
          : href?.slice(-1) === '/'
          ? `/${href}`
          : `/${locale}/${href}/`; // <----- result
      /* eslint-enable */

      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          role="button"
          tabIndex={0}
          draggable={draggable}
          className={className}
          onMouseEnter={onMouseEnter}
          style={style}
          onClick={() => {
            window.location.pathname = trueHref;
            handleLinkClick();
          }}
          onKeyDown={() => {
            window.location.pathname = trueHref;
            handleLinkClick();
          }}
        >
          {children}
        </a>
      );
    }
    // removing '/en' because it is the default locale
    const hrefWithoutEn = href?.replace('/en/', '');

    return (
      <Link
        href={
          /* eslint-disable no-nested-ternary */
          hrefWithoutEn === '/'
            ? '/'
            : hrefWithoutEn?.slice(0, 1) === '/' && hrefWithoutEn?.slice(-1) === '/'
            ? hrefWithoutEn
            : hrefWithoutEn?.slice(0, 1) === '/'
            ? `${hrefWithoutEn}`
            : hrefWithoutEn?.slice(-1) === '/'
            ? `/${hrefWithoutEn}`
            : locale === undefined
            ? `/${hrefWithoutEn}`
            : `/${locale}/${hrefWithoutEn}` // <----- result
          /* eslint-enable */
        }
        prefetch={prefetch}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={className}
          onClick={handleLinkClick}
          onMouseEnter={onMouseEnter}
          onKeyDown={handleLinkClick}
          style={style}
          role="button"
          tabIndex={0}
          draggable={draggable}
          aria-label={ariaLabel}
        >
          {children}
        </a>
      </Link>
    );
  };

  return renderButton();
};

export default SiteLink;
