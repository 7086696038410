import Image from 'components/shared/image';
import SiteLink from 'components/shared/sitelink';
import cn from 'classnames';
import useProductRemoveFromCartTracking from 'hooks/tracking/useProductRemoveFromCartTracking';
import useProductAddToCartTracking from 'hooks/tracking/useProductAddToCartTracking';
import { useSettings } from 'contexts/SettingContext';
import { addLocaleToHref } from 'lib/utils/helpers';
import {
  useUpdateItemQuantity,
  useUI,
  useWishlist,
  useLocalWishlist,
  useAddItemsToCart,
} from 'hooks';
import { useRouter } from 'next/router';
import { parseShopifyGid, getShopifyGid } from 'lib/shopify/shopifyGid';
import { formatPriceByStoreLocale } from 'lib/shopify';
import { useState, useEffect } from 'react';
import { mutate } from 'swr';
import UnitPrice from 'components/shared/unit-price/unitPrice';
import ItemCounter from '../../../pages/product/itemCounter';
import { useIsMobile } from '../../../../hooks/utils/useIsMobile';
import styles from './lineItem.module.scss';

const LineItem = ({
  locale,
  lineItem,
  index,
  contentfulData,
  cart,
  globalModules,
  isCartPopup,
}) => {
  const { addToWishlist } = useWishlist();
  const { localWishlist } = useLocalWishlist();
  const addItems = useAddItemsToCart();

  useEffect(() => {
    // if lineitem fas no merchandise (became draft in shopify), revalidate swr cart
    if (!lineItem.merchandise) {
      mutate('/api/shopify/checkout/request/');
    }
  }, [lineItem.merchandise]);

  const router = useRouter();
  const updateItemQuantity = useUpdateItemQuantity();
  const isMobile = useIsMobile();
  const { removeProduct, disableFreeItem, trackedItemsSelectList } = useSettings();
  const outOfStock = lineItem?.merchandise?.product?.tags?.includes('out-of-stock');
  const slug = contentfulData?.slug;
  const { closeCartPopup, openConfirmWishlistPopup } = useUI();
  const [showOverlay, setShowOverlay] = useState(false);

  const bundleBuilderAttributes = ['_bundle_discount', '_bundle_id', '_bundle_message'];

  const createTrackingPayload = (item, productSelectListObj, isCompletelyRemoved = false) => ({
    name: item.title,
    id: parseShopifyGid(item.merchandise?.product.id),
    category: item.merchandise?.product.productType,
    price: item.merchandise?.priceV2.amount,
    quantity: isCompletelyRemoved ? item.quantity : 1,
    productSpecsCollection: contentfulData?.productSpecsCollection?.items || [],
    itemListName: productSelectListObj?.itemListName,
    currencyCode: cart?.currencyCode,
  });

  const {
    freeItem,
    amountOfProductsToGetFreeItem,
    promoCollectionName,
    shopifyTagsToExcludeFromPromo,
  } = globalModules?.secondpartsettings || {};

  const { freeitem: freeItemSettings, lineitem: lineitemModules } = globalModules || {};

  const relatedProducts = lineItem?.merchandise?.product?.relatedProducts?.value;
  const hasFreeItemTag = lineItem?.merchandise?.product?.tags?.includes('free-item');
  const hasFreeItemCustomAttribute = lineItem?.customAttributes?.find(
    attr => attr.key === '_freeItem'
  );

  const lineItemIsFreeItem =
    lineItem?.merchandise?.product?.id ===
    getShopifyGid('Product', freeItem?.product?.shopifyData?.variants[0]?.product_id);

  const getNewQuantity = (id, difference) => {
    if (!id) return 0;
    const currentQuantity = cart.lines?.edges?.reduce((acc, curr) => {
      if (curr.node.id === id) {
        return acc + curr.node.quantity;
      }
      return acc;
    }, 0);
    let newQuantity = currentQuantity - difference;
    if (newQuantity < 0) newQuantity = 0;

    return newQuantity;
  };

  const getFreeItemsQuantity = mainProductQuantityDifference => {
    if (!relatedProducts) return [];
    const parsedRelatedProducts = JSON.parse(relatedProducts);

    const newFreeItems = parsedRelatedProducts.map(id => ({
      variantId: id,
      quantity: getNewQuantity(id, mainProductQuantityDifference),
    }));

    return newFreeItems;
  };

  const getItemsWithSameBundleId = () => {
    const bundleId = lineItem?.attributes?.find(attr => attr.key === '_bundle_id')?.value;
    if (!bundleId) return [];
    return cart.lines?.edges?.filter(
      ({ node }) => node.attributes?.find(attr => attr.key === '_bundle_id')?.value === bundleId
    );
  };

  const handleRemoveProduct = async isKeyDown => {
    // If we are removing the free item from cart,
    // we disable it from being automatically added
    if (lineItemIsFreeItem) {
      disableFreeItem();
    }

    let items = [
      {
        id: lineItem.id,
        variantId: lineItem.merchandise?.id,
        quantity: 0,
      },
      ...getFreeItemsQuantity(lineItem.quantity),
    ];

    const bundleItems = getItemsWithSameBundleId();

    if (bundleItems.length > 1) {
      const itemsToRemoveAttributes = bundleItems
        .filter(({ node }) => node.id !== lineItem.id)
        .map(({ node }) => ({
          id: node.id,
          variantId: node.merchandise?.id,
          quantity: node.quantity,
          customAttributes: node.attributes.filter(
            attr => !bundleBuilderAttributes.includes(attr.key)
          ),
        }));

      items = items.concat(itemsToRemoveAttributes);
    }
    updateItemQuantity(items, freeItem?.product?.shopifyData, {
      amountOfProductsToGetFreeItem,
      promoCollectionName,
      shopifyTagsToExcludeFromPromo,
    });

    if (!lineItemIsFreeItem && lineItem?.merchandise) {
      const productSelectListObj = trackedItemsSelectList?.find(
        p => p?.productId === parseShopifyGid(lineItem?.merchandise?.product.id)
      );
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useProductRemoveFromCartTracking({
        product: createTrackingPayload(lineItem, productSelectListObj, true),
      });
    }

    if (!isKeyDown) {
      await removeProduct(lineItem?.merchandise?.product_id);
    }
  };

  const handleUpdateQuantity = async value => {
    const items = [
      {
        id: lineItem.id,
        variantId: lineItem.merchandise?.id,
        customAttributes: lineItem.attributes,
        quantity: getNewQuantity(lineItem?.id, lineItem.quantity - value),
      },
      ...getFreeItemsQuantity(lineItem.quantity - value),
    ];

    if (value > lineItem.quantity > 0) {
      items[0].quantity = 1;
      items[0].customAttributes = lineItem.attributes.filter(
        attr => !bundleBuilderAttributes.includes(attr.key)
      );
      await addItems(items, freeItem?.product?.shopifyData, {
        amountOfProductsToGetFreeItem,
        promoCollectionName,
        shopifyTagsToExcludeFromPromo,
      });
    } else {
      const bundleItems = getItemsWithSameBundleId();

      if (bundleItems.length > 1) {
        const newQuantity = value;
        const newItems = bundleItems.map(({ node }) => ({
          id: node.id,
          variantId: node.merchandise?.id,
          customAttributes: node.attributes,
          quantity: newQuantity,
        }));

        await updateItemQuantity(newItems, freeItem?.product?.shopifyData, {
          amountOfProductsToGetFreeItem,
          promoCollectionName,
          shopifyTagsToExcludeFromPromo,
        });

        const itemsToAddBack = bundleItems
          .filter(({ node }) => node.id !== lineItem.id)
          .map(({ node }) => ({
            id: node.id,
            variantId: node.merchandise?.id,
            quantity: 1,
            customAttributes: node.attributes.filter(
              attr => !bundleBuilderAttributes.includes(attr.key)
            ),
          }));

        await addItems(itemsToAddBack, freeItem?.product?.shopifyData, {
          amountOfProductsToGetFreeItem,
          promoCollectionName,
          shopifyTagsToExcludeFromPromo,
        });
      } else {
        await updateItemQuantity(items, freeItem?.product?.shopifyData, {
          amountOfProductsToGetFreeItem,
          promoCollectionName,
          shopifyTagsToExcludeFromPromo,
        });
      }
    }

    const productSelectListObj = trackedItemsSelectList?.find(
      p => p?.productId === parseShopifyGid(lineItem?.merchandise?.product?.id)
    );
    if (value > lineItem.quantity) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useProductAddToCartTracking({
        product: createTrackingPayload(lineItem, productSelectListObj),
      });
    } else if (value < lineItem.quantity) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useProductRemoveFromCartTracking({
        product: createTrackingPayload(lineItem, productSelectListObj),
      });
    }
  };

  const totalDiscount = lineItem.discountAllocations.reduce(
    (acc, curr) => acc + Number(curr.discountedAmount.amount),
    0
  );

  const calcSubscribtionPrice = productPrice => {
    const priceAdjustments =
      lineItem.sellingPlanAllocation?.sellingPlan?.priceAdjustments?.[0]?.adjustmentValue;
    if (!priceAdjustments) return null;

    if (priceAdjustments.price?.amount) {
      return priceAdjustments.price?.amount;
    }

    if (priceAdjustments.adjustmentPercentage) {
      return (productPrice * (100 - priceAdjustments.adjustmentPercentage)) / 100;
    }

    if (priceAdjustments.adjustmentAmount?.amount) {
      return productPrice - priceAdjustments.adjustmentAmount.amount;
    }

    return null;
  };

  const Prices = () => {
    const itemPrice = (Number(lineItem.merchandise?.priceV2?.amount) * lineItem.quantity).toFixed(
      2
    );
    const subscriptionPrice = calcSubscribtionPrice(itemPrice);
    const finalPrice = itemPrice;

    const discountedPrice = (() => {
      if (totalDiscount) {
        return Number(finalPrice - totalDiscount).toFixed(2);
      }
      if (subscriptionPrice) return subscriptionPrice;

      return null;
    })();

    const getPriceText = price => {
      if (price === '0.00' && freeItemSettings?.priceFreeText?.text) {
        return (
          <span style={{ color: freeItemSettings?.priceFreeTextColor?.text || '' }}>
            {freeItemSettings?.priceFreeText?.text}
          </span>
        );
      }
      return formatPriceByStoreLocale({
        locale,
        price,
      });
    };

    return (
      <span className={styles.price}>
        <>
          <span className={cn({ [styles.lineThrough]: discountedPrice })}>
            {getPriceText(finalPrice)}
          </span>
          {discountedPrice && (
            <span className={styles.discount}>{getPriceText(discountedPrice)}</span>
          )}
        </>
        <UnitPrice
          unitPrice={{
            ...(lineItem.merchandise?.unitPrice || {}),
            ...(lineItem.merchandise?.unitPriceMeasurement || {}),
          }}
          className={styles.unitPrice}
          locale={locale}
          size={contentfulData?.size}
          globalModules={globalModules}
        />
      </span>
    );
  };

  const LineItemImage = () => {
    const img = (
      <div className={cn(styles.itemImage, { [styles.smallItemImage]: isCartPopup || isMobile })}>
        <Image src={lineItem.merchandise?.image} layout="fill" />
      </div>
    );

    if (!slug) {
      return img;
    }
    return (
      <SiteLink
        locale={locale}
        href={addLocaleToHref(slug, locale, router)}
        onClick={closeCartPopup}
      >
        {img}
      </SiteLink>
    );
  };

  const RemoveButton = () => {
    if (hasFreeItemTag || lineItemIsFreeItem) {
      return null;
    }
    return (
      <span
        role="button"
        tabIndex={0}
        className={styles.actionButton}
        onClick={() => setShowOverlay(true)}
        onKeyDown={() => setShowOverlay(true)}
      >
        {lineitemModules?.removeText?.text}
      </span>
    );
  };

  const SaveForLaterButton = () => {
    if (hasFreeItemTag || lineItemIsFreeItem) {
      return null;
    }

    // if an item is already in wisglist, do not show the button
    if (localWishlist.includes(lineItem?.merchandise?.product?.id)) {
      return null;
    }

    const handleSaveForLater = async () => {
      const GIDs = [lineItem?.merchandise?.product?.id];
      await addToWishlist(GIDs);
      openConfirmWishlistPopup(lineItem);
      handleRemoveProduct();
    };

    return (
      <span
        role="button"
        tabIndex={0}
        className={styles.actionButton}
        onClick={() => handleSaveForLater()}
        onKeyDown={() => handleSaveForLater()}
      >
        {lineitemModules?.saveForLaterText?.text}
      </span>
    );
  };

  const ConfirmOverlay = () => {
    if (!showOverlay) return null;
    return (
      <div className={styles.confirmOverlay}>
        <span className={styles.overlayText}>{lineitemModules?.confirmRemoveMessage?.text}</span>
        <div className={styles.overlayButtons}>
          <button
            type="button"
            onClick={() => setShowOverlay(false)}
            className={styles.cancelButton}
          >
            {lineitemModules?.confirmRemoveNoButtonText?.text}
          </button>
          <button
            type="button"
            onClick={() => handleRemoveProduct()}
            className={styles.confirmButton}
          >
            {lineitemModules?.confirmRemoveYesButtonText?.text}
          </button>
        </div>
      </div>
    );
  };

  if (isCartPopup || isMobile) {
    return (
      <div className={styles.lineItemContainer}>
        <div className={cn(styles.popupLineItem, index !== 0 && styles.topBorder)}>
          <LineItemImage />
          <div className={styles.infoContainer}>
            <div className={styles.info}>
              <div>
                <div className={cn(styles.title, { [styles.opacTitle]: showOverlay })}>
                  {contentfulData?.title || lineItem?.merchandise?.product?.title}
                </div>
                <div className={styles.additionalData}>
                  {lineItem.sellingPlanAllocation?.sellingPlan?.name}
                </div>
              </div>

              <Prices />
            </div>

            <div className={styles.attributes}>
              {lineItem?.discountAllocations?.[0]?.title || null}
              {/* {lineItem?.attributes?.find(attr => attr.key === '_bundle_message')?.value || null} */}
            </div>

            <div className={styles.controls}>
              <ItemCounter
                disabled={hasFreeItemTag || lineItemIsFreeItem || hasFreeItemCustomAttribute}
                onlyDecrease={false}
                quantity={lineItem.quantity}
                updateQuantity={value => handleUpdateQuantity(value)}
              />
              <span className={cn(styles.actionButtons, styles.rowActtionButtons)}>
                <RemoveButton />
                <SaveForLaterButton />
              </span>
            </div>
          </div>
        </div>
        <ConfirmOverlay />
      </div>
    );
  }

  return (
    <div className={styles.lineItemContainer}>
      <div className={cn(styles.lineItem, index !== 0 && styles.topBorder, styles.lineWidth)}>
        <LineItemImage />
        <div className={cn(styles.info, styles.columnWidthItem)}>
          <div className={cn(styles.infoBox, styles.columnWidth)}>
            <span className={styles.title}>
              {contentfulData?.title || lineItem?.merchandise?.product?.title}
            </span>
            <div className={cn(styles.attributes, styles.cartAttributes)}>
              {lineItem?.discountAllocations?.[0]?.title || null}
            </div>
            <span className={cn(styles.actionButtons, styles.rowActtionButtons)}>
              <RemoveButton />
              <SaveForLaterButton />
            </span>
          </div>
        </div>
        <div className={styles.columnWidth}>
          {lineItem.merchandise ? <Prices /> : <span>-</span>}
        </div>

        <div className={cn(styles.controls, styles.columnWidth)}>
          {outOfStock ? (
            <div className={styles.outOfStockTag}>
              {globalModules?.productpage?.outOfStock?.text}
            </div>
          ) : (
            <ItemCounter
              disabled={hasFreeItemTag || lineItemIsFreeItem || hasFreeItemCustomAttribute}
              onlyDecrease={false}
              quantity={lineItem.quantity}
              updateQuantity={value => handleUpdateQuantity(value)}
            />
          )}
        </div>
      </div>
      <ConfirmOverlay />
    </div>
  );
};

export default LineItem;
