const Play = ({ width = 23, height = 28 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23 14L0.5 27.5V0.5L23 14Z" fill="white" />
  </svg>
);

export default Play;
