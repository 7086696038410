/**
 * A function that takes an event and ref and performs the callbacks depending on which gesture is being performed.
 * @param {the event that's taking place} event
 * @param {The useRef reference of the object to perform the callbacks on} ref
 * @param {An object of callbacks structured as follows: { leftSideCallback, rightSideCallback,
 * }
 * @returns void
 */
const handleTouch = (event, ref, callbacks) => {
  const start = {
    time: new Date().getTime(),
    x: event.targetTouches[0].clientX,
    y: event.targetTouches[0].clientY,
  };
  const end = {
    time: null,
    x: null,
    y: null,
  };
  const thresholdTime = 500;
  const thresholdDistance = 10;

  if (event.targetTouches.length > 1) return;

  let tracking = true;

  const touchMove = e => {
    if (tracking) {
      end.x = e.targetTouches[0].clientX;
      end.y = e.targetTouches[0].clientY;
    }
  };

  const touchEnd = () => {
    tracking = false;
    const now = new Date().getTime();
    const delta = {
      time: now - start.time,
      x: end.x - start.x,
      y: end.y - start.y,
    };
    if (delta.time < thresholdTime) {
      // Check type of gesture that has been performed
      if (delta.x > thresholdDistance && Math.abs(delta.y) < thresholdDistance)
        callbacks.leftSideCallback(delta);
      else if (-delta.x > thresholdDistance && Math.abs(delta.y) < thresholdDistance)
        callbacks.rightSideCallback(delta);
    }
  };

  ref.current?.addEventListener('touchmove', e => touchMove(e));
  ref.current?.addEventListener('touchend', e => touchEnd(e));
};

export default handleTouch;
