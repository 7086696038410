const HeartIcon = ({ width = '1.8rem', height = '1,7rem', type }) => {
  let fill = '';
  let stroke = '#3C3C3B';
  switch (type) {
    case 'red':
      fill = '#FBA59C';
      stroke = '#FBA59C';
      break;
    case 'black':
      fill = '#3C3C3B';
      stroke = '#3C3C3B';
      break;
    default:
      fill = 'none';
      stroke = '#3C3C3B';
      break;
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99935 15.5L7.79102 14.421C3.49935 10.6035 0.666016 8.08583 0.666016 4.99591C0.666016 2.4782 2.68268 0.5 5.24935 0.5C6.69935 0.5 8.09102 1.16213 8.99935 2.20845C9.90768 1.16213 11.2993 0.5 12.7493 0.5C15.316 0.5 17.3327 2.4782 17.3327 4.99591C17.3327 8.08583 14.4993 10.6035 10.2077 14.4292L8.99935 15.5Z"
        fill={fill}
        stroke={stroke}
        strokeLinecap="square"
      />
    </svg>
  );
};

export default HeartIcon;
