import { useEffect } from 'react';

export const usePreloadImages = imageUrls => {
  useEffect(() => {
    const { head } = document;
    const links = imageUrls
      ? imageUrls.map(url => {
          const link = document.createElement('link');
          link.rel = 'preload';
          link.as = 'image';
          link.href = url;
          head.appendChild(link);
          return link;
        })
      : [];

    return () => {
      links.forEach(link => head.removeChild(link));
    };
  }, [imageUrls]);
};

export default usePreloadImages;
